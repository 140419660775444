export class AdminAccount {
    id: string;
    consecutiveFailedLogins: number;
    created: string;
    createdByAccountId: string;
    email: string;
    firstName: string;
    lastName: string;
    lastLogin: string;
    phone: string;
    location: {
        countryCode2: string;
        countryCode3: string;
        countrySubdivision: string;
        freeformAddress: string;
        lat: number;
        lon: number;
        municipalities: string[];
        postalCode: string;
        score: number;
        streetName: string;
        streetNumber: string;
    };
    referredBy: string;
    resetPasswordTokenExp: string;
    isLegalAgreed: boolean;
    isLegalAgreedAt: string;
    city: string;
    stateAbbreviation: string;
    countryIso2: string;
    linkedIn: string;
    profileImageFile: string;
    systemRoles: any;

    constructor(a: any) {
        this.id = a.id;
        this.consecutiveFailedLogins = a.consecutiveFailedLogins;
        this.created = a.created;
        this.createdByAccountId = a.createdByAccountId;
        this.email = a.email;
        this.firstName = a.firstName;
        this.lastName = a.lastName;
        this.lastLogin = a.lastLogin;
        this.phone = a.phone;
        this.location = a.location;
        this.referredBy = a.referredBy;
        this.resetPasswordTokenExp = a.resetPasswordTokenExp;
        this.isLegalAgreed = a.isLegalAgreed;
        this.isLegalAgreedAt = a.isLegalAgreedAt;
        this.city = a.city;
        this.stateAbbreviation = a.stateAbbreviation;
        this.countryIso2 = a.countryIso2;
        this.linkedIn = a.linkedIn;
        this.profileImageFile = a.profileImageFile;
        this.systemRoles = [];
        for (const role of a.systemRoles) {
            this.systemRoles.push({systemRoleId: role.systemRoleId});
        }
    }
}
