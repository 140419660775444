﻿import { Component, OnInit, OnChanges, SimpleChanges, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import {
  Account, AccountSearchResults, AdminAccount, SystemRole,
  TimeZoneInfo, ConsultantProfile, RawListItemSearchResult, ConsultantProfileFlags, AccountNote, Questionnaire,
  QuestionnaireSection, Question, Answer, AccountMessage
} from '../_models';
import { AccountService, SystemRolesService, OrganizationService, UtilityService, ListService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { CONFIG } from '../../environments/environment';
import { finalize, map } from 'rxjs/operators';
@Component({
  selector: 'account-manage',
  moduleId: module.id,
  templateUrl: 'account-management.component.html',
  styleUrls: ['account-management.component.scss']
})
export class AccountManagementComponent implements OnChanges, OnInit {
  public static allRolesGroupedByRoleGroup: any;

  public loggedInAccount: Account;
  private _selectedAccountId: string = null;
  @Output() public accountLoaded = new EventEmitter<{}>();
  @Input() public set selectedAccountId(val: string) {
    this._selectedAccountId = val;
    this.setAccount();
  }
  public get selectedAccountId(): string {
    return this._selectedAccountId;
  }

  public adminAccount: AdminAccount;
  public profileImageBlob: any;
  public consultantProfile: ConsultantProfile;
  public consultantProfileDirty = false;
  public consultantResponsivenessMouseover: number = null;

  public loading: boolean;
  public avatarUrl: any;
  public avatarSrc: any;
  public canSetRoles: boolean;
  public accountId: string = null;
  public listItems: RawListItemSearchResult[] = [];
  public loggedInAccountImpersonationToken: string = null;

  public ConsultantProfileFlags: any;

  public showAddSuggestedListItem = false;
  public showListItemEditor = false;

  public accountNotes: AccountNote[] = null;
  public selectedNote: AccountNote = null;
  public selectedNoteDirty = false;

  public accountMessages: AccountMessage[] = null;
  public selectedMessage: AccountMessage = null;
  public selectedMessageDirty = false;

  public consultantQuestionnaire: Questionnaire;
  public requiredSectionsCompletion = 0;
  public optionalSectionsCompletion = 0;
  public zoomedSection: QuestionnaireSection = null;

  public ctas = [
    'Contact Me',
    'Start Project'
  ];

  public listItemTypes = [
    { id: 'ForReview', name: 'Ready For Review' },
    { id: 'Suggested', name: 'Suggested For You' },
    { id: 'Later', name: 'Hold For Later' },
    { id: 'MovedToProjects', name: 'Sent To Project Discussion' },
  ];

  public createAccountModel = {
    email: <string>null,
    firstName: <string>null,
    lastName: <string>null,
    sendCreateEmail: <boolean>false
  };


  public createSuggestedListItem = {
    rawBody: <string>null,
    listItemTitle: <string>null,
    cta: <string>null,
    listItemResponse: <string>null
  };

  public updateListItemModel = {
    listItem: <RawListItemSearchResult>null,
    newType: <string>null
  };

  public singleSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false,
    selectionLimit: 1,
    autoUnselect: true,
    showUncheckAll: true,
    closeOnSelect: true
  };
  // Text configuration
  public multiSelectTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    defaultTitle: '- - - - - - - - - - - - - - - - - Select - - - - - - - - - - - - - - - - - ',
    allSelected: 'All selected',
  };

  public consultantStatusOptions: { internalStatus: string, externalStatus: string }[] = [
    { externalStatus: 'In-Process', internalStatus: 'Created Consultant Profile' },
    { externalStatus: 'In-Process', internalStatus: 'Submitted Basic Info' },
    { externalStatus: 'In-Process', internalStatus: 'Hold 1' },
    { externalStatus: 'In-Process', internalStatus: 'Submitted Additional Info' },
    { externalStatus: 'In-Process', internalStatus: 'Hold 2' },
    { externalStatus: 'In-Process', internalStatus: 'Submitted Bio, Specialties, Capabilities' },
    { externalStatus: 'In-Process', internalStatus: 'Hold 3' },
    { externalStatus: 'In-Process', internalStatus: 'Submitted Skills' },
    { externalStatus: 'In-Process', internalStatus: 'Hold 4' },
    { externalStatus: 'In-Process', internalStatus: 'Submitted At Least One Writing Sample' },
    { externalStatus: 'In-Process', internalStatus: 'Hold 5' },
    { externalStatus: 'In-Process', internalStatus: 'Submitted All Writing Samples (And Completed Onboarding Except Chat)' },
    { externalStatus: 'In-Process', internalStatus: 'Waiting For Sample Work' },
    { externalStatus: 'In-Process', internalStatus: 'Review Sample Work' },
    { externalStatus: 'In-Process', internalStatus: 'Hold 7' },
    { externalStatus: 'In-Process', internalStatus: 'Waiting For Onboarding Chat' },
    { externalStatus: 'In-Process', internalStatus: 'Hold 8' },
    { externalStatus: 'Approved', internalStatus: 'Invite To Onboarding Slack And Send Process Email' },
    { externalStatus: 'Approved', internalStatus: 'Waiting For Acceptance Of Onboarding Slack Invitation' },
    { externalStatus: 'Approved', internalStatus: 'Waiting For Trial Project Notification In Onboarding Slack' },
    { externalStatus: 'Approved', internalStatus: 'Staffed On Trial Project' },
    { externalStatus: 'Approved', internalStatus: 'Review Trial Project Performance' },
    { externalStatus: 'Approved', internalStatus: 'Invite To Normal Slack' },
    { externalStatus: 'Approved', internalStatus: 'Waiting For Acceptance Of Normal Slack Invitation' },
    { externalStatus: 'Approved', internalStatus: 'Joined Slack And Is A Consultant!' },
    { externalStatus: 'Approved', internalStatus: '"Promoted" To PM And Invite To PM Slack And PM Email' }
  ];



  constructor(private accountService: AccountService,
    private systemRolesService: SystemRolesService,
    private organizationService: OrganizationService,
    private utilityService: UtilityService,
    private listService: ListService,
    private store: Store<Account>) {
    this.ConsultantProfileFlags = ConsultantProfileFlags;
    this.loading = true;
    this.store.select('loggedInAccount')
      .select((account: Account) => {
        this.loggedInAccount = account;
        if (this.loggedInAccount) {
          this.canSetRoles = RolesHelper
            .belongsToRole(this.loggedInAccount.roles, { System: ['RoleAdmin'] });
        }

        this.loading = false;
      }).subscribe();

    if (!AccountManagementComponent.allRolesGroupedByRoleGroup) {
      this.loading = true;
      // get all system roles:
      this.systemRolesService.getSystemRoles(this.loggedInAccount.refreshToken)
        .subscribe((result: SystemRole[]) => {
          if (result) {
            AccountManagementComponent.allRolesGroupedByRoleGroup =
              RolesHelper.getRolesKeyedByRoleGroup(result, ['System', 'API', 'Consultant']);
            // console.log('all org roles:', AccountManagementComponent.allOrganizationRoles);
            this.loading = false;
            // this.setAccount();
          }
        });
    }

  }

  public ngOnInit() {
    // this.setAccount();
  }

  private setAccount() {
    this.accountId = this.selectedAccountId;
    this.grabAdminAccount();
  }

  private getConsultantProfileIfExists() {
    if (this.adminAccount) {
      this.accountService.getConsultantProfileForAccount(this.adminAccount.id, this.loggedInAccount.refreshToken)
        .subscribe((profile: ConsultantProfile) => {
          if (profile) {
            this.consultantProfile = profile;
            this.handleConsultantStatus();
            this.getConsultantQuestionnaireIfExists();
          }
        });
    }
  }

  public handleConsultantStatus() {
    // check to make sure that if there is a status that doesn't match, that we add it to the list...
    if (this.consultantProfile.internalStatus && this.consultantProfile.externalStatus) {
      const foundOpt = this.consultantStatusOptions
        .find(o => o.externalStatus === this.consultantProfile.externalStatus
          && o.internalStatus === this.consultantProfile.internalStatus);
      if (!foundOpt) {
        this.consultantStatusOptions.push({
          internalStatus: this.consultantProfile.internalStatus,
          externalStatus: this.consultantProfile.externalStatus
        });
      }
    }
  }

  public getConsultantQuestionnaireIfExists() {
    if (this.consultantProfile) {
      this.accountService.getConsultantQuestionnaire(this.loggedInAccount.refreshToken)
        .subscribe(questionnaire => {
          // console.log('loaded questionnaire', questionnaire);
          if (questionnaire) {
            this.accountService.getAnswersAndAssignToQuestionnaire(questionnaire,
              this.consultantProfile.id, this.loggedInAccount.refreshToken).subscribe(() => {
                this.consultantQuestionnaire = questionnaire;
                this.calculateCompletions();
                this.setUIAnswerTexts();
                // console.log('setting questionnaire and answers', this.consultantQuestionnaire);
              });
          }
        });
    }

  }

  public getQuestionText(question: Question): string {
    const startWith = question.question;
    let ret = this.subFieldValuesInQuestion(question, startWith);
    ret = this.substituteTokens(ret);
    ret += question.required ? '*' : '';
    const instructions = this.subFieldValuesInQuestion(question, question.instructions);
    ret += `<br><i>${instructions}</i>`;
    return ret;
  }

  private subFieldValuesInQuestion(question: Question, startWith: string): string {
    const hbStart = startWith.indexOf('{{');
    const hbEnd = startWith.indexOf('}}');
    if (hbStart >= 0 && hbEnd > hbStart && hbEnd > 0) {
      // we found a substitution:
      // grab the name:
      const fieldName = startWith.substring(hbStart + 2, hbEnd);
      let foundAnswer: Answer = null; // = this.allAnswers.find(a => a.question.fieldName === fieldName);
      this.consultantQuestionnaire.sections.forEach(section => {
        section.questions.forEach(q => {
          if (q.uiAnswers.length === 1 && q.fieldName === fieldName) {
            foundAnswer = q.uiAnswers[0];
          }
        });
      });
      let subValue = '';
      if (foundAnswer && foundAnswer.answer) {
        subValue = foundAnswer.answer;
      }
      startWith = startWith.replace('{{' + fieldName + '}}', subValue);
      startWith = this.subFieldValuesInQuestion(question, startWith);
    }
    return startWith;
  }

  private substituteTokens(startWith: string): string {
    const hbStart = startWith.indexOf('[[');
    const hbEnd = startWith.indexOf(']]');
    if (hbStart >= 0 && hbEnd > hbStart && hbEnd > 0) {
      // we found a substitution:
      // grab the name:
      const fieldName = startWith.substring(hbStart + 2, hbEnd);
      let value = '';
      if (this.adminAccount) {
        value = this.adminAccount[fieldName];
      }
      startWith = startWith.replace('[[' + fieldName + ']]', value);
      startWith = this.substituteTokens(startWith);
    }
    return startWith;
  }

  private calculateCompletions() {
    let totalRequiredQuestions = 0;
    let totalOptionalQuestions = 0;
    let totalRequiredAnswers = 0;
    let totalOptionalAnswers = 0;

    this.consultantQuestionnaire.sections.forEach(section => {
      const sectionAnswers: { question: Question, answers: Answer[] }[] = [];
      // populate section answers by UI answers:
      section.questions.forEach(question => {
        sectionAnswers.push({ question, answers: question.uiAnswers });
      });
      const progress = Questionnaire.getProgressForSection(section, sectionAnswers);
      if (section.category === 'OnboardingFlow') {
        totalRequiredQuestions += progress.evaluatedQuestions;
        totalRequiredAnswers += progress.evaluatedAnswers;
      } else {
        totalOptionalQuestions += progress.evaluatedQuestions;
        totalOptionalAnswers += progress.evaluatedAnswers;
      }
      section.uiCompletion = progress.percentComplete;
    });

    this.requiredSectionsCompletion = Math.round((totalRequiredAnswers / totalRequiredQuestions) * 100);
    this.optionalSectionsCompletion = Math.round((totalOptionalAnswers / totalOptionalQuestions) * 100);
  }

  private getHTMLFromPlainText(plainText: string): string {
    return plainText.replace(/\n/g, '<br>');
  }

  // whenever this code changes, also be sure to change in the API ConsultantController to match up.
  // (FormatAccountConsultantProfileAnswers)
  private setUIAnswerTexts() {
    this.consultantQuestionnaire.sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.uiAnswers && question.uiAnswers.length > 0) {
          if (question.options && question.options.length > 0) {
            // substitute with the option value:
            question.uiAnswers.forEach(answer => {
              if (question.type === 'timeranges') {
                answer.uiAnswerText = '';
                const ranges: { selectedOptions: string[], from: string, to: string }[] = JSON.parse(answer.answer);
                ranges.forEach(range => {
                  let addComma = false;
                  answer.uiAnswerText += '<b>';
                  question.options.forEach(option => {
                    if (range.selectedOptions.indexOf(option.name) >= 0) {
                      if (addComma) {
                        answer.uiAnswerText += ', ' + option.label;
                      } else {
                        answer.uiAnswerText += option.label;
                        addComma = true;
                      }
                    }
                  });
                  answer.uiAnswerText += ': </b><br>' + range.from + ' - ' + range.to + '<br>';
                });
              } else {
                const foundOption = question.options.find(o => o.name === answer.answer);
                if (foundOption) {
                  answer.uiAnswerText = this.getHTMLFromPlainText(foundOption.label);
                } else {
                  answer.uiAnswerText = this.getHTMLFromPlainText(answer.answer);
                }
              }
            });
            // if the question is a ranking type, reorder the values:
            if (question.type === 'rank') {
              question.uiAnswers = question.uiAnswers.sort((a, b) => {
                if (Number(a.answer) < Number(b.answer)) {
                  return - 1;
                }
                if (Number(a.answer) > Number(b.answer)) {
                  return 1;
                }
                return 0;
              });
            }
          } else {
            // use the value as-is:
            question.uiAnswers.forEach(answer => {
              if (question.type === 'yesno') {
                if (this.boolFromString(answer.answer)) {
                  answer.uiAnswerText = 'Yes';
                } else {
                  answer.uiAnswerText = 'No';
                }
              } else if (question.type === 'slider' || question.type === 'rating') {
                answer.uiAnswerText = `${answer.answer}/${question.max}`;
              } else if (question.type === 'timeranges') {
                const range: { min: string, max: string } = JSON.parse(answer.answer);
                answer.uiAnswerText = `${range.min} - ${range.max}`;
              } else if (question.type === 'docfile') {
                const doc: { filename: string, originalFilename: string } = JSON.parse(answer.answer);
                answer.uiAnswerText = doc.originalFilename;
              } else {
                answer.uiAnswerText = this.getHTMLFromPlainText(answer.answer);
              }
            });
          }
        }
      });
    });
  }

  public downloadConsultantFile(question: Question) {
    if (question.type === 'docfile') {
      // {"filename":"xxxx1e54.docx","originalFilename":"xxx.docx"}
      const upload = JSON.parse(question.uiAnswers[0].answer);
      const remoteName = upload.filename;
      const localName = upload.originalFilename;
      this.accountService.downloadConsultantFile(remoteName, localName, this.loggedInAccount.refreshToken)
        .subscribe(() => {

        });
    }
  }

  public boolFromString(str: string): boolean {
    const answerBool = String(str).toLowerCase() === 'true' || String(str) === '1';
    return answerBool;
  }

  public get allRoles() {
    return AccountManagementComponent.allRolesGroupedByRoleGroup;
  }

  public get allRoleKeys() {
    return Object.keys(this.allRoles);
  }

  public hasRole(roleId: string): boolean {
    if (this.adminAccount) {
      const ret: boolean = this.adminAccount &&
        this.adminAccount.systemRoles &&
        this.adminAccount.systemRoles.some(
          (r) => r.systemRoleId === roleId
        );
      return ret;
    }
    return false;
  }

  public grabAdminAccount() {
    if (!this.accountId) {
      return;
    }
    this.loading = true;
    this.avatarUrl = null;
    this.avatarSrc = null;
    this.loggedInAccountImpersonationToken = null;
    this.consultantProfile = null;
    this.accountService.getAccount(this.accountId,
      this.loggedInAccount.refreshToken)
      .subscribe((account: AdminAccount) => {
        console.log('grabAdminAccount', account);
        if (account) {
          this.adminAccount = new AdminAccount(account);
          if (this.accountLoaded && this.adminAccount) {
            this.accountLoaded.emit(this.adminAccount);
          }
          this.getImageBlobForAccount();
          this.getConsultantProfileIfExists();
          this.loadListItems();
          this.getAccountNotes();
          this.getAccountMessages();
        }
        this.loading = false;
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    // for (const propName of Object.keys(changes)) {
    //     const chng = changes[propName];
    //     if (propName === 'selectedAccount') {
    //         this.grabAdminAccount();
    //     }
    // }
  }

  public setRole(roleId: string, roleEnabled: boolean) {
    this.loading = true;
    this.accountService.setRole(this.adminAccount.id, roleId,
      roleEnabled, this.loggedInAccount.refreshToken)
      .subscribe((result: any) => {
        this.grabAdminAccount();
        this.loading = false;
      });
  }

  public createConsultantProfile() {
    if (this.adminAccount) {
      const profile: ConsultantProfile = this.consultantProfile ? this.consultantProfile : new ConsultantProfile();
      profile.accountId = this.adminAccount.id;
      profile.isPublic = true;
      this.accountService.upsertConsultantProfile(profile, this.loggedInAccount.refreshToken)
        .subscribe((affectedProfile: ConsultantProfile) => {
          if (affectedProfile) {
            this.consultantProfile = affectedProfile;
          }
        });
    }
  }


  private loadListItems() {
    this.listService.getRawListItemsForAccount(this.adminAccount.id, this.loggedInAccount.refreshToken).subscribe((listItems) => {
      this.listItems = listItems;
    });
  }

  public addSuggestedListItemLoad() {
    this.showAddSuggestedListItem = true;
  }

  public cancelAddSuggestedListItem() {
    this.showAddSuggestedListItem = false;
  }

  public addSuggestedListItem() {
    this.loading = true;
    this.listService.createSuggestedListItem(
      this.createSuggestedListItem.rawBody,
      this.createSuggestedListItem.listItemTitle,
      this.createSuggestedListItem.listItemResponse,
      this.createSuggestedListItem.cta,
      this.adminAccount.id,
      this.loggedInAccount.refreshToken)
      .subscribe((org) => {
        this.showAddSuggestedListItem = false;
        this.loadListItems();
        this.loading = false;
      });
  }

  public createAccount() {
    this.loading = true;
    this.adminAccount = null;
    this.accountService.adminCreateAccount(
      this.createAccountModel.email,
      this.createAccountModel.firstName,
      this.createAccountModel.lastName,
      this.createAccountModel.sendCreateEmail,
      this.loggedInAccount.refreshToken)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        map((result: AdminAccount) => {
          if (result) {
            this.createAccountModel.email = null;
            this.createAccountModel.firstName = null;
            this.createAccountModel.lastName = null;
            this.createAccountModel.sendCreateEmail = false;
            this.accountId = result.id;
            this.grabAdminAccount();
          } else {
            alert('There was an error creating the account. Please check the JavaScript log for details.');
          }
        },
          (error: any) => {
            console.log('Error creating account:', error);
          })
      ).subscribe();
  }

  public listItemEditorLoad(listItem: RawListItemSearchResult) {
    if (!this.updateListItemModel) {
      this.updateListItemModel = { listItem: null, newType: null };
    } else {
      this.updateListItemModel.listItem = listItem;
      this.updateListItemModel.newType = listItem.listItemType;
    }
    this.showListItemEditor = true;
  }

  public saveListItem() {
    this.loading = true;
    this.listService.updateListItem(this.updateListItemModel.listItem.listItemNoteId,
      this.updateListItemModel.newType, this.accountId, this.loggedInAccount.refreshToken).subscribe((resp) => {
        this.loadListItems();
        this.showListItemEditor = false;
        this.loading = false;
      });

  }

  public listItemEditorClose() {
    this.showListItemEditor = false;
  }

  public openImpersonationSite() {
    window.open(CONFIG.websiteBase + 'login/' + this.loggedInAccountImpersonationToken, '_blank');
  }

  public openConsultantProfileOnSite() {
    window.open(CONFIG.websiteBase + 'teammate/' + this.consultantProfile.publicProfileId, '_blank');
  }

  public requestImpersonationKey(acct: AdminAccount) {
    // request a refresh token:
    this.accountService.getRefreshTokenForImpersonation(acct.id, this.loggedInAccount.refreshToken)
      .subscribe(token => {
        this.loggedInAccountImpersonationToken = token.refreshToken;
      });
  }

  public consultantProfileChange() {
    // find internal / external, set, and update status:
    const foundPair = this.consultantStatusOptions.find(o => o.internalStatus === this.consultantProfile.internalStatus);
    if (foundPair) {
      this.consultantProfile.internalStatus = foundPair.internalStatus;
      this.consultantProfile.externalStatus = foundPair.externalStatus;
      this.accountService.upsertConsultantProfile(this.consultantProfile, this.loggedInAccount.refreshToken)
        .subscribe((affectedProfile: ConsultantProfile) => {
          if (affectedProfile) {
            this.consultantProfile = affectedProfile;
            this.consultantProfileDirty = false;
          }
        });
    }
  }

  public consultantResponsivenessOver(item: number) {
    this.consultantResponsivenessMouseover = item;
  }

  public consultantResponsivenessOut() {
    this.consultantResponsivenessMouseover = null;
  }


  public getStarClass(name: string, rating: number): string {
    if (this.consultantResponsivenessMouseover) {
      if (rating <= this.consultantResponsivenessMouseover) {
        return 'fa-star rating-sel';
      } else {
        return 'fa-star-o';
      }
    } else {
      if (this.consultantProfile.ratings) {
        const allRatings = JSON.parse(this.consultantProfile.ratings);
        const foundRating = allRatings.find(r => r.name === name);
        if (foundRating) {
          if (rating <= foundRating.rating) {
            return 'fa-star rating-sel';
          } else {
            return 'fa-star-o';
          }
        } else {
          return 'fa-star-o';
        }
      } else {
        return 'fa-star-o';
      }
    }
  }

  public setConsultantRating(name: string, rating: number) {
    if (!this.consultantProfile.ratings) {
      this.consultantProfile.ratings = '[]';
    }
    // create the object and the json string value for it:
    const allRatings = JSON.parse(this.consultantProfile.ratings);
    let foundRating = allRatings.find(r => r.name === name);
    if (!foundRating) {
      foundRating = { name, rating };
      allRatings.push(foundRating);
    } else {
      foundRating.rating = rating;
    }
    this.consultantProfile.ratings = JSON.stringify(allRatings);
    this.consultantProfileDirty = true;
  }


  public get consultantRejectedFlag(): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!(this.consultantProfile.flags & ConsultantProfileFlags.Rejected);
  }
  public set consultantRejectedFlag(set: boolean) {
    this.consultantFlagChanged(ConsultantProfileFlags.Rejected, set);
  }

  public consultantFlagChanged(flag: ConsultantProfileFlags, set: boolean) {
    if (set) {
      // tslint:disable-next-line:no-bitwise
      this.consultantProfile.flags |= flag;
    } else {
      // tslint:disable-next-line:no-bitwise
      this.consultantProfile.flags &= ~flag;
    }
    this.consultantProfileDirty = true;
  }

  private getImageBlobForAccount() {
    if (this.adminAccount.profileImageFile) {
      this.accountService.downloadAccountImage(this.adminAccount.profileImageFile, this.loggedInAccount.refreshToken)
        .subscribe(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            this.profileImageBlob = reader.result;
          };

          if (blob) {
            reader.readAsDataURL(blob);
          }
        });
    }
  }

  public getAccountNotes() {
    this.accountService.getAccountNotes(this.adminAccount.id, this.loggedInAccount.refreshToken)
      .subscribe(notes => {
        this.accountNotes = notes;
        if (this.accountNotes && this.accountNotes.length > 0) {
          this.selectedNote = notes[0];
        }
      });
  }

  public setSelectedNote(note) {
    this.selectedNote = note;
  }

  public selectedNoteChanged() {
    this.selectedNoteDirty = true;
  }

  public updateNote() {
    const idx = this.accountNotes.indexOf(this.selectedNote);
    this.accountService.upsertAccountNote(this.selectedNote, this.loggedInAccount.refreshToken)
      .subscribe(note => {
        this.accountNotes.splice(idx, 1);
        this.accountNotes.unshift(note);
        this.setSelectedNote(note);
        this.selectedNoteDirty = false;
      });
  }

  public createNote() {
    const newNote = new AccountNote();
    newNote.accountId = this.adminAccount.id;
    newNote.note = '';
    this.accountService.upsertAccountNote(newNote, this.loggedInAccount.refreshToken)
      .subscribe(createdNote => {
        this.accountNotes.unshift(createdNote);
        this.setSelectedNote(createdNote);
      });
  }

  public toggleZoomSection(section: QuestionnaireSection) {
    if (this.zoomedSection) {
      this.zoomedSection = null;
    } else {
      this.zoomedSection = section;
    }
  }


  public getAccountMessages() {
    this.accountService.getAccountMessages(this.adminAccount.id, this.loggedInAccount.refreshToken)
      .subscribe(messages => {
        this.accountMessages = this.sortMessages(messages, false);
        if (this.accountMessages && this.accountMessages.length > 0) {
          this.selectedMessage = messages[0];
        }
      });
  }

  public setSelectedMessage(msg) {
    this.selectedMessage = msg;
  }

  public selectedMessageChanged() {
    this.selectedMessageDirty = true;
  }

  public updateMessage() {
    const idx = this.accountMessages.indexOf(this.selectedMessage);
    this.accountService.upsertAccountMessage(this.selectedMessage, this.loggedInAccount.refreshToken)
      .subscribe(msg => {
        this.accountMessages.splice(idx, 1);
        this.accountMessages.unshift(msg);
        this.setSelectedMessage(msg);
        this.selectedMessageDirty = false;
      });
  }

  public createMessage() {
    const newMessage = new AccountMessage();
    newMessage.accountId = this.adminAccount.id;
    newMessage.sender = this.loggedInAccount.profile.firstName + ' ' + this.loggedInAccount.profile.lastName;
    newMessage.subject = '';
    newMessage.message = '';
    newMessage.messageType = 'M';
    this.accountMessages.unshift(newMessage);
    this.setSelectedMessage(newMessage);
  }

  private sortMessages(arr: AccountMessage[], isAsc: boolean): AccountMessage[] {
    if (arr) {
      arr = arr.sort((a, b) => {
        if (isAsc) {
          if (new Date(a.created) < new Date(b.created)) { return -1; }
          if (new Date(a.created) > new Date(b.created)) { return 1; }
        } else {
          if (new Date(a.created) > new Date(b.created)) { return -1; }
          if (new Date(a.created) < new Date(b.created)) { return 1; }
        }
        return 0;
      });
    }
    return arr;
  }
}
