import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { RawListItemSearchResult } from '../_models';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, take } from 'rxjs/operators';



@Injectable()
export class ListService {

    constructor(private http: HttpClient) { }

    public searchForRawListItems(refreshToken: string):
        Observable<RawListItemSearchResult[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get wallet from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/rawListItems/search',
            options)
            .pipe(
                take(1),
                map((response: RawListItemSearchResult[]) => response)
            );
    }

    public getRawListItemsForAccount(accountId: string, refreshToken: string):
        Observable<RawListItemSearchResult[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get wallet from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/accounts/' + accountId + '/rawListItems',
            options)
            .pipe(
                take(1),
                map((response: RawListItemSearchResult[]) => response)
            );
    }

    public getRawListItem(rawListItemId: string, refreshToken: string):
        Observable<RawListItemSearchResult> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get wallet from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/rawListItems/' + rawListItemId,
            options)
            .pipe(
                take(1),
                map((response: RawListItemSearchResult) => response)
            );
    }

    public downloadRawFile(item: RawListItemSearchResult, refreshToken: string):
        Observable<any> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        return this.http.get(CONFIG.apiEndpointBase +
            'admins/rawListItems/rawUploads/'
            + item.azureBlobFilename,
            { headers, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                map((response: HttpResponse<Blob>) => {
                    let type = '';
                    if (item.azureBlobFilename.endsWith('m4a')) {
                        type = 'audio/mpeg';
                    } else if (item.azureBlobFilename.endsWith('mp3')) {
                        type = 'audio/mpeg';
                    } else if (item.azureBlobFilename.endsWith('txt')) {
                        type = 'text/plain';
                    } else if (item.azureBlobFilename.endsWith('jpg')) {
                        type = 'image/jpeg';
                    }
                    //    console.log('resp:', response.blob());
                    const array = response.body;
                    const blob = new Blob([array], { type });
                    return blob;
                })
            );
    }

    public respondToRawListItem(listItemId: string, listItemRawId: string,
        listItemBody: string, listItemNote: string, callToAction: string, projectId: string,
        customerAccountId: string, newProjectShellName: string, refreshToken: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const body = {
            listItemId, listItemRawId, listItemBody, listItemNote, callToAction,
            projectId, customerAccountId, newProjectShellName
        };
        // console.log('posting body response', body);
        // post to the API:
        return this.http.post(CONFIG.apiEndpointBase + 'admins/rawListItems/responses', body, options)
            .pipe(
                take(1),
                map((response: Response) => {
                    return true;
                })
            );
    }

    public updateListItem(listItemNoteId: string, newType: string, accountId: string, refreshToken: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const body = { listItemNoteId, newType, accountId };
        // PATCH to the API:
        return this.http.patch(CONFIG.apiEndpointBase + 'admins/listItems', body, options)
            .pipe(
                take(1),
                map((response: Response) => {
                    return true;
                })
            );
    }

    public createSuggestedListItem(rawTextContent: string,
        listItemBody: string, listItemNote: string,
        callToAction: string, accountId: string,
        refreshToken: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const body = { rawTextContent, listItemBody, listItemNote, callToAction, accountId };
        // console.log('posting body response', body);
        // post to the API:
        console.log('sending suggested', body)
        return this.http.post(CONFIG.apiEndpointBase + 'admins/accounts/suggestedListItems', body, options)
            .pipe(
                take(1),
                map((response: Response) => {
                    return true;
                })
            );
    }
}
