import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { RawListItemSearchResult } from '../_models';
import { ListService } from '../_services';

@Component({
    selector: 'async-audio',
    template:
    `<audio controls *ngIf="done">
        <source [src]="audio | safe" type="audio/mpeg">
    </audio>`
    // template: `<img [src]="img | safe" />`
})
export class AsyncAudioComponent implements OnInit, OnChanges {

    @Input() item: RawListItemSearchResult;
    @Input() refreshToken: string;
    public audio: any;
    public done = false;
    public working = false;
    constructor(
        public listService: ListService
    ) { }

    public ngOnInit() {
        // console.log('onInit audio');
        this.loadMedia();
    }

    public ngOnChanges() {
        if (!this.working) {
            this.audio = null;
            this.done = false;
        }
        this.loadMedia();
    }

    private loadMedia() {
        if (this.working || this.audio) {
            return;
        }
        this.working = true;
        // console.log('onInit', this.item, this.refreshToken);
        this.listService.downloadRawFile(this.item, this.refreshToken).subscribe((blob) => {
            // console.log('audio', blob);
            const url = URL.createObjectURL(blob);
            this.audio = url;
            this.done = true;
            this.working = false;
        });
    }

}
