
export class Questionnaire {
    id: string;
    name: string;
    description: string;
    instructions: string;
    endMessage: string;
    sections: QuestionnaireSection[];

    public static getProgressForSection(section: QuestionnaireSection, sectionAnswers: { question: Question, answers: Answer[] }[]):
      { percentComplete: number,
      requiredQuestions: number, requiredAnswers: number,
      optionalQuestions: number, optionalAnswers: number,
      evaluatedQuestions: number, evaluatedAnswers: number } {
      let percentComplete = 0;
      let requiredQuestions = 0;
      let requiredAnswers = 0;
      let optionalQuestions = 0;
      let optionalAnswers = 0;
      let evaluatedQuestions = 0;
      let evaluatedAnswers = 0;
      // console.log('getProgressForSection:', section, sectionAnswers);
      if (section.questions) {
        section.questions.forEach(question => {
          const answer = sectionAnswers.find(a => {
            return a.question.id === question.id;
          });
          // console.log('found answer', answer);
          if (question.required && !question.conditionalFieldName) {
            requiredQuestions ++;
            if (answer && answer.answers.length > 0  && !!answer.answers[0].answer) {
              requiredAnswers ++;
            }
          } else {
            if (!question.conditionalFieldName) {
              optionalQuestions ++;
              if (answer && answer.answers && answer.answers.length > 0 && !!answer.answers[0].answer) {
                optionalAnswers ++;
              }
            }
          }
        });
      }

      // console.log(section.name + ': get progress required: ' + requiredAnswers + '/' + requiredQuestions);
      // console.log(section.name + ': get progress optional: ' + optionalAnswered + '/' + optionalQuestions);
      // percentComplete = Math.round(((requiredAnswers + optionalAnswered) / (requiredQuestions + optionalQuestions)) * 100);
      if (requiredQuestions > 0) {
        evaluatedQuestions = requiredQuestions;
        evaluatedAnswers = requiredAnswers;
        percentComplete = Math.round((requiredAnswers / requiredQuestions) * 100);
      } else if (optionalQuestions > 0) {
        evaluatedQuestions = optionalQuestions;
        evaluatedAnswers = optionalAnswers;
        percentComplete = Math.round((optionalAnswers / optionalQuestions) * 100);
      }
      return {  percentComplete, requiredQuestions, requiredAnswers, optionalQuestions,
                optionalAnswers, evaluatedQuestions, evaluatedAnswers };
    }

  }

  export class QuestionnaireSection {
    id: string;
    name: string;
    description: string;
    instructions: string;
    displayOrder: number;
    questions: Question[];
    category: string;
    profileHidden: boolean;
    isAccountSetupSection: boolean;
    isConsultantSetupSection: boolean;
    uiCompletion: number;
  }

  export class Question {
    id: string;
    question: string;
    type: string;
    fieldName: string;
    required: boolean;
    instructions: string;
    displayOrder: number;
    options: {name: string, label: string, type: string, required: boolean, min: number, max: number}[];
    minLength: number;
    maxLength: number;
    min: number;
    max: number;
    conditionalFieldName: string;
    conditionalOptionName: string;
    conditionalMin: number;
    conditionalMax: number;
    conditionalBool: boolean;
    minWords: number;
    maxWords: number;
    examplePopup: string;
    answerDisplay: string;

    uiAnswers: Answer[];
  }

  export class Answer {
    questionId: string;
    answerFieldName: string;
    answer: string;
    answeredOn: string;
    isCurrent: boolean;

    uiAnswerText: string;
  }
