import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { RawListItemSearchResult } from '../_models';
import { ListService } from '../_services';

@Component({
    selector: 'async-image',
    styles: ['img {height: 200px;}', 'img:hover {height:initial; max-height:100vh; max-width:100%;}'],
    template: `<a [href]="img | safe" target="_blank"><img *ngIf="done" [src]="img | safe"/></a>`
})
export class AsyncImageComponent implements OnInit, OnChanges {

    @Input() item: RawListItemSearchResult;
    @Input() refreshToken: string;
    public img: any;
    public done = false;
    public working = false;

    constructor(
        public listService: ListService
    ) { }

    public ngOnInit() {
        // console.log('onInit audio');
        this.loadMedia();
    }

    public ngOnChanges() {
        if (!this.working) {
            this.img = null;
            this.done = false;
        }
        this.loadMedia();
    }

    private loadMedia() {
        if (this.working || this.img) {
            return;
        }
        this.working = true;
        this.listService.downloadRawFile(this.item, this.refreshToken).subscribe((blob) => {
            const url = URL.createObjectURL(blob);
            this.img = url;
            this.done = true;
            this.working = false;
        });
    }
}
