import { Component, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';
import { ProjectFilePermissionFlags } from '../_models';
import { AfterViewChecked } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'project-file-permissions',
  styleUrls: ['./project-file-permissions.component.scss'],
  templateUrl: './project-file-permissions.component.html'
})
export class ProjectFilePermissionsComponent implements AfterViewInit {
  @Input() public permName: string;
  @Input() public permissions: ProjectFilePermissionFlags;
  @Output() public permissionsUpdated = new EventEmitter<ProjectFilePermissionFlags>();

  constructor() {}

  public ngAfterViewInit() {
  }

  public get canRead(): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!(this.permissions & ProjectFilePermissionFlags.Read);
  }

  public get canEdit(): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!(this.permissions & ProjectFilePermissionFlags.Edit);
  }

  public get canDelete(): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!(this.permissions & ProjectFilePermissionFlags.Delete);
  }

  public set canRead(can: boolean) {
    this.setPerm(ProjectFilePermissionFlags.Read, can);
  }

  public set canEdit(can: boolean) {
    this.setPerm(ProjectFilePermissionFlags.Edit, can);
  }

  public set canDelete(can: boolean) {
    this.setPerm(ProjectFilePermissionFlags.Delete, can);
  }

  public setPerm(perm: ProjectFilePermissionFlags, can: boolean) {
    if (can) {
      // tslint:disable-next-line:no-bitwise
      this.permissions |= perm;
    } else {
      // tslint:disable-next-line:no-bitwise
      this.permissions &= ~perm;
    }
  }

  public modelChange() {
    if (this.permissionsUpdated) {
      this.permissionsUpdated.emit(this.permissions);
    }
  }

}
