import { Component, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
export interface CustomModel {
  title: string;
  message: string;
  bodyHtml: string;
  okButtonText: string;
  showCancelButton: boolean;
  showOkButton: boolean;
  cancelButtonText: string;
}
@Component({  selector: 'modal',
  template: `<div style="width:100vw; height:100vh; background-color: rgba(0,0,0,.7);">
              <div class="modal-content" style="position:absolute; left: 50%; top:50%; transform: translate(-50%, -50%); min-width: 50%; min-height: 200px;">
                   <div class="modal-header">
                     <button type="button" class="close" (click)="closeModal()" >&times;</button>
                     <h4 class="modal-title">{{title || 'Confirm'}}</h4>
                   </div>
                   <div class="modal-body">
                     <div #bodyContainer></div>
                   </div>
                   <div class="modal-footer">
                     <button type="button" class="btn btn-primary" 
                        [ngStyle]="{ 'display': showOkButton ? 'inline' : 'none' }" (click)="confirm()">
                        {{okButtonText}}
                     </button>
                     <button type="button" class="btn btn-default" 
                        [ngStyle]="{ 'display': showCancelButton ? 'inline' : 'none' }" (click)="closeModal()">
                        {{cancelButtonText}}
                     </button>
                   </div>
                 </div>
              </div>`
})
export class CustomModalComponent extends DialogComponent<CustomModel, boolean> implements AfterContentInit {
    @ViewChild('bodyContainer') bodyContainer: ElementRef;

    public bodyHtml: string;
    public title: string;
    public showOkButton = true;
    public showCancelButton = true;
    public okButtonText = 'OK';
    public cancelButtonText = 'Cancel';
    constructor(dialogService: DialogService) {
      super(dialogService);
    }

    loadData(bodyHtml) {
      this.bodyContainer.nativeElement.innerHTML = bodyHtml;
    }

    confirm() {
      // we set dialog result as true on click on confirm button,
      // then we can get dialog result from caller code
      this.result = true;
      this.close();
    }

    closeModal() {
      this.close();
    }

    ngAfterContentInit() {
      this.loadData(this.bodyHtml);
    }

}
