﻿import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './app/login';
import { AccountAdminComponent } from './app/accountAdmin';
import { ChatAdminComponent } from './app/chatAdmin';
import { DashboardComponent } from './app/dashboard';
import { MLDemoComponent } from './app/ml-demo';
import { SiteAdminComponent } from './app/site-admin';
import { ProjectAdminComponent, ProjectEditorComponent } from './app/project-admin';
import { AuthGuard } from './app/_guards';


const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'accountAdmin/:action', component: AccountAdminComponent, canActivate: [AuthGuard] },
    { path: 'accountAdmin/manage/:accountId', component: AccountAdminComponent, canActivate: [AuthGuard] },
    { path: 'chat', component: ChatAdminComponent, canActivate: [AuthGuard] },
    { path: 'project-edit', component: ProjectEditorComponent, canActivate: [AuthGuard] },
    { path: 'site', component: SiteAdminComponent, canActivate: [AuthGuard] },
    { path: 'site/projectEstimates', component: SiteAdminComponent, canActivate: [AuthGuard] },
    { path: 'site/projectEstimates/:projectEstimateId', component: SiteAdminComponent, canActivate: [AuthGuard] },
    { path: 'site/:action', component: SiteAdminComponent, canActivate: [AuthGuard] },
    { path: 'ml-demo', component: MLDemoComponent, canActivate: [AuthGuard] },
    { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'projectAdmin/:action', component: ProjectAdminComponent, canActivate: [AuthGuard] },
    { path: 'projectAdmin/edit/:projectId', component: ProjectAdminComponent, canActivate: [ AuthGuard ] },
    { path: 'projectAdmin/userUploads/:uploadId', component: ProjectAdminComponent, canActivate: [ AuthGuard ] },
    // otherwise redirect to home
    { path: '**', redirectTo: 'login' }
];

export const routing = RouterModule.forRoot(appRoutes);
