﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



import { AuthenticationService } from './authentication.service';
import {
    Account, AccountSearchResults, AdminAccount, AccountNote, ConsultantProfile, Question, Questionnaire,
    QuestionnaireSection, Answer, AccountMessage
} from '../_models';
import * as FileSaver from 'file-saver';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class AccountService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }

    public getAccount(accountId: string, refreshToken: string): Observable<AdminAccount> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/accounts/' + accountId, options)
            .pipe(
                take(1),
                map((response: AdminAccount) => response)
            );
    }

    public getAccountsForOrganization(organizationId: string, refreshToken: string): Observable<AdminAccount[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/organizations/' + organizationId + '/accounts', options)
            .pipe(
                take(1),
                map((response: AdminAccount[]) => response)
            );
    }

    public getConsultantAccountsForProject(projectId: string, refreshToken: string): Observable<AdminAccount[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/consultants', options)
            .pipe(
                take(1),
                map((response: AdminAccount[]) => response)
            );
    }

    public getProjectManagerAccounts(refreshToken: string): Observable<AdminAccount[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/accounts/projectmanagers', options)
            .pipe(
                take(1),
                map((response: AdminAccount[]) => response)
            );
    }

    public getConsultantAccounts(refreshToken: string): Observable<AdminAccount[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/accounts/consultants', options)
            .pipe(
                take(1),
                map((response: AdminAccount[]) => response)
            );
    }

    public setRole(accountId: string, systemRoleId: string,
        enabled: boolean, refreshToken: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const body = {
            accountId,
            systemRoleId,
            enabled
        };
        // post to the API:
        return this.http.post(CONFIG.apiEndpointBase + 'admins/accounts/roles', body, options)
            .pipe(
                take(1),
                map((response: any) => {
                    return true;
                })
            );
    }

    public adminCreateAccount(email: string, firstName: string, lastName: string, sendCreateEmail: boolean,
        refreshToken: string): Observable<AdminAccount> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const body = {
            email,
            firstName,
            lastName,
            sendCreateEmail
        };
        // post to the API:
        return this.http.post(CONFIG.apiEndpointBase + 'admins/newAccounts', body, options)
            .pipe(
                take(1),
                map((response: AdminAccount) => response)
            );
    }

    public upsertAccountNote(note: AccountNote, refreshToken: string): Observable<AccountNote> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // post to the API:
        console.log('posting', note);
        return this.http.post(CONFIG.apiEndpointBase + 'admins/accounts/notes', note, options)
            .pipe(
                take(1),
                map((response: AccountNote) => response)
            );
    }

    public upsertAccountMessage(message: AccountMessage, refreshToken: string): Observable<AccountMessage> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // post to the API:
        console.log('posting', message);
        return this.http.post(CONFIG.apiEndpointBase + 'admins/accounts/messages', message, options)
            .pipe(
                take(1),
                map((response: AccountMessage) => response)
            );
    }

    public getAccountNotes(accountId: string, refreshToken: string): Observable<AccountNote[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const url = `${CONFIG.apiEndpointBase}admins/accounts/${accountId}/notes`;
        console.log('URL', url);
        return this.http.get(url, options)
            .pipe(
                take(1),
                map((response: AccountNote[]) => response)
            );
    }

    public getAccountMessages(accountId: string, refreshToken: string): Observable<AccountMessage[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const url = `${CONFIG.apiEndpointBase}admins/accounts/${accountId}/messages`;
        console.log('URL', url);
        return this.http.get(url, options)
            .pipe(
                take(1),
                map((response: AccountMessage[]) => response)
            );
    }

    public upsertConsultantProfile(consultantProfile: ConsultantProfile, refreshToken: string): Observable<ConsultantProfile> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        console.log('upsert consultant profile:', consultantProfile);
        // post to the API:
        return this.http.post(CONFIG.apiEndpointBase + 'admins/accounts/consultants/profiles', consultantProfile, options)
            .pipe(
                take(1),
                map((response: ConsultantProfile) => response)
            );
    }

    public getConsultantProfileForAccount(accountId: string, refreshToken: string): Observable<ConsultantProfile> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/accounts/consultants/profiles/' + accountId, options)
            .pipe(
                take(1),
                map((response: ConsultantProfile) => response)
            );
    }

    public getRefreshTokenForImpersonation(accountId: string, refreshToken: string): Observable<any> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/auths/impersonations/' + accountId, options)
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }

    public searchAccounts(request: any, refreshToken: string): Observable<AccountSearchResults> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // post to the API:
        return this.http.post(CONFIG.apiEndpointBase + 'admins/auths/accountSearches', request, options)
            .pipe(
                take(1),
                map((response: AccountSearchResults) => response)
            );
    }

    public downloadAccountImage(filename: string, refreshToken: string): Observable<Blob> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        return this.http.get(CONFIG.apiEndpointBase + 'admins/accountImages/' + filename, { headers, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                map((response: HttpResponse<Blob>) => {
                    const fileBlob: Blob = response.body
                    return fileBlob;
                })
            );
    }


    public getConsultantQuestionnaire(refreshToken: string): Observable<Questionnaire> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        return this.http.get(
            CONFIG.apiEndpointBase + 'admins/questionnaires/consultants', { headers: headers })
            .pipe(
                take(1),
                map((response: Questionnaire) => {
                    this.sortQuestionnaire(response);
                    return response;
                }),
                catchError((error) => Observable.throw(error.text()))
            );
    }

    private sortQuestionnaireSections(questionnaire: Questionnaire) {
        if (questionnaire && questionnaire.sections) {
            questionnaire.sections = questionnaire.sections.sort((a, b) => {
                if (a.displayOrder < b.displayOrder) {
                    return -1;
                }
                if (a.displayOrder > b.displayOrder) {
                    return 1;
                }
                return 0;
            });
        }
    }

    private sortQuestionnaireSectionQuestions(section: QuestionnaireSection) {
        if (section.questions) {
            section.questions = section.questions.sort((a, b) => {
                if (a.displayOrder < b.displayOrder) {
                    return -1;
                }
                if (a.displayOrder > b.displayOrder) {
                    return 1;
                }
                return 0;
            });
        }
    }

    private sortQuestionnaire(questionnaire: Questionnaire) {
        this.sortQuestionnaireSections(questionnaire);
        questionnaire.sections.forEach(section => {
            this.sortQuestionnaireSectionQuestions(section);
        });
    }

    public getAnswersForQuestionnaire(questionnaireId: string, consultantId: string, refreshToken: string): Observable<Answer[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        return this.http.get(
            CONFIG.apiEndpointBase + `admins/questionnaires/${questionnaireId}/consultants${consultantId}/answers`,
            { headers: headers })
            .pipe(
                take(1),
                map((response: Answer[]) => response),
                catchError((error) => Observable.throw(error.text()))
            );
    }

    public getAnswersAndAssignToQuestionnaire(questionnaire: Questionnaire, consultantId: string, refreshToken: string): Observable<void> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        return this.http.get(
            CONFIG.apiEndpointBase + `admins/questionnaires/${questionnaire.id}/consultants/${consultantId}/answers`,
            { headers: headers })
            .pipe(
                take(1),
                map((response: Answer[]) => {
                    if (!!response) {
                        questionnaire.sections.forEach(section => {
                            section.questions.forEach(question => {
                                question.uiAnswers = response.filter(a => a.questionId === question.id);
                            });
                        });
                    }
                }),
                catchError((error) => Observable.throw(error.text()))
            );
    }

    public downloadConsultantFile(filename: string, localfilename: string, refreshToken: string): Observable<void> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        return this.http.get(CONFIG.apiEndpointBase + `admins/consultants/uploads/${filename}`,
            { headers, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                map((response: HttpResponse<Blob>) => {
                    const fileBlob: Blob = response.body;
                    FileSaver.saveAs(fileBlob, localfilename);
                })
            );
    }
}
