import { AppConfig } from '../app/_models';

export const environment = {
    production: false
};

export const CONFIG: AppConfig = {
    apiEndpointBase: 'https://flexteamapi-dev.azurewebsites.net/api/v1/',
    websiteBase: 'https://flexteamweb-dev.azurewebsites.net/',
    pubNub: {
        publishKey: 'pub-c-0d1d0dde-1505-4927-828c-9f608df78653',
        subscribeKey: 'sub-c-19eb642e-f459-11e6-9c1c-0619f8945a4f',
        agentMessageBus: 'FT_Agent_Dev'
    }
};