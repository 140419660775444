import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, } from '../_models';
import { AccountService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';
import { select, Store } from '@ngrx/store';
import { catchError, mergeMap, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    moduleId: module.id,
    templateUrl: 'site-admin.component.html',
    styleUrls: ['site-admin.component.css'],
})
export class SiteAdminComponent implements AfterViewInit {

    public loggedInAccount: Account;
    public loading = false;
    public viewState: 'projectEstimates' | 'projectEstimateLayouts' = 'projectEstimates';


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
            }).subscribe();

        router.events.subscribe((val) => {
            this.handleRoute();
        });
    }

    public ngAfterViewInit() {
        this.handleRoute();
    }

    private handleRoute() {
        this.route.params
            .pipe(
                take(1),
                switchMap((params) => {
                    const action = params['action'];
                    if (action === 'projectEstimates') {
                        this.viewState = 'projectEstimates';
                    } else if (action === 'projectEstimateLayouts') {
                        this.viewState = 'projectEstimateLayouts';
                    }
                    return of(undefined)
                }))
            .subscribe();

        this.route.params
            .pipe(
                take(1),
                switchMap((params) => {
                    const projectId = params['projectEstimateId'];
                    const component = this;
                    if (projectId) {
                        this.viewState = 'projectEstimates';
                    }
                    return of(undefined)
                }))
            .subscribe();
    }
}

