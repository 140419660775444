import { SystemRole } from '../_models';

export class RolesHelper {

    public static belongsToRole(roles: any, checkRoles: any): boolean {
        const groups = Object.keys(checkRoles);

        for (const group of groups) {
            const rolesGroup: any[] = roles[group];
            const checkGroup: any[] = checkRoles[group];
            if (rolesGroup && checkGroup) {
                for (const role of checkGroup) {
                    if (rolesGroup.indexOf(role) !== -1) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public static setRoles(roles: any): any {
        const assigned: any = {};

        for (const role of roles) {
            if (!assigned[role.grp]) {
                assigned[role.grp] = new Array<string>();
            }
            assigned[role.grp].push(role.role);
        }

        return assigned;
    }

    public static getRolesKeyedByRoleGroup(allRoles: SystemRole[], groupNameFilter: string[]) {
        const ret: any = {};
        for (const role of allRoles) {
            if (!ret[role.roleGroup] && groupNameFilter.some(s => s === role.roleGroup)) {
                ret[role.roleGroup] = new Array<SystemRole>();
            }
            if (groupNameFilter.some(s => s === role.roleGroup)) {
                ret[role.roleGroup].push(role);
            }
        }

        return ret;
    }
}
