/* tslint:disable:component-class-suffix */
import { Component, Input } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';


@Component({
    moduleId: module.id,
    selector: 'organization-overview-report',
    templateUrl: 'organization-overview-report.component.html',
    styleUrls: ['reports-common.scss']
})
export class OrganizationOverviewReportComponent {
    @Input() containerHeight = 500;

    private _displayed: string;
    public get displayed(): string { return this._displayed; }
    @Input() public set displayed(v: string) { if (v && !this._displayed) { this.getReportData(); } this._displayed = v; }

    public reportData: any[];
    public loggedInAccount: Account;
    public loading = false;


    public childColumns: {
        displayName: string, propertyName: string,
        sortPrimerFunc?: (valIn) => any, dispType: string,
        fixedDec?: number, styleOverride?: any
    }[] = [
            { displayName: 'Organization', propertyName: 'organizationName', dispType: 'string' },
            { displayName: 'Total Accounts', propertyName: 'totalAccounts', dispType: 'number' },
            { displayName: 'First Project Start Date', propertyName: 'firstProjectStartDate', dispType: 'date' },
            { displayName: 'Last Project Start Date', propertyName: 'lastProjectStartDate', dispType: 'date' },
            { displayName: 'Total Projects', propertyName: 'totalProjects', dispType: 'number' },
            { displayName: 'Total Projects Completed', propertyName: 'totalProjectsCompleted', dispType: 'number' },
            { displayName: 'Avg Projects / Month', propertyName: 'avgProjectsPerMonth', dispType: 'fixed', fixedDec: 1 },
            { displayName: 'Total Successful Charge Amount', propertyName: 'totalSuccessfulChargeAmount', dispType: 'money' },
            { displayName: 'Avg $ Charged / Month', propertyName: 'avgSuccessfulChargesPerMonth', dispType: 'money' },
            { displayName: 'Avg $ Charged / Project', propertyName: 'avgSuccessfulChargesPerProject', dispType: 'money' }
        ];

    public initialSorts: { propertyName: string, reverse: boolean }[] = [];

    public get childTableHeight(): number {
        return this.containerHeight;
    }

    constructor(
        private accountService: AccountService,
        private reportsService: ReportsService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                // this.getReportData();
            }).subscribe();
    }

    private getReportData() {
        if (!this.reportData || this.reportData.length === 0) {
            this.loading = true;
            this.reportsService.getReport('organizationOverview', this.loggedInAccount.refreshToken)
                .subscribe(reportData => {
                    if (reportData) {
                        this.initialSorts = [
                            { propertyName: 'organizationName', reverse: false },
                            { propertyName: 'totalAccounts', reverse: true }
                        ];
                        this.reportData = reportData;
                    }
                    this.loading = false;
                });
        }
    }

}


