import { Component, AfterViewInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Account, Project, ProjectScopeDeliverable, ProjectTimeTracking, PaymentAccountWallet,
    StripeBankAccount, StripeCard, AdminAccount, PaymentTransaction,
    ProjectScopeRateInfo, ProjectInvoice, ProjectInvoiceLineItem, ExternalPaymentAccount
} from '../_models';
import { AccountService, ProjectService, PaymentService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { IMyOptions, IMyDateModel, MyDatePicker, IMyDate } from 'mydatepicker';
import { forEach } from '@angular/router/src/utils/collection';
import * as moment from 'moment';
import { CONFIG } from '../../environments/environment';
@Component({
    selector: 'projectBilling',
    moduleId: module.id,
    templateUrl: 'project-billing.component.html',
    styleUrls: ['project-billing.component.scss'],
})
export class ProjectBillingComponent implements AfterViewInit, OnDestroy {
    @Input() public project: Project;
    @Input() public deliverables: ProjectScopeDeliverable[];
    @Input() public rates: ProjectScopeRateInfo[];
    @Input() public allOrganizationAccounts: AdminAccount[] = [];
    @ViewChild('dueDate') dueDatePicker: MyDatePicker;

    public allInvoices: ProjectInvoice[] = [];
    public selectedInvoice: ProjectInvoice = null;

    public projectWallet: PaymentAccountWallet = null;
    public showBilling = false;
    public selectedCC: StripeCard = null;
    public selectedPO: ExternalPaymentAccount = null;
    public selectedTerms: ExternalPaymentAccount = null;
    public selectedExternalPaymentSource = false;
    public externalPaymentAccountName: string = null;
    public externalPaymentAccountType: string = null;
    public transactionResult: PaymentTransaction = null;
    public updateTransaction: PaymentTransaction = null;
    public charging = false;

    public dirtiedAt: number;
    public saving: boolean;
    public saved: boolean;
    public saveError: boolean;
    public invalid: boolean;

    public isEditingNote = false;
    public lineItemUnderEdit: ProjectInvoiceLineItem = null;
    public isEditingPop = false;

    public datePickerOptions: IMyOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy'
    };

    public noteEditorConfig: any = {
        height: 500,
        uiColor: '#FFFFFF',
        language: 'en',
        allowedContent: true,
        enterMode: 2,
        shiftEnterMode: 2,
        toolbar: [
            { name: 'editing', items: ['Scayt', 'Find', 'Replace', 'SelectAll'] },
            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-', 'RemoveFormat'] },
        ]
    };

    public loggedInAccount: Account;
    public loading = false;


    constructor(
        private accountService: AccountService,
        private projectService: ProjectService,
        private paymentService: PaymentService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                this.showBilling = RolesHelper
                    .belongsToRole(this.loggedInAccount.roles, { System: ['BillingAdmin'] });
            }).subscribe();
    }

    public ngAfterViewInit() {
        this.loadInvoices(null);
    }

    public ngOnDestroy() {
        this.selectedInvoice = null;
        this.allInvoices = null;
    }

    public loadInvoices(preLoadId: string) {
        this.allInvoices = [];
        this.selectedInvoice = null;
        this.selectedCC = null;
        this.updateTransaction = null;
        this.selectedExternalPaymentSource = false;
        this.externalPaymentAccountName = null;
        this.externalPaymentAccountType = null;
        this.charging = false;
        this.transactionResult = null;
        this.projectWallet = null;
        this.projectService.getProjectInvoices(this.project.id, this.loggedInAccount.refreshToken)
            .subscribe((invoices) => {
                this.allInvoices = invoices;
                // console.log('invoices', this.allInvoices);
                if (preLoadId) {
                    this.selectInvoice(invoices.find(i => { return i.id === preLoadId; }));
                }
            });
    }

    public createInvoice() {
        if (this.project) {
            const newInvoice: ProjectInvoice = new ProjectInvoice();
            newInvoice.projectId = this.project.id;
            newInvoice.visible = true;
            this.projectService.upsertProjectInvoice(newInvoice, this.loggedInAccount.refreshToken)
                .subscribe(inv => {
                    console.log('created invoice:', inv);
                    this.loadInvoices(inv.id);
                });
        }
    }

    public createInvoiceLineItem() {
        if (this.selectedInvoice) {
            // console.log('creating line item inner', this.selectedInvoice.lineItems);
            const newItem: ProjectInvoiceLineItem = new ProjectInvoiceLineItem();
            newItem.projectInvoiceId = this.selectedInvoice.id;
            newItem.lineNumber = this.selectedInvoice.lineItems.length + 1;
            newItem.tax = 0;
            newItem.discount = 0;
            this.selectedInvoice.lineItems.push(newItem);
            // console.log('created line item inner', this.selectedInvoice.lineItems);
        }
    }

    private resetFlags() {
        this.dirtiedAt = null;
        this.saving = false;
        this.saved = false;
        this.saveError = false;
        this.invalid = false;
    }

    public getInvoiceHasApprovedTransaction(inv: ProjectInvoice): boolean {
        if (inv.transactions) {
            return inv.transactions.findIndex(tx => tx.approved) >= 0;
        } else {
            return false;
        }
    }


    public selectInvoice(invoice: ProjectInvoice) {
        this.selectedInvoice = invoice;
        this.resetFlags();
        this.setDueDate();
        this.setPaymentForTransaction(invoice);
        this.runSaveLoop();
    }

    private setDueDate() {
        setTimeout(() => {
            if (this.selectedInvoice.dueDate && this.dueDatePicker) {
                const jsDate = new Date(this.selectedInvoice.dueDate);
                const myDate: IMyDate = {
                    day: jsDate.getUTCDate(),
                    month: jsDate.getUTCMonth() + 1,
                    year: jsDate.getUTCFullYear()
                };
                this.dueDatePicker.updateDateValue(myDate, false);
            }
        }, 0);
    }

    private setPaymentForTransaction(invoice: ProjectInvoice) {
        invoice.transactions.forEach(tx => {
            console.log('tx', tx);
            if (tx.paymentTransactionDetailTypeName === 'StripePaymentTransactionDetail') {
                this.paymentService.getStripeCardByPaymentAccountId
                    (tx.paymentAccountId, this.loggedInAccount.refreshToken)
                    .subscribe(card => {
                        tx.paymentAccountCard = card;
                    });
            } else if (tx.paymentTransactionDetailTypeName === 'ExternalPaymentTransactionDetail') {
                this.paymentService.getExternalPaymentAccountByPaymentAccountId
                    (tx.paymentAccountId, this.loggedInAccount.refreshToken)
                    .subscribe(ex => {
                        tx.paymentAccountExternal = ex;
                    });
            }
        });
    }


    public canShowDeliverable(deliverable: ProjectScopeDeliverable): boolean {
        const hit = this.rates.find((r) => {
            return r.projectScopeDeliverableIds.indexOf(deliverable.id) >= 0 && r.audience === 'Client';
        });
        return hit != null;
    }

    public getLineItemTotal(lineItem: ProjectInvoiceLineItem): number {
        let ret = (lineItem.quantity * lineItem.unitPrice) + lineItem.tax - lineItem.discount;
        if (!ret) { ret = 0; }
        return ret;
    }


    public deliverableChange(item: ProjectInvoiceLineItem, deliverableId: string) {
        this.lineItemChanged(item);
        // get the client rate associated to the client deliverable:
        const topRate = this.rates.find((r) => {
            return r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0 && r.audience === 'Client';
        });
        const deliverable = this.deliverables.find((d) => {
            return d.id === deliverableId;
        });
        // don't override description if set:
        if (!item.description || item.description.length === 0) {
            item.description = deliverable.name;
        }
        if (topRate) {
            if (topRate.rateType === 'Hourly') {
                item.uom = 'Hour';
                this.popPeriodOfPerformance(item);
            } else if (topRate.rateType === 'Recurring') {
                // set the days specified on the rate:
                item.uom = 'Day';
                item.quantity = topRate.recurringDays;
                item.unitPrice = topRate.rate / topRate.recurringDays;
            } else if (topRate.rateType === 'Flat') {
                // set the flat rate:
                item.uom = 'Flat';
                item.quantity = 1;
                item.unitPrice = topRate.rate;
            } else if (topRate.rateType === 'Monthly') {
                // set the monthly rate:
                item.uom = 'Month';
                item.quantity = 1;
                item.unitPrice = topRate.rate;
            }
        }
    }


    public getInvoiceTotal(inv: ProjectInvoice): number {
        if (inv && inv.lineItems) {
            let total = 0;
            inv.lineItems.forEach(li => {
                total += this.getLineItemTotal(li);
            });
            return total;
        }
        return 0;
    }

    public getChargeDescription() {
        return 'Inv#' + this.selectedInvoice.invoiceNumber + ': ' + this.project.name;
    }

    public cancelChargeAccount() {
        this.projectWallet = null;
        this.selectedCC = null;
        this.selectedPO = null;
        this.selectedTerms = null;
        this.selectedExternalPaymentSource = false;
        this.loadInvoices(this.selectedInvoice.id);
    }

    public chargeAccount() {
        if (!this.selectedInvoice.transactions) {
            this.selectedInvoice.transactions = [];
        }
        if (this.selectedCC) {
            if (this.selectedCC) {
                this.charging = true;
                this.paymentService.chargePaymentAccount(this.selectedCC.paymentAccountId, this.getInvoiceTotal(this.selectedInvoice),
                    this.getChargeDescription(), this.selectedInvoice.id, null, null,
                    this.loggedInAccount.refreshToken).subscribe(tx => {
                        this.transactionResult = tx;
                        this.selectedInvoice.transactions.push(tx);
                        this.charging = false;
                        this.cancelChargeAccount();
                        console.log(tx);
                    });
            }
        } else if (this.selectedExternalPaymentSource) {
            // first, create the external payment account:
            this.charging = true;
            this.paymentService.chargePaymentAccount(null, this.getInvoiceTotal(this.selectedInvoice),
                this.getChargeDescription(), this.selectedInvoice.id,
                this.externalPaymentAccountName, this.externalPaymentAccountType,
                this.loggedInAccount.refreshToken).subscribe(tx => {
                    this.transactionResult = tx;
                    this.transactionResult.approved = true; // not really yet, but don't want to show X
                    this.selectedInvoice.transactions.push(tx);
                    this.charging = false;
                    this.cancelChargeAccount();
                    console.log(tx);
                });
        } else if (this.selectedPO) {
            this.charging = true;
            this.paymentService.chargePaymentAccount(this.selectedPO.paymentAccountId, this.getInvoiceTotal(this.selectedInvoice),
                this.getChargeDescription(), this.selectedInvoice.id,
                this.externalPaymentAccountName, this.externalPaymentAccountType,
                this.loggedInAccount.refreshToken).subscribe(tx => {
                    this.transactionResult = tx;
                    this.transactionResult.approved = true; // not really yet, but don't want to show X
                    this.selectedInvoice.transactions.push(tx);
                    this.charging = false;
                    this.cancelChargeAccount();
                    console.log(tx);
                });
        } else if (this.selectedTerms) {
            this.charging = true;
            this.paymentService.chargePaymentAccount(this.selectedTerms.paymentAccountId, this.getInvoiceTotal(this.selectedInvoice),
                this.getChargeDescription(), this.selectedInvoice.id,
                this.externalPaymentAccountName, this.externalPaymentAccountType,
                this.loggedInAccount.refreshToken).subscribe(tx => {
                    this.transactionResult = tx;
                    this.transactionResult.approved = true; // not really yet, but don't want to show X
                    this.selectedInvoice.transactions.push(tx);
                    this.charging = false;
                    this.cancelChargeAccount();
                    console.log(tx);
                });
        }
    }

    private isValid(i: ProjectInvoiceLineItem): boolean {
        const ret =
            (
                i.discount !== null &&
                i.projectScopeDeliverableId !== null &&
                i.description !== null && i.description.length > 0 &&
                i.quantity > 0 &&
                i.unitPrice > 0 &&
                i.discount !== null
            );
        return ret;
    }

    private runSaveLoop() {
        const loopInvoice = this.selectedInvoice;
        const interval = setInterval(() => {
            if (!this.selectedInvoice || this.selectedInvoice !== loopInvoice) {
                clearInterval(interval);
            }
            if (this.selectedInvoice) {
                // console.log('interval', loopInvoice.invoiceNumber);
                if (this.dirtiedAt && (Date.now() - this.dirtiedAt > 1000)) {
                    this.saving = true;
                    this.invalid = false;
                    this.projectService.upsertProjectInvoice(this.selectedInvoice, this.loggedInAccount.refreshToken)
                        .subscribe((inv) => {
                            this.selectedInvoice.notes = inv.notes;
                            this.selectedInvoice.dueDate = inv.dueDate;
                            this.saved = true;
                            this.saving = false;
                            this.dirtiedAt = null;
                        }, (err) => {
                            this.saveError = true;
                            this.saving = false;
                            this.saved = false;
                            this.dirtiedAt = null;
                        });
                }
                this.selectedInvoice.lineItems.forEach(i => {
                    if (i.dirtiedAt && (Date.now() - i.dirtiedAt > 1000)) {
                        if (this.isValid(i)) {
                            i.saving = true;
                            i.invalid = false;
                            this.projectService.upsertProjectInvoiceLineItem(i, this.loggedInAccount.refreshToken)
                                .subscribe((lineItem: ProjectInvoiceLineItem) => {
                                    // console.log('saved line item:', lineItem);
                                    //transfer the alert
                                    if (i.alert) {
                                        lineItem.alert = i.alert;
                                    }

                                    if (!i.id) {
                                        const idx = this.selectedInvoice.lineItems.indexOf(i);
                                        this.selectedInvoice.lineItems[idx] = lineItem;
                                        lineItem.saved = true;
                                        lineItem.saving = false;
                                    } else {
                                        i.saveError = false;
                                        i.saving = false;
                                        i.invalid = false;
                                        i.dirtiedAt = null;
                                        i.saved = true;
                                        i.saving = false;
                                    }
                                }, (err) => {
                                    i.saveError = true;
                                    i.saving = false;
                                    i.saved = false;
                                });
                        } else {
                            i.invalid = true;
                        }
                    }
                });
            }
        }, 1000);
    }

    public lineItemChanged(lineItem: ProjectInvoiceLineItem) {
        lineItem.dirtiedAt = Date.now();
        lineItem.saveError = false;
        lineItem.saved = false;
        lineItem.saving = false;
    }

    public selectedInvoiceChanged() {
        this.dirtiedAt = Date.now();
        this.saveError = false;
        this.saved = false;
        this.saving = false;
    }

    public deleteLine(lineItem: ProjectInvoiceLineItem) {
        if (lineItem.id) {
            this.projectService.deleteProjectInvoiceLineItem(lineItem, this.loggedInAccount.refreshToken)
                .subscribe(() => {
                    const idx = this.selectedInvoice.lineItems.indexOf(lineItem);
                    this.selectedInvoice.lineItems.splice(idx, 1);
                    let num = 1;
                    this.selectedInvoice.lineItems.forEach(li => {
                        li.lineNumber = num;
                        console.log('saving', li);
                        this.projectService.upsertProjectInvoiceLineItem(li, this.loggedInAccount.refreshToken)
                            .subscribe((res) => {
                                console.log('saved', res);
                            });
                        num++;
                    });
                }, (error) => {
                    lineItem.saveError = true;
                    lineItem.saving = false;
                    lineItem.saved = false;
                });
        } else {
            const idx = this.selectedInvoice.lineItems.indexOf(lineItem);
            this.selectedInvoice.lineItems.splice(idx, 1);
        }
    }

    public deleteInvoice(invoiceId: string) {
        this.projectService.deleteProjectInvoice(invoiceId, this.loggedInAccount.refreshToken)
            .subscribe(() => {
                this.loadInvoices(null);
            });
    }

    public showChargeComponent() {
        this.projectWallet = null;
        this.paymentService.getProjectWallet(this.project.id, this.loggedInAccount.refreshToken)
            .subscribe(wallet => {
                this.projectWallet = wallet;
                console.log('wallet', this.projectWallet);
            });
    }

    public getNameForAccountId(accountId: string): string {
        if (this.allOrganizationAccounts) {
            const found = this.allOrganizationAccounts.find(a => { return a.id === accountId; });
            if (found) {
                return found.firstName + ' ' + found.lastName;
            }
        }
        return 'Unknown';
    }

    public onDueDateChanged(event: IMyDateModel) {
        this.selectedInvoice.dueDate = event.formatted;
        this.dirtiedAt = Date.now();
    }

    public updateExternalTransaction() {
        this.paymentService.updateExternalPaymentTransaction(
            this.updateTransaction.id,
            this.updateTransaction.transactionDetail.paid,
            this.updateTransaction.transactionDetail.cleared,
            this.updateTransaction.paymentAccountExternal.accountName,
            this.loggedInAccount.refreshToken
        ).subscribe(() => {
            this.loadInvoices(this.updateTransaction.projectInvoiceId);
            this.updateTransaction = null;
        });
    }

    public openInvoiceOnWebsite(invoiceId: string) {
        window.open(CONFIG.websiteBase + 'project/invoice/' + invoiceId, '_blank');
    }

    public editNotes(lineItem: ProjectInvoiceLineItem) {
        this.lineItemUnderEdit = lineItem;
        this.isEditingNote = true;
    }

    public cancelEditNotes() {
        this.isEditingNote = false;
        this.lineItemUnderEdit = null;
    }

    public popPeriodOfPerformance(item: ProjectInvoiceLineItem) {
        this.lineItemUnderEdit = item;
        this.isEditingPop = true;
    }

    public periodBeginningChanged(event: IMyDateModel) {
        this.lineItemUnderEdit.periodBeginning = event.formatted;
        this.lineItemUnderEdit.dirtiedAt = Date.now();
    }

    public periodEndingChanged(event: IMyDateModel) {
        this.lineItemUnderEdit.periodEnding = event.formatted;
        this.lineItemUnderEdit.dirtiedAt = Date.now();
    }

    public confirmPeriodOfPerformance() {
        if (this.lineItemUnderEdit.uom === 'Hour' && this.lineItemUnderEdit.periodBeginning && this.lineItemUnderEdit.periodEnding) {
            // invoices are sorted from the service by invoice number, which is incremented on db:
            // Get some invoice dates to use for determinations below
            const endDate = new Date(this.lineItemUnderEdit.periodEnding);
            const beginDate: Date = new Date(this.lineItemUnderEdit.periodBeginning);

            const topRate = this.rates.find((r) => {
                return r.projectScopeDeliverableIds.indexOf(this.lineItemUnderEdit.projectScopeDeliverableId) >= 0
                    && r.audience === 'Client';
            });
            this.projectService.getTimeTrackingForScope(topRate.projectScopeId, this.loggedInAccount.refreshToken)
                .subscribe((trackingEntriesForScope) => {
                    // console.log('got tracking entries');
                    // grab the hours worked, and pre-populate qty:
                    // add up the total hours:
                    let totalHoursWorked = 0;
                    this.lineItemUnderEdit.notes = '';
                    const notesItems: ProjectTimeTracking[] = [];
                    trackingEntriesForScope.forEach(entry => {
                        const dt = new Date(entry.dateWorked);
                        if (dt <= endDate && dt >= beginDate) {
                            if (entry.approvedOn && entry.approvedByAccountId) {
                                totalHoursWorked += entry.billableHours;
                                notesItems.push(entry);
                            } else {
                                if (!this.lineItemUnderEdit.alert) {
                                    this.lineItemUnderEdit.alert = 'There are unapproved hours in this period of performance!';
                                }
                            }
                        }
                    });

                    // subtract all hours on other invoices that came before, or are on this invoice, that are covered by the date range:
                    this.allInvoices.forEach(i => {
                        i.lineItems.forEach(li => {
                            const dt = new Date(li.created);
                            if (dt <= endDate && dt >= beginDate) {
                                if (li.projectScopeDeliverableId === this.lineItemUnderEdit.projectScopeDeliverableId
                                    && li.id !== this.lineItemUnderEdit.id && li.uom === this.lineItemUnderEdit.uom) {
                                    totalHoursWorked -= li.quantity;
                                    // want to remove from note, but can't because they are not tied to the tracking item.
                                }
                            }
                        });
                    });

                    if (notesItems && notesItems.length > 0) {
                        notesItems.forEach(item => {
                            const dt = new Date(item.dateWorked);
                            const entryDateFmt = moment(dt).format('L');
                            if (dt > beginDate && dt <= endDate) {
                                this.lineItemUnderEdit.notes += `<b>${entryDateFmt}: </b>${item.description}<br>`;
                            }
                        });
                    }

                    this.lineItemUnderEdit.quantity = totalHoursWorked;
                    this.lineItemUnderEdit.unitPrice = topRate.rate;

                    // console.log('item updated with notes', item);
                    this.isEditingPop = false;
                    this.lineItemUnderEdit = null;
                });
        }
    }

}

