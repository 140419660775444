﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models/account';
import { LOGIN, LOGOUT } from '../_reducers/account.reducer';
import { MLDemoService } from './ml-demo.service';
import { HttpClient } from '@angular/common/http';

@Component({
    moduleId: module.id,
    templateUrl: 'ml-demo.component.html',
    styleUrls: ['ml-demo.component.css']
})
export class MLDemoComponent implements OnInit {
    public loggedInAccount: Account = null;
    public showAll = false;
    private svc: MLDemoService = null;
    public score: Number = null;
    public tagCloudResults: any = null;
    public selectedTagCloudResult: any = null;
    constructor(
        private http: HttpClient,
        private router: Router,
        private authenticationService: AuthenticationService,
        private store: Store<Account>) {
        this.store.select('loggedInAccount').select((account: Account) => {
            this.loggedInAccount = account;
        }).subscribe();
        this.svc = new MLDemoService(http);
    }

    ngOnInit() {

    }

    public canSubmit(form): boolean {
        const ret = (
            form.projectId.value &&
            form.topic.value &&
            form.category.value &&
            form.person.value &&
            form.order.value &&
            form.tld.value &&
            form.used.value &&
            form.articleType.value &&
            form.authorType.value
        );
        console.log('can submit?', ret, form);
        return ret;
    }

    public getScorePrediction(form) {
        const reqObj = {
            projectId: form.projectId.value,
            topic: form.topic.value,
            category: form.category.value,
            person: form.person.value,
            order: form.order.value,
            fullUrl: form.fullUrl.value,
            urlId: form.urlId.value,
            used: form.used.value,
            wordCountSnippet: form.wordCountSnippet.value,
            originType: form.originType.value,
            tld: form.tld.value,
            articleType: form.articleType.value,
            date: form.date.value,
            wordCount: form.wordCount.value,
            author: form.author.value,
            authorType: form.authorType.value,
            authorCompany: form.authorCompany.value,
            authorTwitterFollowers: form.authorTwitterFollowers.value,
            articleShares: form.articleShares.value,
            articleComments: form.articleComments.value,
            citedSources: form.citedSources.value,
            citedSourcesCount: form.citedSourcesCount.value,
            linkedSources: form.linkedSources.value,
            linkedSourcesCount: form.linkedSourcesCount.value,
            tech: form.tech.value,
            adNetwork: form.adNetwork.value,
            recommendedSources: form.recommendedSources.value
        };
        console.log('req', reqObj);

        this.svc.getArticlePageQualityPrediction(reqObj, this.loggedInAccount).subscribe((result) => {
            console.log('Result:', result);
            if (result.Results && result.Results.output1) {
                this.score = result.Results.output1[0]['Scored Label Mean'];
            }
        });

    }

    public getConsultantVisualization(form) {
        this.selectedTagCloudResult = null;
        const reqObj = {
            consultantName: form.consultantName.value,
        };
        console.log('req', reqObj);
        this.svc.getConsultantTagClouds(reqObj, this.loggedInAccount).subscribe((result) => {
            console.log('Result:', result);
            this.tagCloudResults = result;
            if (this.tagCloudResults.clouds.length === 1) {
                this.selectedTagCloudResult = this.tagCloudResults.clouds[0];
            }
        });
    }

    public shuffle(array) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }
}
