export class ProjectEstimationSiteSection {
    id: string;
    name: string;
    displayOrder: number;
    visible: boolean;
    groups: ProjectEstimationSiteSectionGroup[];
}

export class ProjectEstimationSiteSectionGroup {
    id: string;
    projectEstimationSiteSectionId: string;
    name: string;
    description: string;
    headerImageName: string;
    displayOrder: number;
    visible: boolean;
    assignments: ProjectEstimationSiteSectionGroupAssignment[];
}

export class ProjectEstimationSiteSectionGroupAssignment {
    id: string;
    projectEstimationSiteSectionGroupId: string;
    projectEstimationId: string;
    displayOrder: number;
}
