﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AuthGuard, PendingChangesGuard } from './app/_guards';
import { accountReducer } from './app/_reducers/account.reducer';
import {
    AuthenticationService, AccountService, SystemRolesService,
    PaymentService, ChatService, ProjectService, OrganizationService,
    UtilityService, ListService, SiteService, ReportsService
} from './app/_services';
import { LoginComponent } from './app/login';
import { DashboardComponent, MonthlyProjectsReportComponent, ProjectOverviewReportComponent, FlexTeamOverviewReportComponent, AccountsOverviewReportComponent, OrganizationOverviewReportComponent, ConsultantOverviewReportComponent, ReportTableComponent, DeliverablesOverviewReportComponent, InvoiceOverviewReportComponent } from './app/dashboard';
import { HeaderComponent } from './app/header';
import { MLDemoComponent } from './app/ml-demo';
import { AccountAdminComponent, AccountManagementComponent, AccountSearchComponent, OrganizationAdminComponent } from './app/accountAdmin';
import { SiteAdminComponent, ProjectTypesComponent, ProjectTypesLayoutComponent } from './app/site-admin';
import { ChatAdminComponent, ChatHistoryComponent } from './app/chatAdmin';
import { PopoverModule } from 'ngx-popover';
import {
    SpinnerComponent, ConfirmModalComponent, InputModalComponent,
    AlertModalComponent, CustomModalComponent, MultiSelectComponent,
    MultiSelectComponent2, SingleSelectComponent, AsyncImageComponent,
    AsyncAudioComponent, AsyncTextComponent, SafePipe, SafeHtmlPipe
} from './app/uiHelpers';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import {
    ProjectAdminComponent, ProjectListComponent, ProjectBillingComponent, ProjectFilePermissionsComponent,
    ProjectEditorComponent, UserUploadsComponent, ProjectConsultantComponent
} from './app/project-admin';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { PubNubAngular } from 'pubnub-angular2';
import { TimeAgoPipe } from 'time-ago-pipe';
import { MyDatePickerModule } from 'mydatepicker';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { CKEditorModule } from 'ng2-ckeditor';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { DragulaModule } from 'ng2-dragula';
import { MomentModule } from 'angular2-moment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';

@NgModule({
    imports: [
        BrowserModule,
        BootstrapModalModule,
        CKEditorModule,
        DragulaModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        MomentModule,
        MultiselectDropdownModule,
        PopoverModule,
        ChartsModule,
        routing,
        StoreModule.forRoot({
            loggedInAccount: accountReducer
        }),
        TextareaAutosizeModule,
        MyDatePickerModule,
        Ng2TableModule
    ],
    declarations: [
        AppComponent,
        AsyncAudioComponent,
        AsyncImageComponent,
        AsyncTextComponent,
        LoginComponent,
        DashboardComponent,
        HeaderComponent,
        AccountAdminComponent,
        ChatAdminComponent,
        ChatHistoryComponent,
        AccountManagementComponent,
        AccountSearchComponent,
        SpinnerComponent,
        MLDemoComponent,
        MultiSelectComponent,
        MultiSelectComponent2,
        OrganizationAdminComponent,
        SingleSelectComponent,
        ConfirmModalComponent,
        AlertModalComponent,
        CustomModalComponent,
        InputModalComponent,
        ProjectAdminComponent,
        ProjectEditorComponent,
        ProjectListComponent,
        SafePipe,
        SafeHtmlPipe,
        TimeAgoPipe,
        UserUploadsComponent,
        SiteAdminComponent,
        ProjectTypesComponent,
        ProjectTypesLayoutComponent,
        ProjectBillingComponent,
        ProjectConsultantComponent,
        ProjectFilePermissionsComponent,

        MonthlyProjectsReportComponent,
        ProjectOverviewReportComponent,
        FlexTeamOverviewReportComponent,
        AccountsOverviewReportComponent,
        OrganizationOverviewReportComponent,
        ConsultantOverviewReportComponent,
        DeliverablesOverviewReportComponent,
        ReportTableComponent,
        InvoiceOverviewReportComponent
    ],
    providers: [
        AuthGuard,
        AuthenticationService,
        AccountService,
        SystemRolesService,
        PaymentService,
        ChatService,
        ListService,
        ProjectService,
        OrganizationService,
        ReportsService,
        SiteService,
        UtilityService,
        PubNubAngular,
        PendingChangesGuard
    ],
    entryComponents: [
        ConfirmModalComponent,
        AlertModalComponent,
        CustomModalComponent,
        InputModalComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
