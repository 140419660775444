import { Component, AfterViewInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Account, ProjectScopeRateInfo, ProjectScopeDeliverable,
    Project, AdminAccount, ProjectTimeTracking, APTimeTrackingInvoice
} from '../_models';
import { AccountService, ProjectService, PaymentService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { IMyOptions, IMyDateModel, MyDatePicker, IMyDate } from 'mydatepicker';
import { CONFIG } from '../../environments/environment';
class UIConsultant {
    account: AdminAccount;
    hours: ProjectTimeTracking[];
    workedHours: number;
    paidHours: number;
    unpaidHours: number;
    notes: string;
    internalNotes: string;
    invoices: APTimeTrackingInvoice[];
}

@Component({
    selector: 'projectConsultant',
    moduleId: module.id,
    templateUrl: 'project-consultant.component.html',
    styleUrls: ['project-consultant.component.scss'],
})
export class ProjectConsultantComponent implements AfterViewInit {

    @Input() public project: Project = null;
    @Input() public deliverables: ProjectScopeDeliverable[] = [];
    @Input() public rates: ProjectScopeRateInfo[] = [];
    @ViewChild('totalBox') public totalBox: ElementRef;

    public showBilling = false;
    public loggedInAccount: Account;
    public loading = false;

    public consultants: UIConsultant[] = null;
    public selectedPayConsultant: UIConsultant;
    public selectedViewConsultant: UIConsultant;
    public createButtonEnabled = false;
    public createDisabledReason = null;

    constructor(
        private accountService: AccountService,
        private projectService: ProjectService,
        private paymentService: PaymentService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                this.showBilling = RolesHelper
                    .belongsToRole(this.loggedInAccount.roles, { System: ['BillingAdmin'] });
            }).subscribe();
    }

    public ngAfterViewInit() {
        this.getData();
    }

    private getData() {
        this.projectService.getTimeTrackingForProject(this.project.id, this.loggedInAccount.refreshToken)
            .subscribe(hours => {
                this.accountService.getConsultantAccountsForProject(this.project.id, this.loggedInAccount.refreshToken)
                    .subscribe(accounts => {
                        this.projectService.getAPTimeTrackingInvoicesForProject(this.project.id, this.loggedInAccount.refreshToken)
                            .subscribe(apTimeTrackingInvoices => {
                                this.consultants = [];
                                this.selectedPayConsultant = null;
                                accounts.forEach(a => {
                                    const c = {
                                        account: a,
                                        hours: [],
                                        workedHours: 0,
                                        paidHours: 0,
                                        unpaidHours: 0,
                                        internalNotes: '',
                                        notes: '',
                                        invoices: []
                                    };
                                    this.consultants.push(c);
                                    hours.forEach(h => {
                                        if (h.accountId === a.id) {
                                            c.hours.push(h);
                                            c.workedHours += h.billableHours;
                                            if (h.apTimeTrackingInvoiceId) {
                                                // console.log('tt '+ this.project.id, apTimeTrackingInvoices);
                                                c.paidHours += h.billableHours;
                                                const inv = apTimeTrackingInvoices.find(i => { return i.id === h.apTimeTrackingInvoiceId; });
                                                if (inv && c.invoices.indexOf(inv) < 0) {
                                                    c.invoices.push(inv);
                                                }
                                                c.invoices = c.invoices.sort((ia, ib) => {
                                                    if (ia.invoiceNumber < ib.invoiceNumber) {
                                                        return -1;
                                                    }
                                                    if (ia.invoiceNumber > ib.invoiceNumber) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                });
                                            }
                                        }
                                    });
                                    c.unpaidHours = c.workedHours - c.paidHours;
                                });
                            });
                    });
            });
    }

    public getConsultantAccount(consultantAccountId: string) {
        return this.consultants.find(c => { return c.account.id === consultantAccountId; });
    }

    public canCheck(h: ProjectTimeTracking): boolean {
        return (h.approvedByAccountId || h.approvedOn) && !h.apTimeTrackingInvoiceId;
    }

    public checkAll(checked: boolean) {
        this.selectedPayConsultant.hours.forEach(h => {
            if (this.canCheck(h)) {
                h.uiChecked = checked;
            }
        });
        this.setTotal();
    }

    private getHourlyRate(consultantAccountId: string, deliverableId: string) {
        const rate = this.rates.find(r => {
            return r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0 &&
                r.consultantAccountId === consultantAccountId &&
                r.rateType === 'Hourly';
        });

        return rate;
    }

    private getFlatRate(consultantAccountId: string, deliverableId: string) {
        const rate = this.rates.find(r => {
            return r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0 &&
                r.consultantAccountId === consultantAccountId &&
                r.rateType === 'Flat';
        });

        return rate;
    }

    private getRecurringRate(consultantAccountId: string, deliverableId: string) {
        const rate = this.rates.find(r => {
            return r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0 &&
                r.consultantAccountId === consultantAccountId &&
                r.rateType === 'Recurring';
        });

        return rate;
    }

    private getMonthlyRate(consultantAccountId: string, deliverableId: string) {
        const rate = this.rates.find(r => {
            return r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0 &&
                r.consultantAccountId === consultantAccountId &&
                r.rateType === 'Monthly';
        });

        return rate;
    }

    private getTotalFlat(): number {
        let ret = 0;
        const foundRateIds: string[] = [];
        this.selectedPayConsultant.hours.forEach(h => {
            if (h.uiChecked) {
                const rate = this.getFlatRate(this.selectedPayConsultant.account.id, h.projectScopeDeliverableId);
                if (rate) {
                    if (foundRateIds.indexOf(rate.id) < 0) {
                        ret += rate.rate;
                        foundRateIds.push(rate.id);
                    }
                }
            }
        });
        return ret;
    }

    private getTotalAdjustments(): number {
        let ret = 0;
        const foundRateIds: string[] = [];
        this.selectedPayConsultant.hours.forEach(h => {
            if (h.uiChecked && h.invoiceAdjustmentAmount) {
                ret += h.invoiceAdjustmentAmount;
            }
        });
        return ret;
    }

    private getTotalRecurring(): number {
        let ret = 0;
        const foundRateIds: string[] = [];
        this.selectedPayConsultant.hours.forEach(h => {
            if (h.uiChecked) {
                const rate = this.getRecurringRate(this.selectedPayConsultant.account.id, h.projectScopeDeliverableId);
                if (rate) {
                    if (foundRateIds.indexOf(rate.id) < 0) {
                        ret += rate.rate;
                        foundRateIds.push(rate.id);
                    }
                }
            }
        });
        return ret;
    }

    private getTotalMonthly(): number {
        let ret = 0;
        const foundRateIds: string[] = [];
        this.selectedPayConsultant.hours.forEach(h => {
            if (h.uiChecked) {
                const rate = this.getMonthlyRate(this.selectedPayConsultant.account.id, h.projectScopeDeliverableId);
                if (rate) {
                    if (foundRateIds.indexOf(rate.id) < 0) {
                        ret += rate.rate;
                        foundRateIds.push(rate.id);
                    }
                }
            }
        });
        return ret;
    }

    public getTotalHourly(): number {
        let ret = 0;
        this.selectedPayConsultant.hours.forEach(h => {
            if (h.uiChecked) {
                const rate = this.getHourlyRate(this.selectedPayConsultant.account.id, h.projectScopeDeliverableId);
                ret += h.billableHours * (rate ? rate.rate : 0);
            }
        });
        return ret;
    }

    public isFlat(h: ProjectTimeTracking): boolean {
        const flatRate = this.getFlatRate(h.accountId, h.projectScopeDeliverableId);
        return !!flatRate;
    }

    public getRateDescription(deliverableId: string): string {
        const rate = this.rates.find(r => {
            return r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0 &&
                r.consultantAccountId === this.selectedPayConsultant.account.id;
        });
        if (rate) {
            let ret = rate.rateType;
            if (rate.rateType === 'Hourly') {
                ret += ' @ $' + rate.rate.toFixed(2) + '/hr';
            } else if (rate.rateType === 'Flat') {
                ret += ' @ $' + rate.rate.toFixed(2);
            } else if (rate.rateType === 'Recurring') {
                ret += ': ' + rate.recurringDays + ' days @ $' + (rate.rate / rate.recurringDays).toFixed(2) + '/day';
            }
            return ret;
        } else {
            return 'Unknown rate';
        }
    }

    public getTotal() {
        return this.getTotalHourly() + this.getTotalFlat() + this.getTotalRecurring() + this.getTotalMonthly() + this.getTotalAdjustments();
    }

    public setCreateButtonEnabled() {
        this.createButtonEnabled = false;
        this.createDisabledReason = null;
        const totalLineItems = this.getTotal();
        const totalAdjustments = this.getTotalAdjustments();
        if (totalLineItems !== null && (totalAdjustments === null || totalAdjustments !== 0)) {
            if ((!this.selectedPayConsultant.notes || this.selectedPayConsultant.notes.length < 10)) {
                this.createDisabledReason = 'If you enter adjustments, you must specify the reason why in notes.';
            } else {
                this.createButtonEnabled = true;
            }
        } else if (totalLineItems !== null && totalAdjustments === 0 && totalLineItems > 0) {
            this.createButtonEnabled = true;
        }
    }

    private setTotal() {
        if (this.totalBox) {
            this.totalBox.nativeElement.value = this.getTotal().toFixed(2);
        }
        this.setCreateButtonEnabled();
    }

    public hoursChecked() {
        this.setTotal();
    }

    public getDeliverableDescription(deliverableId: string) {
        return this.deliverables.find(d => { return d.id === deliverableId; }).name;
    }

    public selectPayConsultant(consultant: any) {
        this.selectedPayConsultant = consultant;
        setTimeout(() => {
            this.setTotal();
        }, 100);
    }

    public selectViewConsultant(consultant: any) {
        this.selectedViewConsultant = consultant;
        console.log('selected', this.selectedViewConsultant);
    }

    public createTrackingInvoice() {
        this.setCreateButtonEnabled();
        if (!this.createDisabledReason) {
            const inv = new APTimeTrackingInvoice();
            inv.accountId = this.selectedPayConsultant.account.id;
            inv.internalNotes = this.selectedPayConsultant.internalNotes;
            inv.notes = this.selectedPayConsultant.notes;
            inv.paymentAmount = this.totalBox.nativeElement.value;
            const tts: { projectTimeTrackingId: string, adjustmentAmount: number }[] = [];
            this.selectedPayConsultant.hours.forEach(h => {
                if (h.uiChecked) {
                    tts.push({ projectTimeTrackingId: h.id, adjustmentAmount: h.invoiceAdjustmentAmount });
                }
            });
            this.projectService.upsertAPTimeTrackingInvoice(inv, tts, this.loggedInAccount.refreshToken).subscribe((newInv) => {
                console.log('AP invoice created', newInv);
                this.getData();
            });
        }
    }

    public markPaid(invoice: APTimeTrackingInvoice) {
        this.projectService.markAPTimeTrackingInvoicePaid(invoice.id, this.loggedInAccount.refreshToken)
            .subscribe((updated) => {
                invoice.paidOn = updated.paidOn;
            });
    }

    public openInvoiceOnWebsite(invoice: APTimeTrackingInvoice) {
        window.open(CONFIG.websiteBase + '/consultant-invoices/' + invoice.id, '_blank');
    }

    public toggleCheckItem(tracking: ProjectTimeTracking) {
        if (this.canCheck(tracking)) {
            tracking.uiChecked = !tracking.uiChecked;
            this.hoursChecked();
        }
    }
}
