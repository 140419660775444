import { Injectable } from '@angular/core';

import { SystemRole } from '../_models';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class SystemRolesService {
    constructor(private http: HttpClient) {

    }

    public getSystemRoles(refreshToken): Observable<SystemRole[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get users from api
        return this.http.get(CONFIG.apiEndpointBase + 'systemRoles', options)
            .pipe(
                take(1),
                map((response: SystemRole[]) => response)
            );
    }
}
