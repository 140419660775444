import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, Project, Organization, ProjectSearchResult } from '../_models';
import { AccountService, ProjectService, OrganizationService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';


@Component({
    selector: 'projectList',
    moduleId: module.id,
    templateUrl: 'project-list.component.html',
    styleUrls: ['project-list.component.scss'],
})
export class ProjectListComponent {
    @Output() public searchResultSelected = new EventEmitter<ProjectSearchResult>();

    public loggedInAccount: Account;
    public loading = false;
    public projects: ProjectSearchResult[] = [];
    public searchQuery: string = null;

    public sortProp = 'score';
    public sortDir = 'desc';
    public allOrgs: Organization[];

    constructor(
        private route: ActivatedRoute,
        private accountService: AccountService,
        private projectService: ProjectService,
        private organizationService: OrganizationService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.setOrganizations();
            }).subscribe();
    }


    private searchForProjects(query: string) {
        this.loading = true;
        this.projectService.searchForProjects(null, query, this.loggedInAccount.refreshToken)
            .subscribe((projects: ProjectSearchResult[]) => {
                this.projects = projects;
                this.loading = false;
                this.sort();
            });
    }

    private setOrganizations() {
        if (!this.allOrgs || this.allOrgs.length === 0) {
            this.organizationService.getAllOrganizations(this.loggedInAccount.refreshToken)
                .subscribe(orgs => {
                    this.allOrgs = orgs;
                    this.loading = false;
                });
        }
    }

    public getOrgName(orgId: string) {
        const foundOrg = this.allOrgs.find(o => o.id === orgId);
        if (foundOrg) { return foundOrg.name; }
        return '';
    }

    public toggleSort(prop: string) {
        if (this.sortProp === prop) {
            this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortProp = prop;
            this.sortDir = 'asc';
        }

        this.sort();
    }

    public performSearch() {
        // console.log('searching for', this.searchQuery);
        this.searchForProjects(this.searchQuery);
    }

    public sort() {
        this.projects.sort((a, b) => {
            if (!a[this.sortProp] && !b[this.sortProp]) {
                return 0;
            }

            if (!a[this.sortProp] && b[this.sortProp]) {
                return -1;
            }

            if (a[this.sortProp] && !b[this.sortProp]) {
                return 1;
            }
            if (this.sortProp === 'score') {
                if (a[this.sortProp] < b[this.sortProp]) {
                    return -1;
                }
                if (a[this.sortProp] > b[this.sortProp]) {
                    return 1;
                }
                return 0;
            } else {
                return a[this.sortProp].localeCompare(b[this.sortProp]);
            }
        });

        if (this.sortDir === 'desc') {
            this.projects.reverse();
        }
    }

    public loadProject(searchResult: ProjectSearchResult) {
        // [routerLink]="['/projectAdmin/edit/'+project.projectId]"
        if (this.searchResultSelected) {
            this.searchResultSelected.emit(searchResult);
        }
    }

}

