import { OrganizationAccount } from '.';
export class Organization {
    public id: string;
    public name: string;
    public created: string;
    public version: string;
    public timeZoneInfoName: string;
    public accounts: OrganizationAccount[];
    public poEnabled: boolean;
    public paymentTerms: OrganizationPaymentTermsFlags;
}

export enum OrganizationPaymentTermsFlags {
    /// Nothing set
    None = 0,
    /// Net 10
    Net10 = 1,
    /// Net 15
    Net15 = 2,
    /// Net 30
    Net30 = 4
  }
