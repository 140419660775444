import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { Account, AccountSearchResults, AdminAccount, ChatConversation, AgentConversationSearchResult } from '../_models';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
@Injectable()
export class ChatService {
    constructor(private http: HttpClient) { }

    public getUnansweredChatList(refreshToken: string): Observable<ChatConversation[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options: any = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'chats/agents', options)
            .pipe(
                take(1),
                map((response: any) => {
                    const ret: ChatConversation[] = [];
                    if (response && response.conversations) {
                        response.conversations.forEach(c => {
                            ret.push(new ChatConversation(c));
                        });
                    }
                    return ret;
                })
            );
    }

    public getAnsweredChatListForAgent(agentId: string,
        refreshToken: string): Observable<ChatConversation[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options: any = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'chats/agents/' + agentId + '/activeChats',
            options)
            .pipe(
                take(1),
                map((response: any) => {
                    const ret: ChatConversation[] = [];
                    if (response && response.conversations) {
                        response.conversations.forEach(c => {
                            ret.push(new ChatConversation(c));
                        });
                    }
                    return ret;
                })
            );
    }

    public pickupAgentConversation(agentConversationId: string,
        refreshToken: string): Observable<ChatConversation> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'chats/agents/' + agentConversationId,
            options)
            .pipe(
                take(1),
                map((response: ChatConversation) => {
                    return new ChatConversation(response);
                })
            );
    }

    public hangUpConversation(conversationId: string,
        refreshToken: string): Observable<any> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options: any = { headers: headers };
        // get account from api
        return this.http.delete(CONFIG.apiEndpointBase + 'chats/' + conversationId,
            options)
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }

    public searchAgentChatConversations(
        refreshToken: string): Observable<AgentConversationSearchResult[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const req = {};
        return this.http.post(CONFIG.apiEndpointBase + '/chats/admins/conversations/searches', req, options)
            .pipe(
                take(1),
                map((response: AgentConversationSearchResult[]) => response)
            );
    }

}
