export class StripeBankAccount {
    accountId: string;
    paymentAccountId: string;
    bankName: string;
    last4: string;
    accountHolderName: string;
    accountHolderType: string;
    country: string;
    currency: string;
    defaultForCurrency: boolean;
    routingNumber: string;
    created: string;
    isDefault: boolean;

    constructor(b: any) {
        this.accountId = b.accountId;
        this.paymentAccountId = b.paymentAccountId;
        this.bankName = b.bankName;
        this.last4 = b.last4;
        this.accountHolderName = b.accountHolderName;
        this.accountHolderType = b.accountHolderType;
        this.country = b.country;
        this.currency = b.currency;
        this.defaultForCurrency = b.defaultForCurrency;
        this.routingNumber = b.routingNumber;
        this.created = b.created;
        this.isDefault = b.isDefault;
    }
}
