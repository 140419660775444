import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CONFIG } from '../../environments/environment';

import { Account, AccountSearchResults, AdminAccount, AccountNote } from '../_models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

export class MLDemoService {
    constructor(
        private http: HttpClient) {
    }

    public getArticlePageQualityPrediction(body: any, account: Account): Observable<any> {
        const headers = new HttpHeaders(
            {
                Authorization: 'Bearer ' + account.refreshToken
            });
        const url = CONFIG.apiEndpointBase + 'demos/predictivePageQuality';
        // get prediction:
        return this.http.post(url, body, { headers: headers })
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }

    public getConsultantTagClouds(body: any, account: Account): Observable<any> {
        const headers = new HttpHeaders(
            {
                Authorization: 'Bearer ' + account.refreshToken
            });
        const url = CONFIG.apiEndpointBase + 'demos/tagClouds';
        // get prediction:
        return this.http.post(url, body, { headers: headers })
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }

}
