import { Component, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, AdminAccount, } from '../_models';
import { AccountService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: 'account-admin.component.html',
    styleUrls: ['account-admin.component.css']
})
export class AccountAdminComponent {
    public loggedInAccount: Account;
    public loading = false;
    public viewState: string;
    public loadedAccounts: { accountId: string, name: string }[] = [];


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private location: Location,
        private store: Store<Account>) {
        this.loading = true;
        this.viewState = 'search';
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
            }).subscribe();
        router.events.subscribe((val) => {
            this.handleRoute();
        });
    }


    private handleRoute() {
        this.route.params.pipe(
            take(1),
            map((params) => {
                const action = params['action'];
                if (action === 'search') {
                    this.viewState = 'search';
                } else if (action === 'manage') {
                    this.viewState = 'manage';
                }
            })
        );
        this.route.params.pipe(
            take(1),
            map((params) => {
                const accountId = params['accountId'];
                const component = this;
                if (accountId) {
                    this.viewState = 'manage';
                    this.accountSelected({ accountId, name: 'Loading...' });
                }
            })
        );
    }

    public handleTabClick(viewState: string) {
        if (viewState === 'search') {
            this.location.replaceState('accountAdmin/search');
        } else if (viewState === 'create') {
            this.location.replaceState('accountAdmin/create');
        } else {
            this.location.replaceState('/accountAdmin/' + viewState);
        }
        this.viewState = viewState;
    }

    public accountSelected(account: { accountId: string, name: string }) {
        const foundAccount = this.loadedAccounts.find(a => a.accountId === account.accountId);
        if (!foundAccount) {
            this.loadedAccounts.push(account);
        }
        this.viewState = account.accountId;
        this.location.replaceState('/accountAdmin/manage/' + account.accountId);
    }

    public accountLoaded(account: AdminAccount) {
        // grab the matching one in the collection:
        const tabAccount = this.loadedAccounts.find(a => a.accountId === account.id);
        if (tabAccount) {
            tabAccount.name = account.firstName + ' ' + account.lastName;
        }
    }

    public removeAccount(account: any) {
        const foundIndex = this.loadedAccounts.indexOf(account);
        if (foundIndex >= 0) {
            this.loadedAccounts.splice(foundIndex, 1);
            if (foundIndex > 0) {
                const toLoad = this.loadedAccounts[foundIndex - 1];
                this.accountSelected(toLoad);
            } else {
                this.handleTabClick('search');
            }
        }
    }
}
