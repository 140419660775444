import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import {
    Project, ProjectScope, ProjectPermission, ProjectScopeDeliverable,
    ProjectScopeRateInfo, ProjectFile, ProjectEstimationAbstract,
    ProjectInvoice, ProjectInvoiceLineItem,
    ProjectTimeTracking, APTimeTrackingInvoice, ProjectSearchResult, ProjectFileTemplate
} from '../_models';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ProjectService {
    constructor(private http: HttpClient) {

    }


    public upsertProject(project: Project, refreshToken: string): Observable<Project> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects', project, options)
            .pipe(
                take(1),
                map((response: Project) => response)
            );
    }

    public duplicateProject(projectId: string, refreshToken: string): Observable<Project> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/duplicates', options)
            .pipe(
                take(1),
                map((response: Project) => response)
            );
    }

    public upsertProjectScope(projectScope: ProjectScope, refreshToken: string): Observable<ProjectScope> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/scopes', projectScope, options)
            .pipe(
                take(1),
                map((response: ProjectScope) => response)
            );
    }

    public upsertProjectScopeDeliverable(projectScopeDeliverable: ProjectScopeDeliverable,
        refreshToken: string): Observable<ProjectScopeDeliverable> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/scopes/deliverables', projectScopeDeliverable, options)
            .pipe(
                take(1),
                map((response: ProjectScopeDeliverable) => response)
            );
    }

    public upsertProjectScopeRateInfo(projectScopeRateInfo: ProjectScopeRateInfo,
        refreshToken: string): Observable<ProjectScopeRateInfo> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // console.log('upsert rate info', projectScopeRateInfo);
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/scopes/rateInfos', projectScopeRateInfo, options)
            .pipe(
                take(1),
                map((response: ProjectScopeRateInfo) => response)
            );
    }

    public sendConsultantEmailsForRateInfo(projectScopeRateInfoId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // console.log('upsert rate info', projectScopeRateInfo);
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/scopes/rateInfos/' + projectScopeRateInfoId
            + '/consultantEmails', options)
            .pipe(
                take(1),
                map((response: Response) => {
                    return;
                })
            );
    }

    public getProjectById(projectId: string,
        refreshToken: string): Observable<Project> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId, options)
            .pipe(
                take(1),
                map((response: Project) => response)
            );
    }

    public sendProjectScopeEmail(projectId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        console.log('sending scope email', projectId);
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/emails/scoped', options)
            .pipe(
                take(1),
                map((response: any) => {
                    console.log('Sent scope email', response);
                    return;
                })
            );
    }

    public getProjectScopesByProjectId(projectId: string,
        refreshToken: string): Observable<ProjectScope[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/scopes', options)
            .pipe(
                take(1),
                map((response: ProjectScope[]) => response)
            );
    }

    public getProjectScopeDeliverablesByProjectScopeId(projectScopeId: string,
        refreshToken: string): Observable<ProjectScopeDeliverable[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/scopes/' + projectScopeId + '/deliverables', options)
            .pipe(
                take(1),
                map((response: ProjectScopeDeliverable[]) => response)
            );

    }

    public getProjectScopeRateInfosByProjectScopeId(projectScopeId: string,
        refreshToken: string): Observable<ProjectScopeRateInfo[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/scopes/' + projectScopeId + '/rateInfos', options)
            .pipe(
                take(1),
                map((response: ProjectScopeRateInfo[]) => response)
            );
    }

    public deleteProject(projectId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/projects/' + projectId, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

    public deleteProjectScope(projectScopeId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/projects/scopes/' + projectScopeId, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

    public deleteProjectScopeDeliverable(projectScopeDeliverableId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/projects/scopes/deliverables/' + projectScopeDeliverableId, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

    public deleteProjectScopeRateInfo(projectScopeRateInfoId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/projects/scopes/rateInfos/' + projectScopeRateInfoId, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

    public upsertProjectFile(projectFile: ProjectFile,
        fromTemplate: ProjectFileTemplate,
        contentBase64String: string, sendEmail: boolean,
        refreshToken: string): Observable<ProjectFile> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const req = {
            fileInfo: projectFile,
            fromTemplate,
            sendEmail,
            contentBase64String
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/files', req, options)
            .pipe(
                take(1),
                map((response: ProjectFile) => response)
            );
    }

    public getProjectFiles(projectId: string,
        refreshToken: string): Observable<ProjectFile[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/files', options)
            .pipe(
                take(1),
                map((response: ProjectFile[]) => response)
            );

    }

    public getProjectEstimationAbstracts(refreshToken: string): Observable<ProjectEstimationAbstract[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'sites/projectEstimationAbstracts', options)
            .pipe(
                take(1),
                map((response: ProjectEstimationAbstract[]) => {
                    response.sort(function (a, b) { return (a.projectType > b.projectType) ? 1 : ((b.projectType > a.projectType) ? -1 : 0); });
                    return response;
                })
            );
    }

    public downloadProjectFile(file: ProjectFile,
        refreshToken: string): Observable<HttpResponse<Blob>> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/files/' + file.id + '/' + file.name,
            { headers, observe: 'response', responseType: 'blob' })
            .pipe(
                take(1), map((response: HttpResponse<Blob>) => response)
            );
    }

    public deleteProjectFile(projectFileId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/projects/files/' + projectFileId, options)
            .pipe(
                take(1),
                map((response: Response) => {
                    return;
                })
            );
    }

    public searchForProjects(
        clientAccountId: string,
        textQuery: string,
        refreshToken: string): Observable<ProjectSearchResult[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const req = {
            clientAccountId,
            textQuery
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/searches', req, options)
            .pipe(
                take(1),
                map((response: ProjectSearchResult[]) => response)
            );
    }

    public setProjectPermissions(perm: ProjectPermission, refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const req = {
            accountId: perm.accountId,
            projectId: perm.projectId,
            roleName: perm.roleName,
            roleGroup: perm.roleGroup
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/permissions', req, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

    public getProjectInvoices(projectId: string, refreshToken: string): Observable<ProjectInvoice[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/invoices', options)
            .pipe(
                take(1),
                map((response: ProjectInvoice[]) => {
                    response.sort(function (a, b) {
                        return (a.invoiceNumber > b.invoiceNumber) ? 1 :
                            ((b.invoiceNumber > a.invoiceNumber) ? -1 : 0);
                    });
                    return response;
                })
            );
    }

    public getProjectInvoice(projectInvoiceId: string, refreshToken: string): Observable<ProjectInvoice> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/invoices/' + projectInvoiceId, options)
            .pipe(
                take(1),
                map((response: ProjectInvoice) => response)
            );
    }

    public getProjectInvoiceLineItems(projectInvoiceId: string, refreshToken: string): Observable<ProjectInvoiceLineItem[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/invoices/' + projectInvoiceId + '/lineItems', options)
            .pipe(
                take(1)
                , map((response: ProjectInvoiceLineItem[]) => {
                    response.sort(function (a, b) {
                        return (a.lineNumber > b.lineNumber) ? 1 :
                            ((b.lineNumber > a.lineNumber) ? -1 : 0);
                    });
                    return response;
                })
            );
    }

    public upsertProjectInvoice(invoice: ProjectInvoice, refreshToken: string): Observable<ProjectInvoice> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        // needs to be submitted without lineitems or transactions:
        const deepCopy = JSON.parse(JSON.stringify(invoice));
        deepCopy.lineItems = null;
        deepCopy.transactions = null;
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/invoices', deepCopy, options)
            .pipe(
                take(1),
                map((response: ProjectInvoice) => response)
            );
    }

    public upsertProjectInvoiceLineItem(lineItem: ProjectInvoiceLineItem, refreshToken: string): Observable<ProjectInvoiceLineItem> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/invoices/lineItems', lineItem, options)
            .pipe(
                take(1),
                map((response: ProjectInvoiceLineItem) => response)
            );
    }

    public deleteProjectInvoice(invoiceId: string,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/projects/invoices/' + invoiceId, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

    public deleteProjectInvoiceLineItem(lineItem: ProjectInvoiceLineItem,
        refreshToken: string): Observable<void> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/projects/invoices/' +
            lineItem.projectInvoiceId + '/lineItems/' + lineItem.id, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

    public getTimeTrackingForScope(projectScopeId: string, refreshToken: string): Observable<ProjectTimeTracking[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/scopes/' + projectScopeId + '/timeTracking', options)
            .pipe(
                take(1),
                map((response: ProjectTimeTracking[]) => {
                    response.sort(function (a, b) {
                        return (a.dateWorked > b.dateWorked) ? 1 :
                            ((b.dateWorked > a.dateWorked) ? -1 : 0);
                    });
                    return response;
                })
            );
    }

    public getTimeTrackingForProject(projectId: string, refreshToken: string): Observable<ProjectTimeTracking[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/timeTracking', options)
            .pipe(
                take(1),
                map((response: ProjectTimeTracking[]) => {
                    response.sort(function (a, b) {
                        return (a.dateWorked > b.dateWorked) ? 1 :
                            ((b.dateWorked > a.dateWorked) ? -1 : 0);
                    });
                    return response;
                })
            );
    }

    public upsertAPTimeTrackingInvoice(invoice: APTimeTrackingInvoice,
        timeTrackingItems: { projectTimeTrackingId: string, adjustmentAmount: number }[],
        refreshToken: string): Observable<APTimeTrackingInvoice> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const req = {
            apInvoice: invoice,
            timeTrackingItems
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/consultantAPs', req, options)
            .pipe(
                take(1),
                map((response: APTimeTrackingInvoice) => response)
            );
    }

    public getAPTimeTrackingInvoicesForProject(projectId: string, refreshToken: string): Observable<APTimeTrackingInvoice[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/' + projectId + '/consultantAPs', options)
            .pipe(
                take(1),
                map((response: APTimeTrackingInvoice[]) => response)
            );
    }

    public markAPTimeTrackingInvoicePaid(invoiceId: string, refreshToken: string): Observable<APTimeTrackingInvoice> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        const req = {};
        return this.http.post(CONFIG.apiEndpointBase + 'admins/projects/consultantAPs/' + invoiceId + '/payments', req, options)
            .pipe(
                take(1),
                map((response: APTimeTrackingInvoice) => response)
            );
    }

    public getProjectFileTemplates(refreshToken: string): Observable<ProjectFileTemplate[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/projects/files/templates', options)
            .pipe(
                take(1),
                map((response: ProjectFileTemplate[]) => response)
            );
    }
}
