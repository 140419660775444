﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models/account';
import { LOGIN, LOGOUT } from '../_reducers/account.reducer';
import { finalize, map } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    model: any = {};
    public viewModel = {
        loggedInAccount: <Account>null
    };

    loading = false;
    error = '';
    redirectUrl: string = null;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private store: Store<Account>) {

        this.store.select('loggedInAccount').select((account: Account) => {
            this.viewModel.loggedInAccount = account;
        }).subscribe();
    }

    ngOnInit() {
        // reset login status
        this.authenticationService.logout();
        this.store.dispatch({ type: LOGOUT, payload: null });

        this.activatedRoute.queryParams.subscribe((params: Params) => {
            const code = params['err'];
            this.redirectUrl = params['redirectUrl'];
            if (code === '401' || code === '403') {
                this.error = 'Please log in again. Your account password may have been changed in another browser session.';
            }
        });
    }

    login(): void {
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
                map((result: any) => {
                    if (result) {
                        console.log('RESULT OF LOGIN', result);
                        this.store.dispatch({ type: LOGIN, payload: result });
                        if (this.redirectUrl) {
                            window.location.href = this.redirectUrl;
                        } else {
                            this.router.navigate(['/']);
                        }
                    } else {
                        this.error = 'Username or password is incorrect';
                    }
                },
                    (error: any) => {
                        this.error = 'ERROR!' + error;
                    })
            ).subscribe();
    }
}
