import { ActionReducer, Action } from '@ngrx/store';
import { Account } from '../_models/account';
import { ReducerStorage } from './reducer.storage';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
class PersistAccountAction implements Action {
    constructor(public payload: Account, public type: string) { }
}

export function accountReducer(
    state: Account = ReducerStorage.hydrate<Account>('loggedInAccount') ?
        new Account(ReducerStorage.hydrate<Account>('loggedInAccount')) : null,
    action: PersistAccountAction) {
    switch (action.type) {
        case LOGIN:
            return ReducerStorage.persist<Account>('loggedInAccount', new Account(action.payload));
        case LOGOUT:
            ReducerStorage.clear('loggedInAccount');
            return null;
        default:
            return state;
    }
};
