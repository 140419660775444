﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ReportsService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }

    public getReport(reportName: string, refreshToken: string): Observable<any[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/reports/' + reportName, options)
            .pipe(
                take(1),
                map((response: any[]) => response)
            );
    }

}
