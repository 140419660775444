export class StripeCard {
    accountId: string;
    paymentAccountId: string;
    brand: string;
    expMonth: string;
    expYear: number;
    last4: string;
    accountHolderName: string;
    addressLine1: string;
    addressLine2: string;
    addressState: string;
    addressZip: string;
    country: string;
    created: string;
    isDefault: boolean;


    constructor(c: any) {
        this.accountId = c.accountId;
        this.paymentAccountId = c.paymentAccountId;
        this.brand = c.brand;
        this.expMonth = c.expMonth;
        this.expYear = c.expYear;
        this.last4 = c.last4;
        this.accountHolderName = c.accountHolderName;
        this.addressLine1 = c.addressLine1;
        this.addressLine2 = c.addressLine2;
        this.addressState = c.addressState;
        this.addressZip = c.addressZip;
        this.country = c.country;
        this.created = c.created;
        this.isDefault = c.isDefault;
    }
}

