export enum ConsultantProfileFlags {
    /// No Flags
    None = 0,
    /// Rejected consultant profile
    Rejected = 1
  }

export class ConsultantProfile {
    public id: string;
    public accountId: string;
    public isLegalAgreed: boolean;
    public isLegalAgreedAt: string;
    public created: string;
    public version: string;
    public internalStatus: string;
    public externalStatus: string;
    public isAvailable: boolean;
    public ratings: string;
    public flags: ConsultantProfileFlags;
    public publicProfileId: string;
    public isPublic: boolean;
    public publicHourlyRate: number;
    public publicProfileIdChangedOn: string;
    public shortBio: string;
}
