import {
    Component, ElementRef, AfterContentInit,
    ViewChild, AfterViewChecked, OnDestroy, HostListener, Input
} from '@angular/core';
import { Account, ChatConversation, AgentConversationSearchResult, AccountNote } from '../_models';
import { AccountService, ChatService } from '../_services';
import { Store } from '@ngrx/store';
import { PubNubAngular } from 'pubnub-angular2';
import { JwtHelper } from '../_helpers';
import { PendingChangesGuard, ComponentCanDeactivate } from '../_guards';
import { CONFIG } from '../../environments/environment';

@Component({
    moduleId: module.id,
    selector: 'chat-history',
    templateUrl: 'chatHistory.component.html',
    styleUrls: ['chatHistory.component.scss'],
    providers: [PubNubAngular]
})
export class ChatHistoryComponent implements OnDestroy, ComponentCanDeactivate {
    @Input() public pubnub: PubNubAngular;
    public loggedInAccount: Account;

    public chatSearchResults: AgentConversationSearchResult[];
    public selectedResult: AgentConversationSearchResult;
    public historyMessages: any[];
    public historyDonePulling = true;

    public notes: AccountNote[] = null;
    public selectedNote: AccountNote = null;
    public selectedNoteDirty = false;
    private agentAccountId: string = null;

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            this.updateNote(false, null);
            $event.returnValue = `You have an unsaved note`;
        }
    }

    constructor(private chatService: ChatService,
        private accountService: AccountService,
        private store: Store<Account>) {

        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.agentAccountId = JwtHelper.decodeToken(this.loggedInAccount.refreshToken).acct;
            }).subscribe();
    }

    canDeactivate(): boolean {
        return !this.selectedNoteDirty;
    }

    public ngOnDestroy() {

    }


    public getDateFromTimestamp(timestamp: number): string {
        return new Date(timestamp / 10000).toISOString();
    }

    public searchChatHistory() {
        this.chatService.searchAgentChatConversations(this.loggedInAccount.refreshToken).subscribe(
            (results: AgentConversationSearchResult[]) => {
                this.chatSearchResults = results;
            });
    }

    public getHistoryUserName(accountId: string): string {
        if (accountId === this.selectedResult.clientAccountId) {
            return this.selectedResult.clientName;
        } else if (accountId === this.selectedResult.respondedByAgentAccountId) {
            return this.selectedResult.agentName;
        }

        return '???';
    }

    public isClientHistoryMessage(accountId: string): boolean {
        return (accountId === this.selectedResult.clientAccountId);
    }

    public pullHistory(comp: ChatHistoryComponent, chatChannelId: string, end: number) {
        this.pubnub.getInstance(chatChannelId).history({
            channel: chatChannelId,
            reverse: true,
            count: 100,
            stringifiedTimeToken: false,
            start: end,
            end: null,
        }, (status, response) => {
            // console.log('response.messages', response.messages);
            response.messages.forEach(msg => {
                msg.entry.text = msg.entry.text.replace(new RegExp('\n', 'g'), '<br>');
                comp.historyMessages.push(msg);
            });
            if (response.messages.length > 0) {
                const index = response.messages.length - 1;
                if (index > 0) {
                    comp.pullHistory(comp, chatChannelId, response.messages[index].timetoken);
                } else {
                    // only message, we can stop
                    comp.historyDonePulling = true;
                    // console.log('historyMessages', comp.historyMessages);
                }
            } else {
                comp.historyDonePulling = true;
                // console.log('historyMessages', comp.historyMessages);
            }

            // get the notes:
            console.log('getting notes');
            this.accountService.getAccountNotes(this.selectedResult.clientAccountId, this.loggedInAccount.refreshToken)
                .subscribe(notes => {
                    this.notes = notes;
                });

        });
    }

    public pullHistoryForChannel(result: AgentConversationSearchResult) {
        this.historyDonePulling = false;
        this.selectedResult = result;
        this.historyMessages = [];
        if (this.selectedNote && this.selectedNoteDirty) {
            // save off the prior note...
            console.log('saving off notes first before changing channel');
            this.updateNote(true, result);
        } else {
            this.setSelectedNote(null);
            this.pubnub.getInstance(result.chatChannelId)
                .init({
                    subscribeKey: CONFIG.pubNub.subscribeKey,
                    publishKey: CONFIG.pubNub.publishKey,
                    ssl: true,
                    uuid: this.agentAccountId,
                    authKey: result.chatChannelAccessTokenId,
                    origin: 'pubsub.pubnub.com'
                });

            this.pullHistory(this, result.chatChannelId, null);
        }
    }

    public updateNote(clearSelectedAfterUpdate: boolean, afterClearChannelResult: AgentConversationSearchResult) {
        const idx = this.notes.indexOf(this.selectedNote);
        this.accountService.upsertAccountNote(this.selectedNote, this.loggedInAccount.refreshToken)
            .subscribe(note => {
                if (clearSelectedAfterUpdate) {
                    this.setSelectedNote(null);
                    if (afterClearChannelResult) {
                        this.pullHistoryForChannel(afterClearChannelResult);
                    }
                } else {
                    this.notes.splice(idx, 1);
                    this.notes.unshift(note);
                    this.setSelectedNote(note);
                }
                this.selectedNoteDirty = false;
            });
    }


    public setSelectedNote(note) {
        this.selectedNote = note;
    }

    public selectedNoteChanged() {
        this.selectedNoteDirty = true;
    }

    public createNote() {
        const newNote = new AccountNote();
        newNote.accountId = this.selectedResult.clientAccountId;
        newNote.note = '';
        this.accountService.upsertAccountNote(newNote, this.loggedInAccount.refreshToken)
            .subscribe(createdNote => {
                this.notes.unshift(createdNote);
                this.setSelectedNote(createdNote);
            });
    }

}
