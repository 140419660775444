import { Component, OnDestroy, DoCheck } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, SiteService, ProjectService } from '../_services';
import { Store } from '@ngrx/store';
import {
    Account, ProjectEstimationAbstract, ProjectEstimationSiteSection,
    ProjectEstimationSiteSectionGroup, ProjectEstimationSiteSectionGroupAssignment
} from '../_models';
import { HttpClient } from '@angular/common/http';
import { DragulaService } from 'ng2-dragula';

@Component({
    selector: 'project-types-layout',
    moduleId: module.id,
    templateUrl: 'project-types-layout.component.html',
    styleUrls: ['project-types-layout.component.css']
})
export class ProjectTypesLayoutComponent implements OnDestroy, DoCheck {

    public loading: boolean = false;
    public loggedInAccount: Account = null;
    public allSiteSections: ProjectEstimationSiteSection[] = [];
    public allAbstracts: ProjectEstimationAbstract[] = [];
    public dragulaOptions: any = {
        direction: 'horizontal'
    };

    public addingSection = false;
    public addNewSectionName: string = null;

    public sectionUnderEdit: ProjectEstimationSiteSection = null;

    public groupUnderEdit: ProjectEstimationSiteSectionGroup = null;
    groupAssignmentsUnderEdit: ProjectEstimationSiteSectionGroup = null;

    private sectionsChanged = false;
    private groupsChanged = null;
    private assignmentsChanged = false;

    public selectedAbstract: ProjectEstimationAbstract;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private siteService: SiteService,
        private projectService: ProjectService,
        private dragulaService: DragulaService,
        private store: Store<Account>) {
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loadSections();
                this.loadAbstracts();
            }).subscribe();
        this.setDragulaOptionsForSections();
        dragulaService.drop.subscribe((value) => {
            this.handleDrop(value);
        });
    }

    ngOnDestroy() {
        if (this.dragulaService.find('sectionBag')) {
            // console.log('destroying sectionBag');
            this.dragulaService.destroy('sectionBag');
        }
        this.allSiteSections.forEach(section => {
            this.dragulaService.destroy('groupsForSection-' + section.id);
        });
    }

    ngDoCheck() {
        if (this.sectionsChanged) {
            this.updateSections();
            this.sectionsChanged = false;
        }
        if (this.groupsChanged) {
            this.updateGroups(this.groupsChanged);
            this.groupsChanged = null;
        }
        if (this.assignmentsChanged) {
            this.updateAssignments();
            this.assignmentsChanged = false;
        }
    }

    private loadAbstracts() {
        this.siteService.getProjectEstimationAbstracts(this.loggedInAccount.refreshToken).subscribe((abstracts) => {
            this.allAbstracts = abstracts;
        })
    }

    private handleDrop(value: any) {
        // console.log('value:', value);
        console.log(`drop:`, value[1].id);
        const id: string = value[1].id;
        if (id.startsWith('section-')) {
            // need to save the sections:
            this.sectionsChanged = true;
        } else if (id.startsWith('group-')) {
            this.groupsChanged = id.substring(6);
        } else if (id.startsWith('assignment-')) {
            this.assignmentsChanged = true;
        }

    }

    private updateSections() {
        // console.log('saving sections', JSON.stringify(this.allSiteSections));
        for (let index = 0; index < this.allSiteSections.length; index++) {
            const section = this.allSiteSections[index];
            section.displayOrder = index;
            // console.log('saving section', section, index);
            this.siteService.upsertProjectEstimationSiteSection(section, this.loggedInAccount.refreshToken).subscribe((updated) => {
                // console.log('saved section', updated);
                let prevVersion = this.allSiteSections.find((s) => {
                    return s.id === updated.id;
                });
                updated.groups = prevVersion.groups;
                prevVersion = updated;
            });
        }
    }

    private updateGroups(sectionId: string) {
        // console.log('saving groups. section id:', sectionId);
        // get the section:
        const section = this.allSiteSections.find((s) => {
            return s.id === sectionId;
        });
        if (section) {
            // console.log('section groups:', section.groups);
            for (let index = 0; index < section.groups.length; index++) {
                const group = section.groups[index];
                group.displayOrder = index;
                // console.log('upserting group', group);
                this.siteService.upsertProjectEstimationSiteSectionGroup(group,
                    this.loggedInAccount.refreshToken).subscribe((updated) => {
                        // console.log('updated group:', updated);
                        section.groups[index] = updated;
                    });
            }
        }
    }

    private updateAssignments() {
        // get the section:
        if (this.groupAssignmentsUnderEdit) {
            // console.log('group assignments:', this.groupAssignmentsUnderEdit.assignments);
            for (let index = 0; index < this.groupAssignmentsUnderEdit.assignments.length; index++) {
                const assignment = this.groupAssignmentsUnderEdit.assignments[index];
                assignment.displayOrder = index;
                // console.log('upserting assignment', assignment);
                this.siteService.upsertProjectEstimationSiteSectionGroupAssignment(assignment,
                    this.loggedInAccount.refreshToken).subscribe((updated) => {
                        // console.log('updated assignment:', assignment);
                        this.groupAssignmentsUnderEdit.assignments[index] = updated;
                    });
            }
        }
    }

    private loadSections() {
        this.siteService.getProjectEstimationSiteSections(this.loggedInAccount.refreshToken).subscribe((allSections) => {
            this.allSiteSections = allSections;
            this.allSiteSections.forEach(section => {
                this.setDragulaOptionsForSectionGroups(section);
            });
        });
    }

    private setDragulaOptionsForSections() {
        this.dragulaService.setOptions('sectionBag', {
            removeOnSpill: false,
            moves: (el, container, target) => {
                if (target.classList) {
                    return target.classList.contains('parent-draggable');
                }
                return false;
            }
        });
    }

    private setDragulaOptionsForSectionGroups(section: ProjectEstimationSiteSection) {
        this.dragulaService.setOptions('groupsForSection-' + section.id, {
            removeOnSpill: false,
            moves: (el, container, target) => {
                if (target.classList) {
                    return !target.classList.contains('section-group-add');
                }
                return true;
            }
        });
    }

    private setDragulaOptionsForSectionGroupsAssignments(group: ProjectEstimationSiteSectionGroup) {
        this.dragulaService.setOptions('assignmentsForGroup-' + group.id, {
            removeOnSpill: false,
            moves: (el, container, target) => {
                if (target.classList) {
                    return !target.classList.contains('section-group-add');
                }
                return true;
            }
        });
    }

    public createSection() {
        if (this.addNewSectionName) {
            const newSection: ProjectEstimationSiteSection = new ProjectEstimationSiteSection();
            newSection.displayOrder = 9999;
            newSection.name = this.addNewSectionName;
            newSection.visible = false;
            this.siteService.upsertProjectEstimationSiteSection(newSection,
                this.loggedInAccount.refreshToken).subscribe((created) => {
                    this.allSiteSections.push(created);
                    this.addNewSectionName = null;
                    this.addingSection = false;
                    this.setDragulaOptionsForSectionGroups(created);
                });
        }
    }

    public addNewGroup(section: ProjectEstimationSiteSection) {
        const newGroup: ProjectEstimationSiteSectionGroup = new ProjectEstimationSiteSectionGroup();
        newGroup.displayOrder = 9999;
        newGroup.name = 'New Group';
        newGroup.description = 'Group Description';
        newGroup.projectEstimationSiteSectionId = section.id;
        newGroup.visible = false;
        this.siteService.upsertProjectEstimationSiteSectionGroup(newGroup,
            this.loggedInAccount.refreshToken).subscribe((created) => {
                section.groups.push(created);
            });
    }

    public updateSectionGroup() {
        if (this.groupUnderEdit) {
            this.siteService.upsertProjectEstimationSiteSectionGroup(this.groupUnderEdit,
                this.loggedInAccount.refreshToken).subscribe((updated) => {
                    // replace the object with the updated one:
                    const section = this.allSiteSections.find((s) => {
                        return s.id === this.groupUnderEdit.projectEstimationSiteSectionId;
                    });
                    const index = section.groups.indexOf(this.groupUnderEdit);
                    updated.assignments = section.groups[index].assignments;
                    section.groups[index] = updated;
                    // clear the group under edit:
                    this.groupUnderEdit = null;
                });
        }
    }

    public viewGroupAssignments(group: ProjectEstimationSiteSectionGroup) {
        // console.log('viewing group assingments for group', group);
        this.groupAssignmentsUnderEdit = group;
    }

    public startAddNewAssignment() {
        this.selectedAbstract = this.allAbstracts[0];
    }

    public saveNewAssignment() {
        if (this.groupAssignmentsUnderEdit && this.selectedAbstract) {
            const assignment: ProjectEstimationSiteSectionGroupAssignment = new ProjectEstimationSiteSectionGroupAssignment();
            assignment.displayOrder = 9999;
            assignment.projectEstimationId = this.selectedAbstract.id;
            assignment.projectEstimationSiteSectionGroupId = this.groupAssignmentsUnderEdit.id;
            this.siteService.upsertProjectEstimationSiteSectionGroupAssignment(assignment, this.loggedInAccount.refreshToken)
                .subscribe((newAssignment) => {
                    if (!this.groupAssignmentsUnderEdit.assignments) {
                        this.groupAssignmentsUnderEdit.assignments = [];
                    }
                    this.groupAssignmentsUnderEdit.assignments.push(newAssignment);
                    this.selectedAbstract = null;
                });
        }
    }

    public getProjectTypeById(id: string): string {
        const found = this.allAbstracts.find((a) => { return a.id === id; });
        return found.projectType;
    }

    public updateSection() {
        if (this.sectionUnderEdit) {
            this.siteService.upsertProjectEstimationSiteSection(this.sectionUnderEdit, this.loggedInAccount.refreshToken)
                .subscribe(() => {
                    this.sectionUnderEdit = null;
                });
        }
    }

    public deleteSection() {
        console.log('deleting section', this.sectionUnderEdit);
        if (this.sectionUnderEdit) {
            this.siteService.deleteProjectEstimationSiteSection(this.sectionUnderEdit.id, this.loggedInAccount.refreshToken)
                .subscribe(() => {
                    this.allSiteSections.splice(this.allSiteSections.indexOf(this.sectionUnderEdit), 1);
                    this.sectionUnderEdit = null;
                });
        }
    }

    public deleteSectionGroup() {
        if (this.groupUnderEdit) {
            this.siteService.deleteProjectEstimationSiteSectionGroup(this.groupUnderEdit.id, this.loggedInAccount.refreshToken)
                .subscribe(() => {
                    // remove the object:
                    const section = this.allSiteSections.find((s) => {
                        return s.id === this.groupUnderEdit.projectEstimationSiteSectionId;
                    });
                    const index = section.groups.indexOf(this.groupUnderEdit);
                    section.groups.splice(index, 1);
                    this.groupUnderEdit = null;
                });
        }
    }

    public deleteSectionGroupAssignment(assignmentId: string) {
        if (this.groupAssignmentsUnderEdit && assignmentId) {
            this.siteService.deleteProjectEstimationSiteSectionGroupAssignment(assignmentId, this.loggedInAccount.refreshToken)
                .subscribe(() => {
                    const assignment = this.groupAssignmentsUnderEdit.assignments.find((a) => {
                        return a.id === assignmentId;
                    });
                    const index = this.groupAssignmentsUnderEdit.assignments.indexOf(assignment);
                    this.groupAssignmentsUnderEdit.assignments.splice(index, 1);
                });

        }
    }
}

