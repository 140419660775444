export class APTimeTrackingInvoice {
        id: string;
        invoiceNumber: number;
        created: string;
        createdBy: string;
        accountId: string;
        internalNotes: string;
        notes: string;
        paymentAmount: number;
        paidOn: string;
}
