import { Injectable } from '@angular/core';


import { AuthenticationService } from './authentication.service';
import {
    ProjectEstimationAbstract, ProjectEstimation, ProjectEstimationSiteSection,
    ProjectEstimationSiteSectionGroup, ProjectEstimationSiteSectionGroupAssignment
} from '../_models';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class SiteService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }


    public getProjectEstimationById(projectEstimationId: string, refreshToken: string): Observable<ProjectEstimation> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/' + projectEstimationId, options)
            .pipe(
                take(1),
                map((response: ProjectEstimation) => response)
            );
    }

    public getProjectEstimationAbstracts(refreshToken: string): Observable<ProjectEstimationAbstract[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/sites/projectEstimationAbstracts', options)
            .pipe(
                take(1),
                map((response: ProjectEstimationAbstract[]) => {
                    response.sort(function (a, b) { return (a.projectType > b.projectType) ? 1 : ((b.projectType > a.projectType) ? -1 : 0); });
                    return response;
                })
            );
    }

    public upsertProjectEstimation(projectEstimation: ProjectEstimation,
        refreshToken: string): Observable<ProjectEstimation> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        console.log('upserting', projectEstimation);
        return this.http.post(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations', projectEstimation, options)
            .pipe(
                take(1),
                map((response: ProjectEstimation) => {
                    console.log('response: ', response);
                    return response;
                })
            );
    }

    public deleteProjectEstimationById(projectEstimationId: string, refreshToken: string): Observable<void> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/' + projectEstimationId, options)
            .pipe(
                take(1),
                map((response: any) => { return; })
            );
    }

    public getProjectEstimationSiteSections(refreshToken: string): Observable<ProjectEstimationSiteSection[]> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/siteSections', options)
            .pipe(
                take(1),
                map((response: ProjectEstimationSiteSection[]) => {
                    response.sort(function (a, b) { return (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0); });
                    response.forEach(section => {
                        if (!section.groups) {
                            section.groups = [];
                        }
                        section.groups.sort(function (a, b) {
                            return (a.displayOrder > b.displayOrder) ? 1 :
                                ((b.displayOrder > a.displayOrder) ? -1 : 0);
                        });
                        section.groups.forEach(group => {
                            if (!group.assignments) {
                                group.assignments = [];
                            }
                            group.assignments.sort(function (a, b) {
                                return (a.displayOrder > b.displayOrder) ? 1 :
                                    ((b.displayOrder > a.displayOrder) ? -1 : 0);
                            });
                        });
                    });
                    // console.log('sections:', ret);
                    return response;
                })
            );
    }

    public upsertProjectEstimationSiteSection(section: ProjectEstimationSiteSection,
        refreshToken: string): Observable<ProjectEstimationSiteSection> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/siteSections', section, options)
            .pipe(
                take(1),
                map((response: ProjectEstimationSiteSection) => {
                    if (!response.groups) {
                        response.groups = [];
                    }
                    response.groups.sort(function (a, b) {
                        return (a.displayOrder > b.displayOrder) ? 1 :
                            ((b.displayOrder > a.displayOrder) ? -1 : 0);
                    });
                    response.groups.forEach(group => {
                        if (!group.assignments) {
                            group.assignments = [];
                        }
                        group.assignments.sort(function (a, b) {
                            return (a.displayOrder > b.displayOrder) ? 1 :
                                ((b.displayOrder > a.displayOrder) ? -1 : 0);
                        });
                    });
                    return response;
                })
            );
    }

    public upsertProjectEstimationSiteSectionGroup(group: ProjectEstimationSiteSectionGroup,
        refreshToken: string): Observable<ProjectEstimationSiteSectionGroup> {
        // add authorization header with jwt token
        // console.log('group', group)
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/siteSections/groups', group, options)
            .pipe(
                take(1),
                map((response: ProjectEstimationSiteSectionGroup) => response)
            );
    }

    public upsertProjectEstimationSiteSectionGroupAssignment(assignment: ProjectEstimationSiteSectionGroupAssignment,
        refreshToken: string): Observable<ProjectEstimationSiteSectionGroupAssignment> {
        // add authorization header with jwt token
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/siteSections/groups/assignments', assignment, options)
            .pipe(
                take(1),
                map((response: ProjectEstimationSiteSectionGroupAssignment) => response)
            );
    }

    public deleteProjectEstimationSiteSection(id: string, refreshToken: string): Observable<void> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/siteSections/' + id, options)
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }

    public deleteProjectEstimationSiteSectionGroup(id: string, refreshToken: string): Observable<void> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/siteSections/groups/' + id, options)
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }

    public deleteProjectEstimationSiteSectionGroupAssignment(id: string, refreshToken: string): Observable<void> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.delete(CONFIG.apiEndpointBase + 'admins/sites/projectEstimations/siteSections/groups/assignments/' + id, options)
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }
}
