﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { Account } from '../_models/account';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient) { }

    public login(username: string, password: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
        const body = JSON.stringify({
            username: username,
            password: password
        });
        const url: string = CONFIG.apiEndpointBase + 'auths/logins';
        // let url: string = '/api/authenticate';
        return this.http.post(
            url,
            body,
            { headers })
            .pipe(
                take(1),
                map((response: any) => response),
                catchError((err) => Observable.throw(err.text))
            );
    }

    public logout(): void {
        // do nothing
    }
}
