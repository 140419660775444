export class ExternalPaymentAccount {
    public paymentAccountId: string;
    public accountType: string;
    public accountName: string;

    constructor(from: any) {
        this.paymentAccountId = from.paymentAccountId;
        this.accountType = from.accountType;
        this.accountName = from.accountName;
    }
}
