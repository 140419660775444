import { Component, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
export interface CustomInputModel {
  title: string;
  message: string;
  inputFieldLabel: string;
  bodyHtml: string;
  okButtonText: string;
  showCancelButton: boolean;
  showOkButton: boolean;
  cancelButtonText: string;
  inputValue: string;
}
@Component({
  selector: 'modal',
  template: `<div style="width:100vw; height:100vh; background-color: rgba(0,0,0,.7); 
                        font-family: 'Open Sans', 'sans-serif'; font-weight: 300;">
              <div class="modal-content" style="position:absolute; left: 50%; 
                          top:50%; transform: translate(-50%, -50%); 
                          min-width: 50%; min-height: 200px;">
                   <div class="modal-header">
                     <button type="button" class="close" (click)="closeModal()" >&times;</button>
                     <h4 class="modal-title">{{title || 'Confirm'}}</h4>
                   </div>
                   <div class="modal-body">
                     <div #bodyContainer></div>
                     <label for="inputField">{{inputFieldLabel}}</label>
                     <input type="text" class="form-control" #inputField [(ngModel)]="inputValue">
                   </div>
                   <div class="modal-footer">
                     <button type="button" class="btn btn-primary" 
                        [ngStyle]="{ 'display': showOkButton ? 'inline' : 'none' }" (click)="confirm()" 
                          [disabled]="(!inputValue || inputValue.trim().length === 0)" >
                        {{okButtonText}}
                     </button>
                     <button type="button" class="btn btn-default"
                        [ngStyle]="{ 'display': showCancelButton ? 'inline' : 'none' }" (click)="closeModal()">
                        {{cancelButtonText}}
                     </button>
                   </div>
                 </div>
              </div>`
})
export class InputModalComponent extends DialogComponent<CustomInputModel, string> implements AfterContentInit {
  @ViewChild('bodyContainer') bodyContainer: ElementRef;
  @ViewChild('inputField') inputField: ElementRef;

  public bodyHtml: string;
  public inputFieldLabel: string;
  public inputValue: string;
  public title: string;
  public showOkButton = true;
  public showCancelButton = true;
  public okButtonText = 'OK';
  public cancelButtonText = 'Cancel';
  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  loadData(bodyHtml) {
    this.bodyContainer.nativeElement.innerHTML = bodyHtml;
  }

  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.result = this.inputField.nativeElement.value;
    this.close();
  }

  closeModal() {
    this.close();
  }

  ngAfterContentInit() {
    this.loadData(this.bodyHtml);
  }
}
