import { HostListener, Component, ElementRef, Input, Output, EventEmitter, ViewChild, Renderer } from '@angular/core';

@Component({
    selector: 'singleSelect',
    moduleId: module.id,
    template: `
<div class="form-group">
    <label for="inputId">{{label}}</label>
    <div class="input-group">
        <input type="text" [disabled]="_disabled" #inputId class="form-control" 
        [(ngModel)]="query" (keyup)="filter($event)" (blur)="blurThis()" (focus)="focusThis()">
        <div class="input-group-addon" (click)="filter(undefined)"><i class="fa fa-search"></i></div>
    </div>
    <div class="suggestions" *ngIf="filteredItems.length > 0">
        <ul>
            <li *ngFor="let item of filteredItems">
                <a (click)="select(item)" [ngClass]="{ selectedItem: isSelected(item) }">{{displayObject(item)}}</a>
            </li>
        </ul>
    </div>
</div>`,
    styleUrls: ['single-select.component.css'],
})
export class SingleSelectComponent {

    public query = '';

    @Input() public selectedItem;
    public elementRef;
    _disabled = false;
    @Input() label: string;
    @Input() filteredItems = [];
    @Input() displayValue: (value: any) => string;
    @Output() runFilter = new EventEmitter();
    @Output() selectedItemChanged = new EventEmitter();
    @Output() handleItemAddition = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @ViewChild('inputId') inputBox: ElementRef;

    selectedIndex = -1;
    hasFocus = false;
    constructor(private myElement: ElementRef, public renderer: Renderer) {
        this.elementRef = myElement;
    }

    @Input() set disabled(value: boolean) {
        this._disabled = value;
        if (value) {
            this.query = '';
            this.select(null);
        }
    }

    blurThis() {
        if (this.hasFocus) {
            this.hasFocus = false;
            this.onBlur.emit();
        }
    }
    focusThis() {
        this.hasFocus = true;
    }

    displayObject(obj: any): string {
        return this.displayValue(obj);
    }

    isSelected(item: any) {
        return this.filteredItems.indexOf(item) === this.selectedIndex;
    }

    filter(event: any) {
        if (this._disabled) {
            return;
        }
        // if (event && event.keyCode) {
        //     console.log('Event keycode:', event.keyCode);
        //     // ESC = 27
        //     // Left Arrow:37
        //     // Up Arrow: 38
        //     // Right Arrow: 39
        //     // Down Arrow: 40
        // }
        this.select(null);
        if (event && event.keyCode) { // handle keypresses:
            if (this.handleItemAddition && event.keyCode === 13 && this.query.trim() !== ''
                && this.selectedIndex < 0) { // enter key pressed, nothing selected by arrows
                this.handleItemAddition.emit(this);
                return;
            } else if (event.keyCode === 13 && this.selectedIndex >= 0) {
                this.select(this.filteredItems[this.selectedIndex]); // arrow select, enter pressed.
                this.filteredItems = [];
                this.selectedIndex = -1;
                return;
            } else if (event.keyCode === 27) { // escape key pressed
                this.filteredItems = [];
                this.selectedIndex = -1;
                return;
            } else if (event.keyCode >= 37 && event.keyCode <= 40) {
                // handle arrows:
                if (event.keyCode === 40) {
                    // move down the list:
                    this.selectedIndex++;
                } else if (event.keyCode === 38) {
                    // move up the list:
                    this.selectedIndex--;
                }
                if (this.selectedIndex < 0) {
                    this.selectedIndex = -1; // can't get below -1
                } else if (this.selectedIndex > this.filteredItems.length - 1) {
                    this.selectedIndex = -1; // can't go above how many are in the filtered items list.
                } else if (this.filteredItems.length === 0) {
                    this.selectedIndex = -1; // if there is nothing, there should be no selected index.
                }
            } else {
                this.selectedIndex = -1;
            }
        }
        this.runFilter.emit({ query: this.query, selectedItem: this.selectedItem });
    }

    select(item) {
        if (this.selectedItem !== item) {
            this.selectedItem = item;
        }
        if (item) {
            this.query = this.displayObject(this.selectedItem);
            this.filteredItems = [];
            this.renderer.invokeElementMethod(this.inputBox.nativeElement, 'focus');
        }
        // console.log('emitting: ', this.selectedItem);
        this.selectedItemChanged.emit({ selectedItem: this.selectedItem });
    }

    @HostListener('document:click', ['$event.target'])
    handleClick(event) {
        let clickedComponent = event;
        let inside = false;
        do {
            if (clickedComponent === this.elementRef.nativeElement) {
                inside = true;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);
        if (!inside) {
            this.filteredItems = [];
        }
    }
}
