/* tslint:disable:component-class-suffix */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReportsCommon } from './reports-common';

@Component({
    moduleId: module.id,
    selector: 'report-table',
    templateUrl: 'report-table.component.html',
    styleUrls: ['reports-common.scss']
  })
  export class ReportTableComponent {
    @Input() public tableHeight = 500;
    @Input() public tableName: string = null;
    @Input() public columnDefs: {  displayName: string, propertyName: string,
        sortPrimerFunc: (valIn) => any, dispType: string,
        fixedDec?: number, styleOverride?: any, clickable?: boolean}[] = [];
    @Input() public dataObjects: any[] = [];
    @Output() public cellClick = new EventEmitter<{rowData: any, propertyName: string}>();

    // when the sorts are set, we want to rerun the sort function...
    private sorts: {propertyName: string, reverse: boolean, primerFunction?: (valIn) => any}[] = [];
    @Input() public set initialSorts(v: {propertyName: string, reverse: boolean, primerFunction?: (valIn) => any}[]) {
      this.sorts = v;
      this.sortReportData();
    }
    public get initialSorts(): {propertyName: string, reverse: boolean, primerFunction?: (valIn) => any}[] {
      return this.sorts;
    }

    constructor() { }

    private toggleChildSort(propertyName: string, remove: boolean, descending?: boolean) {
      const existingSort = this.sorts.find(c => c.propertyName === propertyName);
      if (existingSort && remove) {
          this.sorts.splice(this.sorts.indexOf(existingSort), 1);
      } else if (!remove) {
          // get the column:
          const foundColumn = this.columnDefs.find(c => c.propertyName === propertyName);
          if (existingSort) {
              // update existing:
              existingSort.reverse = descending;
          } else {
              this.sorts.push({ primerFunction: foundColumn.sortPrimerFunc,
                                propertyName: foundColumn.propertyName,
                                reverse: descending});
          }
      }
    }

    private getSortPrimerFunc(propertyName: string): (valIn) => any {
      const col = this.columnDefs.find(d => d.propertyName === propertyName);
      if (col) {
        if (col.sortPrimerFunc) {
          return col.sortPrimerFunc;
        } else {
          if (col.dispType === 'date' || col.dispType === 'datetime') {
            return (valIn) => {
              if (valIn) {
                const date = Date.parse(valIn + 'Z');
                return date;
              } else {
                return null;
              }
            };
          }
        }
      }
      return null;
    }

    public sortReportData() {
      this.sorts.forEach(sort => {
        if (sort !== undefined) { // when moving, we have to push around
          sort.primerFunction = this.getSortPrimerFunc(sort.propertyName);
        }
      });
      this.dataObjects.sort(ReportsCommon.sort(this.sorts));
    }

    public addSort(propertyName: string, reverse: boolean) {
      this.sorts.push({propertyName, reverse});
      this.sortReportData();
    }

    public removeSort(sort: any) {
      const idx = this.sorts.indexOf(sort);
      if (idx >= 0) {
        this.sorts.splice(idx, 1);
      }
      this.sortReportData();
    }

    public getSort(propertyName: string): any {
      return this.sorts.find(s => s.propertyName === propertyName);
    }

    public getColumnDef(propertyName: string): any {
      return this.columnDefs.find(d => d.propertyName === propertyName);
    }

    public reorderSort(sort: any, up: boolean) {
      // console.log('moving sort', sort);
      let existingIdx = this.sorts.indexOf(sort);
      const newIndex = up ? existingIdx - 1 : existingIdx + 1;
      while (existingIdx < 0) {
        existingIdx += this.sorts.length;
      }
      // loop around? not working for looping end to front... look into this.
      // while (newIndex < 0) {
      //     newIndex += this.sorts.length;
      // }
      // if (newIndex >= this.sorts.length) {
      //     let k = newIndex - this.sorts.length;
      //     while ((k--) + 1) {
      //       this.sorts.push(null);
      //     }
      // }
      this.sorts.splice(newIndex, 0, this.sorts.splice(existingIdx, 1)[0]);
      // console.log('sorts', this.sorts);
      this.sortReportData();
    }

    public cellClicked(clickable: boolean, rowData: any, propertyName: string) {
      if (this.cellClick && clickable) {
        // console.log('emitting', this.cellClick, rowData, propertyName);
        this.cellClick.emit({rowData, propertyName});
      }
    }

    public getStyleOverrideForColumn( col: {displayName: string, propertyName: string,
                                      sortPrimerFunc: (valIn) => any, dispType: string,
                                      fixedDec?: number, styleOverride?: any, clickable?: boolean}) {
      let styleObj = col.styleOverride;
      if (col.clickable) {
        if (!styleObj) {styleObj = {}; }
        styleObj.cursor = 'pointer';
        styleObj['text-decoration'] = 'underline';
        styleObj.color = 'darkblue';
      }
      return styleObj;
    }

  }
