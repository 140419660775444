export class ReportsCommon {


    public static group(reportData: any[], groupBy: string[], sumBy: string[]): any[] {
        const ret: any[] = [];
        reportData.forEach(d => {
            let groupRow = ret.find(gd => {
                let found = true;
                groupBy.forEach(gb => {
                    if (found) {
                        if (typeof d[gb] !== 'boolean') {
                            found = gd[gb] === (d[gb] ? '' + d[gb] : 'NULL');
                        } else {
                            found = gd[gb] === d[gb];
                        }
                    }
                });
                return found;
            }
            );
            if (!groupRow) {
                groupRow = {};
                groupBy.forEach(gb => {
                    if (typeof d[gb] !== 'boolean') {
                        groupRow[gb] = d[gb] ? '' + d[gb] : 'NULL';
                    } else {
                        groupRow[gb] = d[gb];
                    }
                    groupRow.count = 0;
                    groupRow.children = [];
                });
                sumBy.forEach(sb => {
                    groupRow[sb + '_sum'] = 0;
                });
                ret.push(groupRow);
            }
            groupRow.count++;
            groupRow.children.push(d);
            sumBy.forEach(sb => {
                groupRow[sb + '_sum'] += d[sb];
            });
        });

        // console.log('Grouped data:', ret);
        return ret;
    }

    private static defaultCompare = function (a, b) {
        if (typeof a === 'string' || typeof b === 'string') {
            return (a ? a : '').localeCompare(b ? b : '');
        } else {
            if (a === b) {
                return 0;
            }
            return a < b ? -1 : 1;
        }
    };

    private static getCompareFunction = function (primer, reverse) {
        const dfc = this.defaultCompare;
        let cmp = this.defaultCompare;
        if (primer) {
            cmp = function (a, b) {
                // console.log('primer:', a, b);
                return dfc(primer(a), primer(b));
            };
        }
        if (reverse) {
            return function (a, b) {
                return -1 * cmp(a, b);
            };
        }
        return cmp;
    };

    /**
    * Returns a function that can be used in an array sort() call.
    * @param    fields A list of the fields that you wish to sort by. 
    *           The primerFunction is a method that will transform compared values before sorting.
    * @returns returns a function that can be passed to array.sort()
    */
    public static sort(fields: { propertyName: string, primerFunction?: (valIn) => any, reverse: boolean }[]): (a, b) => any {
        const fieldCount = fields.length;
        const sorts: { name: any, cmp: any }[] = [];
        // preprocess sorting options
        fields.forEach(field => {
            sorts.push({
                name: field.propertyName,
                cmp: this.getCompareFunction(field.primerFunction, field.reverse)
            });
        });

        // final comparison function
        return function (A, B) {
            let result;
            for (let i = 0; i < fieldCount; i++) {
                result = 0;
                const field = sorts[i];
                const name = field.name;

                result = field.cmp(A[name], B[name]);
                if (result !== 0) {
                    break;
                }
            }
            return result;
        }
    }
}
