import { ProjectScope } from './';

export class Project {
    id: string;
    created: string;
    createdByAccountId: string;
    name: string;
    background: string;
    requestDate: string;
    version: string;
    organizationId: string;
    tags: string[];
    types: string[];
    projectManagerAccountId: string;
    clientAccountId: string;
    scopes: ProjectScope[];
    status: string;
    checkInFrequency: string;
    visible: boolean;
    projectEstimationId: string;
    internalNotes: string;
    organizationUserPermissions: ProjectPermission[];
    consultantUserPermissions: ProjectPermission[];
    whyPM: string;

    uiOrgName: string;
}

export class ProjectSearchResult {
    projectName: string;
    projectId: string;
    organizationName: string;
    organizationId: string;
    clientAccountId: string;
    clientFirstName: string;
    clientLastName: string;
    clientEmail: string;
    projectCreated: string;
    projectRequestDate: string;
    projectStatus: string;
    projectTypes: string[];
    projectTags: string[];
    score: number;
    visible: boolean;
}

export class ProjectPermission {
    accountId: string;
    email: string;
    firstName: string;
    lastName: string;
    projectId: string;
    roleName: string;
    roleGroup: string;
}
