﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { Organization, OrganizationTerms, OrganizationAccount } from '../_models';
import * as FileSaver from 'file-saver';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
@Injectable()
export class OrganizationService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }

    private getBearerHeaders(refreshToken: string): HttpHeaders {
        return new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
    }

    public getAllOrganizations(refreshToken: string): Observable<Organization[]> {
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/organizations/', { headers: this.getBearerHeaders(refreshToken) })
            .pipe(
                take(1),
                map((response: Organization[]) => response)
            );
    }

    public getOrganizationsForAccount(accountId: string, refreshToken: string): Observable<Organization[]> {

        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/accounts/' + accountId + '/organizations', { headers: this.getBearerHeaders(refreshToken) })
            .pipe(
                take(1),
                map((response: Organization[]) => response)
            );
    }

    public updateOrganization(organization: Organization,
        refreshToken: string): Observable<Organization> {
        // add authorization header with jwt token

        const req = {
            name: organization.name,
            version: organization.version,
            timeZoneInfoName: organization.timeZoneInfoName,
            poEnabled: organization.poEnabled,
            paymentTerms: organization.paymentTerms
        };
        // console.log('req:', req);
        return this.http.patch(CONFIG.apiEndpointBase + 'admins/organizations/' + organization.id, req, { headers: this.getBearerHeaders(refreshToken) })
            .pipe(
                take(1),
                map((response: Organization) => response)
            );
    }

    public createOrganization(organization: Organization,
        createdForAccountId: string,
        refreshToken: string): Observable<Organization> {

        const req = {
            createdForAccountId,
            name: organization.name,
            timeZoneInfoName: organization.timeZoneInfoName
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/organizations/', req, { headers: this.getBearerHeaders(refreshToken) })
            .pipe(
                take(1),
                map((response: Organization) => response)
            );
    }

    public addToExistingOrganization(organizationId: string,
        accountId: string,
        refreshToken: string): Observable<Organization> {

        const req = {
            accountId,
            organizationId
        };
        console.log('posting', req);
        return this.http.post(CONFIG.apiEndpointBase + 'admins/accounts/organizations/', req, { headers: this.getBearerHeaders(refreshToken) })
            .pipe(
                take(1),
                map((response: Organization) => response)
            );
    }

    public upsertOrganizationAccount(acct: OrganizationAccount, refreshToken: string): Observable<any> {
        // post to the API:
        return this.http.post(CONFIG.apiEndpointBase + 'admins/organizations/accounts', acct, { headers: this.getBearerHeaders(refreshToken) })
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }

    public upsertOrganizationTerms(terms: OrganizationTerms,
        refreshToken: string): Observable<OrganizationTerms> {
        // add authorization header with jwt token

        const options = { headers: this.getBearerHeaders(refreshToken) };
        const req = {
            id: terms.id,
            organizationId: terms.organizationId,
            type: terms.type,
            name: terms.name,
            description: terms.description,
            azureBlobFilename: terms.azureBlobFilename,
            active: terms.active
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/organizations/terms', req, options)
            .pipe(
                take(1),
                map((response: OrganizationTerms) => response)
            );
    }

    public getOrganizationTerms(organizationId: string, refreshToken: string): Observable<OrganizationTerms[]> {

        const options = { headers: this.getBearerHeaders(refreshToken) };
        return this.http.get(CONFIG.apiEndpointBase + 'admins/organizations/' + organizationId + '/terms', options)
            .pipe(
                take(1),
                map((response: OrganizationTerms[]) => response)
            );
    }

    public downloadOrganizationTerms(terms: OrganizationTerms, refreshToken: string): Observable<boolean> {

        return this.http.get(CONFIG.apiEndpointBase + 'admins/organizations/terms/' + terms.id + '/' + terms.azureBlobFilename,
            { headers: this.getBearerHeaders(refreshToken), observe: 'response', responseType: 'blob' })
            .pipe(
                take(1),
                map((response: HttpResponse<Blob>) => {
                    const fileBlob = response.body
                    FileSaver.saveAs(fileBlob, terms.azureBlobFilename);
                    return true;
                })
            );
    }
}
