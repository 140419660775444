import { ProjectFilePermissionFlags } from './';
export class ProjectFile {
    id: string;
    created: string;
    projectId: string;
    createdByAccountId: string;
    name: string;
    description: string;
    url: string;
    googleDriveId: string;
    googleDriveFileContent: string;
    fileType: string;
    permissions: string[];
    version: string;
    kind: string;
    status: string;
    createdByAccountName: string;

    clientAccountPermissions: ProjectFilePermissionFlags;
    consultantPermissions: ProjectFilePermissionFlags;
    orgUserPermissions: ProjectFilePermissionFlags;
    orgViewerPermissions: ProjectFilePermissionFlags;
    projectManagerPermissions: ProjectFilePermissionFlags;
}
