import { PaymentTransaction } from '.';

export class ProjectInvoice {
    public id: string;
    public invoiceNumber: string;
    public created: string;
    public projectId: string;
    public createdByAccountId: string;
    public approvedByAccountId: string;
    public approvedOn: string;
    public notes: string;
    public dueDate: string;
    public lineItems: ProjectInvoiceLineItem[];
    public transactions: PaymentTransaction[];
    public visible: boolean;
}

export class ProjectInvoiceLineItem {
    public id: string;
    public created: string;
    public projectInvoiceId: string;
    public projectScopeDeliverableId: string;
    public createdByAccountId: string;
    public lineNumber: number;
    public description: string;
    public notes: string;
    public quantity: number;
    public uom: string;
    public unitPrice: number;
    public discount: number;
    public discountDescription: string;
    public tax: number;
    public periodBeginning: string;
    public periodEnding: string;

    public dirtiedAt: number;
    public saving: boolean;
    public saved: boolean;
    public saveError: boolean;
    public invalid: boolean;
    public alert: string;
}
