import { StripeBankAccount } from './stripeBankAccount';
import { StripeCard } from './stripeCard';
import { ExternalPaymentAccount } from './externalPaymentAccount';

export class PaymentAccountWallet {
    stripeCards: Array<StripeCard>;
    stripeBankAccounts: Array<StripeBankAccount>;
    externalPaymentAccounts: Array<ExternalPaymentAccount>;

    constructor(w: any) {
        this.stripeCards = new Array<StripeCard>();
        this.stripeBankAccounts = new Array<StripeBankAccount>();
        this.externalPaymentAccounts = new Array<ExternalPaymentAccount>();
        for (const c of w.stripeCards) {
            this.stripeCards.push(new StripeCard(c));
        }
        for (const b of w.stripeBankAccounts) {
            this.stripeBankAccounts.push(new StripeBankAccount(b));
        }
        for (const x of w.externalPaymentAccounts) {
            this.externalPaymentAccounts.push(new ExternalPaymentAccount(x));
        }
    }
}
