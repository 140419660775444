/* tslint:disable:component-class-suffix */
import { Component, Input } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';
import { ReportsCommon } from './reports-common';


@Component({
    moduleId: module.id,
    selector: 'projects-overview-report',
    templateUrl: 'projects-overview-report.component.html',
    styleUrls: ['reports-common.scss']
})
export class ProjectOverviewReportComponent {
    @Input() containerHeight = 500;
    public reportData: any[];
    public groupedData: any[] = [];
    public selectedGroup: any;

    public loggedInAccount: Account;
    public loading = false;
    public groupBy = ['projectStatus'];
    public sumBy = ['totalBillableHours'];

    public childColumns: {
        displayName: string, propertyName: string,
        sortPrimerFunc?: (valIn) => any, dispType: string,
        fixedDec?: number, styleOverride?: any, clickable?: boolean
    }[] = [
            { displayName: 'Organization', propertyName: 'organizationName', dispType: 'string' },
            {
                displayName: 'Project', propertyName: 'projectName', clickable: true,
                dispType: 'string', styleOverride: { 'min-width': '300px' }
            },
            { displayName: 'Started Work On', propertyName: 'projectStartDate', dispType: 'date' },
            { displayName: 'Status', propertyName: 'projectStatus', dispType: 'string' },
            { displayName: 'Finished Work On', propertyName: 'projectCompletedDate', dispType: 'date' },
            { displayName: 'Last Paid On', propertyName: 'lastPaidDate', dispType: 'date' },
            { displayName: '$ Successfully Billed', propertyName: 'totalSuccessfullyBilledToClient', dispType: 'money' },
            { displayName: 'Billable Hours', propertyName: 'totalApprovedBillableHours', dispType: 'number' },
            { displayName: '$ Paid To Consultants', propertyName: 'totalPaidToConsultants', dispType: 'money' },
            { displayName: '$ Margin', propertyName: 'margin', dispType: 'money' },
            { displayName: '% Margin', propertyName: 'marginPct', dispType: 'pct' },
            { displayName: 'Consultants', propertyName: 'consultants', dispType: 'string' }
        ];

    public initialSorts: { propertyName: string, reverse: boolean }[] = [];

    public get childTableHeight(): number {
        return this.containerHeight - 220;
    }



    private _displayed: string;
    public get displayed(): string { return this._displayed; }
    @Input() public set displayed(v: string) { if (v && !this._displayed) { this.getReportData(); } this._displayed = v; }


    constructor(
        private accountService: AccountService,
        private reportsService: ReportsService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                // this.getReportData();
            }).subscribe();
    }

    private getReportData() {
        if (!this.reportData || this.reportData.length === 0) {
            this.loading = true;
            this.reportsService.getReport('projectOverview', this.loggedInAccount.refreshToken)
                .subscribe(reportData => {
                    if (reportData) {
                        this.initialSorts = [
                            { propertyName: 'organizationName', reverse: false },
                            { propertyName: 'projectName', reverse: false },
                        ];
                        this.reportData = reportData;
                        this.setGroupedData();
                    }
                    this.loading = false;
                });
        }
    }

    public setGroupedData() {
        this.groupedData = ReportsCommon.group(this.reportData, this.groupBy, this.sumBy);
        this.groupedData.sort(ReportsCommon.sort([
            { propertyName: 'projectStatus', primerFunction: null, reverse: false },
        ]));
    }

    public cellClick(d: { rowData: any, propertyName: string }) {
        window.open('/projectAdmin/edit/' + d.rowData.projectId, '_blank');
    }

}


