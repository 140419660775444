/* tslint:disable:component-class-suffix */
import { Component } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';


@Component({
    moduleId: module.id,
    selector: 'monthly-projects-report',
    templateUrl: 'monthly-projects-report.component.html',
    // styleUrls: ['reports-common.scss']
})
export class MonthlyProjectsReportComponent {
    // lineChart
    public lineChartLegend = true;
    public lineChartType = 'line';
    public lineChartOptions: any = { responsive: true };
    public reportData: Array<any> = [];
    public reportLabels: Array<any> = [];
    public lineChartData: Array<any> = [];
    public lineChartLabels: Array<any> = [];
    public loggedInAccount: Account;
    public loading = false;


    constructor(
        private accountService: AccountService,
        private reportsService: ReportsService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                this.getReportData();
            }).subscribe();
    }

    private getReportData() {
        this.loading = true;
        this.reportsService.getReport('monthlyProjects', this.loggedInAccount.refreshToken)
            .subscribe(reportData => {
                // sort the results by year then month number:
                if (reportData) {
                    reportData = reportData.sort((a, b) => {
                        return Number(a.year) - Number(b.year) || a.monthNumber - b.monthNumber;
                    });
                    // format the results into the expected chart format, then set:
                    const chartData: { data: any[], label: string }[] = [
                        { label: 'Invoiced $', data: [] },
                        { label: 'Invoiced Projects #', data: [] },
                        { label: 'Invoices #', data: [] },
                        { label: 'Avg $ / Invoiced Project', data: [] },
                        { label: 'Collected $ So Far', data: [] },
                        { label: 'Outstanding $', data: [] },
                        { label: 'Outstanding Invoices #', data: [] },

                        { label: 'Projects Created', data: [] },
                        { label: 'Projects Started', data: [] },
                        { label: 'Projects Active', data: [] },
                        { label: 'Projects Completed', data: [] }
                    ];
                    const reportLabels: string[] = [];
                    reportData.forEach(d => {
                        reportLabels.push(d.month + ', ' + d.year);
                        chartData.find(cd => cd.label === 'Invoiced $').data.push(d.totalInvoicedAmount
                            ? '$' + d.totalInvoicedAmount.toFixed(0) : 0);
                        chartData.find(cd => cd.label === 'Invoiced Projects #').data.push(d.projectsInvoiced ? d.projectsInvoiced : 0);
                        chartData.find(cd => cd.label === 'Invoices #').data.push(d.invoiceCount ? d.invoiceCount : 0);
                        chartData.find(cd => cd.label === 'Avg $ / Invoiced Project').data.push(d.avgInvoicedAmountPerProject
                            ? '$' + d.avgInvoicedAmountPerProject.toFixed(0) : 0);
                        chartData.find(cd => cd.label === 'Collected $ So Far').data.push(d.totalSuccessfulChargeAmount
                            ? '$' + d.totalSuccessfulChargeAmount.toFixed(0) : 0);
                        chartData.find(cd => cd.label === 'Outstanding $').data.push(d.totalOutstandingChargeAmount
                            ? '$' + d.totalOutstandingChargeAmount.toFixed(0) : 0);
                        chartData.find(cd => cd.label === 'Outstanding Invoices #').data.push(d.outstandingInvoiceCount
                            ? d.outstandingInvoiceCount : 0);

                        chartData.find(cd => cd.label === 'Projects Created').data.push(d.totalCreated ? d.totalCreated : 0);
                        chartData.find(cd => cd.label === 'Projects Started').data.push(d.totalStarted ? d.totalStarted : 0);
                        chartData.find(cd => cd.label === 'Projects Active').data.push(d.totalActive ? d.totalActive : 0);
                        chartData.find(cd => cd.label === 'Projects Completed').data.push(d.totalCompleted ? d.totalCompleted : 0);

                    });
                    this.reportData = chartData;
                    this.reportLabels = reportLabels;
                    this.lineChartData = chartData.slice(7, 11);
                    this.lineChartLabels = this.reportLabels;
                }
                this.loading = false;
            });
    }


    // events
    public chartClicked(e: any): void {
        console.log(e);
    }

    public chartHovered(e: any): void {
        console.log(e);
    }
}
