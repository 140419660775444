export class RawListItemSearchResult {
    public listItemRawId: string;
    public listItemNoteId: string;
    public listItemId: string;
    public azureBlobFilename: string;
    public urgent: boolean;
    public listItemRawCreated: string;
    public listItemRawAccountId: string;
    public listItemNoteCreatedBy: string;
    public listItemNote: string;
    public listItemCreatedBy: string;
    public listItemDeleted: boolean;
    public listItemDeletedBy: string;
    public listItemBody: string;
    public listItemDisplayOrder: number;
    public listItemType: string;
    public projectId: string;
    public firstName: string;
    public lastName: string;
    public callToAction: string;
    public ctaResponseDate: string;
    public listItemNoteCreated;
    public uiBlobUrl: string;
    public listItemRawCaption: string;
}
