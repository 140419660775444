import { StripeCard, ExternalPaymentAccount } from './';
export class PaymentTransaction {
    id: string;
    paymentAccountId: string;
    created: string;
    paymentTransactionDetailTypeId: number;
    amount: number;
    currency: string;
    approved: boolean;
    description: string;
    transactionDetail: any;
    paymentTransactionDetailTypeName: string;
    projectInvoiceId: string;
    paymentAccountCard: StripeCard;
    paymentAccountExternal: ExternalPaymentAccount;

    constructor(t: PaymentTransaction) {
        this.id = t.id;
        this.paymentAccountId = t.paymentAccountId;
        this.created = t.created;
        this.paymentTransactionDetailTypeId = t.paymentTransactionDetailTypeId;
        this.amount = t.amount;
        this.currency = t.currency;
        this.approved = t.approved;
        this.description = t.description;
        this.transactionDetail = t.transactionDetail;
        this.paymentTransactionDetailTypeName = t.paymentTransactionDetailTypeName;
        this.projectInvoiceId = t.projectInvoiceId;
        this.paymentAccountCard = t.paymentAccountCard;
        this.paymentAccountExternal = t.paymentAccountExternal;
    }
}
