import { HostListener, Component, ElementRef, Input, Output, EventEmitter, ViewChild } from '@angular/core';

const TEMPLATE = `
    <label for="inputId">{{label}}</label>
    <div class="input-group">
        <input type="text" id="inputId" class="form-control" [(ngModel)]="query" (keyup)="filter($event)"
        (blur)="blurThis()" (focus)="focusThis()">
        <div class="input-group-addon" (click)="filter(undefined)"><i class="fa fa-search"></i></div>
    </div>
    <div class="suggestions" *ngIf="filteredItems.length > 0">
        <ul>
            <li *ngFor="let item of filteredItems">
                <a (click)="select(item)" [ngClass]="{ selectedItem: isSelected(item) }">{{displayObject(item)}}</a>
            </li>
        </ul>
    </div>
    <div class="btn-group btn-group-xs selectedOuter" role="group" *ngIf="selectedItems && selectedItems.length > 0">
        <button (click)="remove(item)" type="button" class="btn btn-default" *ngFor="let item of selectedItems">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;&nbsp;<span>{{displayObject(item)}}</span>
        </button>
    </div>
    <div class="btn-group btn-group-xs selectedOuter nothing" role="group" *ngIf="!selectedItems || selectedItems.length === 0">
        Nothing Selected
    </div>
`;

@Component({
    selector: 'multiSelect',
    moduleId: module.id,
    template: TEMPLATE,
    styleUrls: ['multi-select.component.css'],
})
export class MultiSelectComponent {

    public query = '';

    @Input() public selectedItems = [];
    public elementRef;
    @Input() label: string;
    @Input() filteredItems = [];
    @Input() displayValue: (value: any) => string;
    @Output() runFilter = new EventEmitter();
    @Output() selectedItemsChanged = new EventEmitter();
    @Output() handleItemAddition = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @ViewChild('inputId') inputBox: ElementRef;

    hasFocus = false;
    selectedIndex = -1;

    constructor(private myElement: ElementRef) {
        this.elementRef = myElement;
    }

    displayObject(obj: any): string {
        return this.displayValue(obj);
    }

    isSelected(item: any) {
        return this.filteredItems.indexOf(item) === this.selectedIndex;
    }

    blurThis() {
        if (this.hasFocus) {
            this.hasFocus = false;
            this.onBlur.emit();
        }
    }
    focusThis() {
        this.hasFocus = true;
    }

    filter(event: any) {
        // if (event && event.keyCode) {
        //     console.log('Event keycode:', event.keyCode);
        //     // ESC = 27
        //     // Left Arrow:37
        //     // Up Arrow: 38
        //     // Right Arrow: 39
        //     // Down Arrow: 40
        // }
        // console.log('EVENT:', event);
        if (event && event.keyCode) { // handle keypresses:
            if (this.handleItemAddition && event.keyCode === 13 && this.query.trim() !== ''
                && this.selectedIndex < 0) { // enter key pressed, nothing selected by arrows
                this.handleItemAddition.emit(this);
                return;
            } else if (event.keyCode === 13 && this.selectedIndex >= 0) {
                this.select(this.filteredItems[this.selectedIndex]); // arrow select, enter pressed.
                this.query = '';
                this.filteredItems = [];
                this.selectedIndex = -1;
                return;
            } else if (event.keyCode === 27) { // escape key pressed
                this.query = '';
                this.filteredItems = [];
                this.selectedIndex = -1;
                return;
            } else if (event.keyCode >= 37 && event.keyCode <= 40) {
                // handle arrows:
                if (event.keyCode === 40) {
                    // move down the list:
                    this.selectedIndex++;
                } else if (event.keyCode === 38) {
                    // move up the list:
                    this.selectedIndex--;
                }
                if (this.selectedIndex < 0) {
                    this.selectedIndex = -1; // can't get below -1
                } else if (this.selectedIndex > this.filteredItems.length - 1) {
                    this.selectedIndex = -1; // can't go above how many are in the filtered items list.
                } else if (this.filteredItems.length === 0) {
                    this.selectedIndex = -1; // if there is nothing, there should be no selected index.
                }
            } else {
                this.selectedIndex = -1;
            }
        }
        this.runFilter.emit({ query: this.query, selectedItems: this.selectedItems });
    }

    select(item) {
        if (!(this.selectedItems.indexOf(item) > -1)) {
            this.selectedItems.push(item);
        }
        this.query = '';
        this.filteredItems = [];
        this.selectedItemsChanged.emit({ selectedItems: this.selectedItems });
    }

    remove(item) {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.selectedItemsChanged.emit({ selectedItems: this.selectedItems });
    }

    @HostListener('document:click', ['$event.target'])
    handleClick(event) {
        let clickedComponent = event;
        let inside = false;
        do {
            if (clickedComponent === this.elementRef.nativeElement) {
                inside = true;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);
        if (!inside) {
            this.filteredItems = [];
        }
    }
}


// This inherits from our base component and uses a different style sheet.
@Component({
    selector: 'multiSelect2',
    moduleId: module.id,
    template: TEMPLATE,
    // This is using a unique css file 
    styleUrls: ['multi-select.component2.css'],
})
export class MultiSelectComponent2 extends MultiSelectComponent { }
