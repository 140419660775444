import { Component, OnInit, AfterContentInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService, SiteService, ProjectService } from '../_services';
import { Store } from '@ngrx/store';
import {
    Account, ProjectEstimationAbstract, ProjectEstimation, ProjectEstimationOption,
    ProjectEstimationQuestion, ProjectEstimationOptionItem
} from '../_models';
import { LOGIN, LOGOUT } from '../_reducers/account.reducer';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
@Component({
    selector: 'project-types-admin',
    moduleId: module.id,
    templateUrl: 'project-types.component.html',
    styleUrls: ['project-types.component.css']
})
export class ProjectTypesComponent implements OnInit, OnDestroy {

    public loggedInAccount: Account = null;
    public allProjectEstimationAbstracts: ProjectEstimationAbstract[] = null;
    public selectedEstimation: ProjectEstimation = null;
    public projectEstimationForm: FormGroup;
    public searchText: FormControl;
    public team: FormControl;
    public category: FormControl;
    public division: FormControl;
    public inFlexTeam: FormControl;
    public sort: FormControl;
    public teams: Set<string>;
    public categories: Set<string>;
    public divisions: Set<string>;
    private allTeams: string[] = [];
    private allCategories: string[] = [];
    private allDivisions: string[] = [];
    public optionToAddTo: ProjectEstimationOption = null;
    public newItemDescription: string = null;
    public optionItemUnderEdit: ProjectEstimationOptionItem = null;
    public loading = false;
    public destroying = false;
    public isDirty = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private siteService: SiteService,
        private projectService: ProjectService,
        private store: Store<Account>) {
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loadEstimateIfPassed();
            }).subscribe();

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.loadEstimateIfPassed();
            }
        });
    }

    public ngOnInit() {
        this.buildForm();
        this.initializeLists();
    }

    public ngOnDestroy() {
        this.destroying = true;
        this.save(false);
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = `Please save before navigating away!`;
        }
    }

    private canDeactivate(): Observable<boolean> | boolean {
        return false;
    }

    private loadEstimateIfPassed() {
        this.route.params.pipe(
            take(1),
            map((params) => {
                const estimateId = params['projectEstimateId'];
                const component = this;
                if (estimateId) {
                    this.loadEstimation(estimateId);
                }
            }))
            ;
    }

    public loadEstimation(id: string) {
        if (this.isDirty) {
            this.save(false);
        }
        this.loading = true;
        this.siteService.getProjectEstimationById(id, this.loggedInAccount.refreshToken).subscribe((pe) => {
            this.selectedEstimation = pe;
            this.loading = false;
            window.scrollTo(0, 0);
        });
    }

    public get options(): ProjectEstimationOption[] {
        const ret = [];
        ret.push(this.essentialOption);
        ret.push(this.premiumOption);
        ret.push(this.expertOption);
        return ret;
    }

    public get essentialOption(): ProjectEstimationOption {
        return this.getOptionByName('Essential');
    }

    public get premiumOption(): ProjectEstimationOption {
        return this.getOptionByName('Premium');
    }

    public get expertOption(): ProjectEstimationOption {
        return this.getOptionByName('Expert');
    }

    public getOptionByName(name: string): ProjectEstimationOption {
        if (this.selectedEstimation) {
            // find and return:
            if (this.selectedEstimation.options) {
                const found = this.selectedEstimation.options.find((o) => {
                    return o.name === name;
                });
                if (found) {
                    return found;
                }
            }

            // it wasn't found and we didn't return, so create a new one:
            const ret: ProjectEstimationOption = new ProjectEstimationOption();
            ret.id = null;
            ret.name = name;
            ret.items = [];
            ret.projectEstimationId = this.selectedEstimation.id;
            this.selectedEstimation.options.push(ret);
            return ret;
        }

        return null;
    }

    public markDirty() {
        this.isDirty = true;
    }

    private buildForm() {
        this.searchText = new FormControl();
        this.team = new FormControl('Team');
        this.category = new FormControl('Category');
        this.division = new FormControl('Division');
        this.inFlexTeam = new FormControl(false);
        this.sort = new FormControl('Sort By');
        this.projectEstimationForm = new FormGroup({
            team: this.team,
            category: this.category,
            division: this.division,
            searchText: this.searchText,
            inFlexTeam: this.inFlexTeam,
            sort: this.sort
        });
    }

    public clearFilters() {
        this.team.setValue('Team');
        this.division.setValue('Division');
        this.category.setValue('Category');
        this.inFlexTeam.setValue(false);
        this.updateDropdowns();
    }

    public updateDropdowns() {
        // filter the dropdowns so they make sense:
        if (this.allProjectEstimationAbstracts) {
            const filteredTeams = [];
            const filteredDivisions = [];
            const filteredCategories = [];

            this.allProjectEstimationAbstracts.forEach(project => {
                if (this.division.value === 'Division' && this.team.value === 'Team' && this.category.value === 'Category') {
                    filteredCategories.push(project.projectCategory);
                    filteredDivisions.push(project.projectDivision);
                    filteredTeams.push(project.projectTeam);
                } else {
                    if ((this.division.value === project.projectDivision || this.division.value === 'Division') &&
                        (this.team.value === project.projectTeam || this.team.value === 'Team') &&
                        (this.category.value === project.projectCategory || this.category.value === 'Category')) {
                        filteredCategories.push(project.projectCategory);
                        filteredDivisions.push(project.projectDivision);
                        filteredTeams.push(project.projectTeam);
                    }
                }
            });

            this.categories = new Set(filteredCategories.sort());
            this.divisions = new Set(filteredDivisions.sort());
            this.teams = new Set(filteredTeams.sort());
        }
    }

    filteredProjects(): ProjectEstimationAbstract[] {
        if (!this.allProjectEstimationAbstracts) {
            return [];
        }
        const results = this.allProjectEstimationAbstracts.filter(project => {
            return (this.team.value === 'Team' || project.projectTeam.indexOf(this.team.value) > -1)
                && (this.category.value === 'Category' || project.projectCategory.indexOf(this.category.value) > -1)
                && (this.division.value === 'Division' || project.projectDivision.indexOf(this.division.value) > -1)
                && (!this.inFlexTeam.value || project.inFlexTeam)
                && (!this.searchText.value || project.projectType.toLowerCase().indexOf(this.searchText.value.toLowerCase()) > -1);
        })
            .sort((d1, d2) => {
                if (this.sort.value === 'importanceRank') {
                    return this.sortMostRequested(d1, d2);
                }
                if (this.sort.value === 'asc') {
                    return this.sortName(d1, d2);
                }
                if (this.sort.value === 'desc') {
                    return this.sortName(d1, d2, true);
                }
                if (d1.inFlexTeam && !d2.inFlexTeam) {
                    return -1;
                }
                if (!d1.inFlexTeam && d2.inFlexTeam) {
                    return 1;
                }
                return this.sortName(d1, d2);
            });
        // for (let i = 0; i < results.length; i++) {
        //   const item = results[i];
        //   item.listIndex = i;
        //   item.totalResults = results.length;
        // }
        return results;
    }

    private sortName(d1: ProjectEstimationAbstract, d2: ProjectEstimationAbstract, desc?: boolean) {
        if (desc) {
            return d1.projectType > d2.projectType ? -1 : (d1.projectType === d2.projectType ? 0 : 1);
        }
        return d1.projectType < d2.projectType ? -1 : (d1.projectType === d2.projectType ? 0 : 1);
    }

    private sortMostRequested(d1: ProjectEstimationAbstract, d2: ProjectEstimationAbstract, desc?: boolean) {
        let ret = d1.importanceRank > d2.importanceRank ? 1 : (d1.importanceRank === d2.importanceRank ? 0 : -1);
        if (ret === 0) {
            // return based on alpha as consistency tiebreaker.
            ret = d1.projectType < d2.projectType ? -1 : (d1.projectType === d2.projectType ? 0 : 1);
        }
        return ret;
    }

    private initializeLists() {
        this.loading = true;
        this.siteService.getProjectEstimationAbstracts(this.loggedInAccount.refreshToken).subscribe((abstracts) => {
            abstracts = abstracts.sort(function (a, b) {
                return (a.importanceRank > b.importanceRank) ? 1 : ((b.importanceRank > a.importanceRank) ? -1 : 0);
            });
            this.allProjectEstimationAbstracts = abstracts;
            this.allProjectEstimationAbstracts.forEach(project => {
                // console.log('pushing project data into lists', project.projectCategory);
                this.allTeams.push(project.projectTeam);
                this.allCategories.push(project.projectCategory);
                this.allDivisions.push(project.projectDivision);
            });
            this.teams = new Set(this.allTeams.sort());
            this.divisions = new Set(this.allDivisions.sort());
            this.categories = new Set(this.allCategories.sort());
            this.sort.setValue('importanceRank');
            this.loading = false;
        });
    }

    public defaultQuestionChange(question: ProjectEstimationQuestion) {
        if (question.isDefault) {
            // turn all others off...
            this.selectedEstimation.questions.forEach(thisQ => {
                if (thisQ !== question) {
                    thisQ.isDefault = false;
                }
            });
        } else {
            // if there are no others that are set as default, make the first the default...
            if (!this.selectedEstimation.questions.find(q => {
                return q.isDefault;
            })) {
                if (this.selectedEstimation.questions.length > 0) {
                    this.selectedEstimation.questions[0].isDefault = true;
                }
            }
        }
        this.markDirty();
    }

    public save(reload: boolean) {
        if (!this.isDirty) {
            return;
        } else {
            this.loading = true;
            this.siteService.upsertProjectEstimation(this.selectedEstimation,
                this.loggedInAccount.refreshToken).subscribe((upserted) => {
                    if (reload) {
                        this.selectedEstimation = upserted;
                    }
                    console.log('saved', upserted);
                    this.loading = false;
                    this.isDirty = false;

                    // update the abstract:
                    const found = this.allProjectEstimationAbstracts.find((a) => {
                        return a.id === upserted.id;
                    });

                    if (found) {
                        this.updateAbstractFromEstimation(found, upserted);
                    }
                });
        }
    }

    public addQuestion() {
        const q = new ProjectEstimationQuestion();
        q.projectEstimationId = this.selectedEstimation.id;
        q.isDefault = this.selectedEstimation.questions.length === 0;
        q.id = null;
        q.questionType = 'Normal';
        this.selectedEstimation.questions.push(q);
    }

    public setOptionToAddTo(opt: ProjectEstimationOption) {
        this.optionToAddTo = opt;
        this.newItemDescription = null;
    }

    public setOptionItemUnderEdit(item: ProjectEstimationOptionItem) {
        this.cancelOptionItemAdd();
        this.optionItemUnderEdit = item;
    }

    public finishOptionItemUnderEdit() {
        this.optionItemUnderEdit = null;
    }

    public cancelOptionItemAdd() {
        this.optionToAddTo = null;
        this.newItemDescription = null;
    }

    public addOptionItem() {
        const i = new ProjectEstimationOptionItem();
        i.id = null;
        i.projectEstimationOptionId = this.optionToAddTo.id;
        i.description = this.newItemDescription;
        if (!this.optionToAddTo.items) {
            this.optionToAddTo.items = [];
        }
        this.optionToAddTo.items.push(i);
        this.optionToAddTo = null;
        this.newItemDescription = null;
    }

    public deleteQuestion(question: ProjectEstimationQuestion) {
        const idx = this.selectedEstimation.questions.indexOf(question);
        this.selectedEstimation.questions.splice(idx, 1);
    }

    public deleteOptionItem(item: ProjectEstimationOptionItem) {
        // find the option:
        this.selectedEstimation.options.forEach(option => {
            const idx = option.items.indexOf(item);
            if (idx >= 0) {
                option.items.splice(idx, 1);
                this.finishOptionItemUnderEdit();
            }
        });
    }

    private updateAbstractFromEstimation(abstract: ProjectEstimationAbstract, estimation: ProjectEstimation) {
        abstract.id = estimation.id;
        abstract.importanceRank = estimation.importanceRank;
        abstract.inFlexTeam = estimation.inFlexTeam;
        abstract.projectCategory = estimation.projectCategory;
        abstract.projectDivision = estimation.projectDivision;
        abstract.projectTeam = estimation.projectTeam;
        abstract.projectType = estimation.projectType;
        abstract.visible = estimation.visible;
    }

    public addNewProjectType() {
        this.loading = true;
        // create a blank, and save it:
        const e = new ProjectEstimation();
        e.projectType = 'New Project Type';
        e.importanceRank = 99999;
        e.visible = false;
        e.id = null;
        this.siteService.upsertProjectEstimation(e, this.loggedInAccount.refreshToken).subscribe((created) => {
            // create a dummy abstract for this:
            const abstract = new ProjectEstimationAbstract();
            this.updateAbstractFromEstimation(abstract, created);
            this.allProjectEstimationAbstracts.push(abstract);
            this.selectedEstimation = created;
            this.loading = false;
        });
    }

    public deleteProjectEstimation() {
        this.loading = true;
        const selectedEstimationId = this.selectedEstimation.id;
        this.siteService.deleteProjectEstimationById(selectedEstimationId,
            this.loggedInAccount.refreshToken).subscribe(() => {
                this.selectedEstimation = null;
                this.initializeLists();
                this.loading = false;
            });
    }

}
