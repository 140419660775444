import { Component, OnInit, OnChanges, SimpleChanges, ElementRef, Input } from '@angular/core';
import { Account, AdminAccount, SystemRole, Organization, TimeZoneInfo, OrganizationTerms, OrganizationAccountPermissionFlags, OrganizationPaymentTermsFlags } from '../_models';
import { AccountService, SystemRolesService, OrganizationService, UtilityService, ListService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';

@Component({
    moduleId: module.id,
    selector: 'organization-admin',
    templateUrl: 'organization-admin.component.html',
    styleUrls: ['organization-admin.component.css']
})
export class OrganizationAdminComponent implements OnInit {
    public static allOrganizationRoles: any[] = [
        {
            name: 'User',
            description: 'Organization User',
            flag: OrganizationAccountPermissionFlags.User
        },
        {
            name: 'Invitor',
            description: 'Can invite others to organization',
            flag: OrganizationAccountPermissionFlags.Invitor
        },
        {
            name: 'BillingAdmin',
            description: 'Can create / modify billing accounts for the organization',
            flag: OrganizationAccountPermissionFlags.BillingAdmin
        },
        {
            name: 'Admin',
            description: 'Can administer organization properties, set user permissions',
            flag: OrganizationAccountPermissionFlags.Admin
        }
    ];
    public static allTimeZones: TimeZoneInfo[];

    private _adminAccount: AdminAccount;
    @Input() public set adminAccount(val: AdminAccount) {
        this._adminAccount = val;
        this.getOrgs();
    }
    public get adminAccount(): AdminAccount {
        return this._adminAccount;
    }
    public loggedInAccount: Account;


    public organizations: Organization[] = [];
    public orgUnderEdit: Organization = null;
    public newOrg: Organization = null;
    public newOrgTerms: OrganizationTerms = null;
    public orgTermsUnderEdit: OrganizationTerms = null;
    public showAddToExistingOrg = false;
    public canSetRoles = false;
    public orgTerms: OrganizationTerms[] = [];

    public selectedOrgId: string[] = null;
    public organizationOptions: IMultiSelectOption[] = [];
    private allOrganizations: Organization[] = [];

    public singleSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        dynamicTitleMaxItems: 1,
        displayAllSelectedText: false,
        selectionLimit: 1,
        autoUnselect: true,
        showUncheckAll: true,
        closeOnSelect: true
    };
    // Text configuration
    public multiSelectTexts: IMultiSelectTexts = {
        checkAll: 'Select all',
        uncheckAll: 'Unselect all',
        checked: 'item selected',
        checkedPlural: 'items selected',
        searchPlaceholder: 'Find',
        defaultTitle: '- - - - - - - - - - - - - - - - - Select - - - - - - - - - - - - - - - - - ',
        allSelected: 'All selected',
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private organizationService: OrganizationService,
        private utilityService: UtilityService,
        private systemRolesService: SystemRolesService,
        private store: Store<Account>) {
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                if (this.loggedInAccount) {
                    this.canSetRoles = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['RoleAdmin'] });
                }
            }).subscribe();

        if (!OrganizationAdminComponent.allTimeZones) {
            // get all timezones:
            this.utilityService.getAllTimeZones(this.loggedInAccount.refreshToken)
                .subscribe((result: TimeZoneInfo[]) => {
                    if (result) {
                        OrganizationAdminComponent.allTimeZones = result;
                    }
                });
        }
    }

    public ngOnInit() {

    }

    public get allTimeZones() {
        return OrganizationAdminComponent.allTimeZones;
    }

    public get allOrgRoles() {
        return OrganizationAdminComponent.allOrganizationRoles;
    }

    public addToExistingOrgLoad() {
        this.setOrganizations();
    }

    public addToExistingOrg() {
        this.organizationService.addToExistingOrganization(this.selectedOrgId[0], this.adminAccount.id, this.loggedInAccount.refreshToken)
            .subscribe((org) => {
                this.showAddToExistingOrg = false;
                this.getOrgs();
            });
    }

    private setOrganizations() {
        this.organizationService.getAllOrganizations(this.loggedInAccount.refreshToken).subscribe((orgs: Organization[]) => {
            if (orgs && orgs.length > 0 && this.organizationOptions.length === 0) {
                this.allOrganizations = orgs;
                orgs.forEach(org => {
                    this.organizationOptions.push({
                        id: org.id,
                        name: org.name
                    });
                });
                this.showAddToExistingOrg = true;
            }
        });
    }

    public getOrgs() {
        // console.log('getOrgs()', this.adminAccount);
        if (this.adminAccount) {
            this.organizationService.getOrganizationsForAccount(this.adminAccount.id,
                this.loggedInAccount.refreshToken).subscribe((organizations: Organization[]) => {
                    // console.log('orgs:', organizations);
                    this.organizations = organizations;
                    // if under edit, refresh the one under edit:
                    if (this.orgUnderEdit) {
                        const foundOrgs = this.organizations.filter(o => {
                            return o.id === this.orgUnderEdit.id;
                        });
                        if (foundOrgs.length > 0) {
                            this.setEditOrg(foundOrgs[0]);
                        } else {
                            this.setEditOrg(null);
                        }
                    }
                });
        }
    }

    public setEditOrg(org: Organization) {
        if (!org) {
            this.orgUnderEdit = null;
            this.orgTermsUnderEdit = null;
            this.newOrgTerms = null;
        } else {
            // create a deep copy:
            this.orgUnderEdit = JSON.parse(JSON.stringify(org));
            console.log('org under edit:', this.orgUnderEdit);
        }
        this.getOrgTerms();
    }

    public setNewOrg() {
        this.newOrg = new Organization();
        this.newOrg.timeZoneInfoName = 'Pacific Standard Time';
    }

    setCreateNewOrgTerms() {
        this.newOrgTerms = new OrganizationTerms();
        this.newOrgTerms.organizationId = this.orgUnderEdit.id;
        this.newOrgTerms.active = true;
        this.newOrgTerms.type = 'Client';
    }

    public cancelNewOrg() {
        this.newOrg = null;
    }

    public createOrg() {
        this.organizationService.createOrganization(
            this.newOrg, this.adminAccount.id,
            this.loggedInAccount.refreshToken).subscribe((organization: Organization) => {
                this.newOrg = null;
                this.getOrgs();
            });
    }

    public updateOrg() {
        this.organizationService.updateOrganization(
            this.orgUnderEdit, this.loggedInAccount.refreshToken).subscribe((organization: Organization) => {
                this.orgUnderEdit = null;
                this.getOrgs();
            });
    }

    public setOrgRole(role: any, roleEnabled: boolean) {
        // console.log('setOrgRole', roleId)
        const orgUser = this.orgUnderEdit.accounts.find(a => a.accountId === this.adminAccount.id);
        if (roleEnabled) {
            // tslint:disable-next-line:no-bitwise
            orgUser.userPermissions |= role.flag;
        } else {
            // tslint:disable-next-line:no-bitwise
            orgUser.userPermissions &= ~role.flag;
        }
        this.organizationService.upsertOrganizationAccount(orgUser, this.loggedInAccount.refreshToken)
            .subscribe((result: any) => {
                this.getOrgs();
            });
    }

    public cancelAddToExistingOrg() {
        this.showAddToExistingOrg = false;
    }

    public hasOrgRole(role: any): boolean {
        if (this.orgUnderEdit && this.adminAccount && this.orgUnderEdit.accounts) {
            const orgUser = this.orgUnderEdit.accounts.find(a => a.accountId === this.adminAccount.id);
            // tslint:disable-next-line:no-bitwise
            return !!(orgUser.userPermissions & role.flag);
        }
        return false;
    }

    public termsUpload(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            this.newOrgTerms.fileUploadFormData = formData;
        }
    }

    public saveNewOrgTerms() {
        this.organizationService.upsertOrganizationTerms(this.newOrgTerms, this.loggedInAccount.refreshToken)
            .subscribe((terms) => {
                this.utilityService.getUploadToken(this.loggedInAccount.refreshToken)
                    .subscribe((token) => {
                        if (token && token.id && token.token) {
                            this.utilityService.uploadFile(this.newOrgTerms.fileUploadFormData, 'orgTermsPdf',
                                token.id, token.token, terms.id, this.loggedInAccount.refreshToken)
                                .subscribe(() => {
                                    this.newOrgTerms = null;
                                    this.orgTermsUnderEdit = null;
                                    this.getOrgTerms();
                                });
                        }
                    });
            });
    }

    public getOrgTerms() {
        this.orgTerms = [];
        if (this.orgUnderEdit) {
            this.organizationService.getOrganizationTerms(this.orgUnderEdit.id, this.loggedInAccount.refreshToken)
                .subscribe((terms) => {
                    this.orgTerms = terms;
                });
        }
    }

    public setEditOrgTerms(terms: OrganizationTerms) {
        this.orgTermsUnderEdit = terms;
        console.log('editing terms:', this.orgTermsUnderEdit);
        this.newOrgTerms = null;
    }

    public cancelOrgTermsUpdate() {
        this.orgTermsUnderEdit = null;
        this.getOrgTerms();
    }

    public updateOrgTerms() {
        this.organizationService.upsertOrganizationTerms(this.orgTermsUnderEdit, this.loggedInAccount.refreshToken)
            .subscribe(terms => {
                this.getOrgTerms();
                this.cancelOrgTermsUpdate();
            });
    }

    public downloadTerms(terms: OrganizationTerms) {
        this.organizationService.downloadOrganizationTerms(terms, this.loggedInAccount.refreshToken)
            .subscribe(() => {
                console.log('downloaded terms:' + terms.azureBlobFilename);
            });
    }

    /// Flag handling:
    // tslint:disable:no-bitwise
    public get net10flag(): boolean { return !!(this.orgUnderEdit.paymentTerms & OrganizationPaymentTermsFlags.Net10); }
    public set net10flag(set: boolean) { this.orgPaymentTermsFlagChanged(OrganizationPaymentTermsFlags.Net10, set); }
    public get net15flag(): boolean { return !!(this.orgUnderEdit.paymentTerms & OrganizationPaymentTermsFlags.Net15); }
    public set net15flag(set: boolean) { this.orgPaymentTermsFlagChanged(OrganizationPaymentTermsFlags.Net15, set); }
    public get net30flag(): boolean { return !!(this.orgUnderEdit.paymentTerms & OrganizationPaymentTermsFlags.Net30); }
    public set net30flag(set: boolean) { this.orgPaymentTermsFlagChanged(OrganizationPaymentTermsFlags.Net30, set); }

    public orgPaymentTermsFlagChanged(flag: OrganizationPaymentTermsFlags, set: boolean) {
        if (set) { this.orgUnderEdit.paymentTerms |= flag; } else { this.orgUnderEdit.paymentTerms &= ~flag; }
    }


}
