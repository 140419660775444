export class ProjectScopeRateInfo {
  id: string;
  created: string;
  projectScopeId: string;
  createdByAccountId: string;
  audience: string;
  hoursMinEstimate: number;
  hoursMaxEstimate: number;
  hoursLimit: number;
  rateType: string;
  recurringStartDate: string;
  recurringEndDate: string;
  recurringDays: number;
  consultantAccountId: string;
  rate: number;
  internalNotes: string;
  version: string;
  projectScopeDeliverableIds: string[];
  consultantAgreedOnDate: string;
}
