export class ReducerStorage {

  public static persist<T>(key: string, payload: T): T {
    localStorage[key] = JSON.stringify(payload);
    return payload;
  }

  public static hydrate<T>(key: string): T {
    return localStorage[key] ? JSON.parse(localStorage[key]) : null;
  }

  public static clear(key: string) {
    localStorage.removeItem(key);
  }

}
