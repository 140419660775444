export class ProjectEstimation {
    id: string;
    projectType: string;
    projectCategory: string;
    projectTeam: string;
    projectDivision: string;
    projectDescriptionFormal: string;
    projectDescriptionCasual: string;
    primaryDeliverableType: string;
    primaryExampleVisible: boolean;
    primaryExampleFileUrl: string;
    primaryExampleFileDescription: string;
    projectFeeDisplayType: string;
    inFlexTeam: boolean;
    importanceRank: number;
    alternateTypes: string[];
    companyRoles: string[];
    companyTypes: string[];
    visible: boolean;
    caseStudies: ProjectEstimationCaseStudy[];
    options: ProjectEstimationOption[];
    questions: ProjectEstimationQuestion[];
}

export class ProjectEstimationCaseStudy {
    id: string;
    projectEstimationId: string;
    projectScopeId: string;
    visible: boolean;
    name: string;
    question: string;
    description: string;
    cost: number;
}

export class ProjectEstimationOption {
    id: string;
    projectEstimationId: string;
    name: string;
    visible: boolean;
    exampleDeliverableFileUrl: string;
    exampleDeliverableFileDescription: string;
    pricingType: string;
    pricingUnits: number;
    pricePerUnit: number;
    deliverableTypes: string[];
    items: ProjectEstimationOptionItem[];
}

export class ProjectEstimationOptionItem {
    id: string;
    projectEstimationOptionId: string;
    description: string;
}

export class ProjectEstimationQuestion {
    id: string;
    projectEstimationId: string;
    isDefault: boolean;
    questionType: string;
    question: string;
    companyTypes: string[];
}
