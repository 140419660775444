import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { RawListItemSearchResult } from '../_models';
import { ListService } from '../_services';

@Component({
    selector: 'async-text',
    template:
    `<textarea style="color:black;" disabled *ngIf="done" class="form-control" rows="5" id="textFileDisplay" [(ngModel)]="text"></textarea>`
})
export class AsyncTextComponent implements OnInit, OnChanges {

    @Input() item: RawListItemSearchResult;
    @Input() refreshToken: string;
    public text: any;
    public done = false;
    constructor(
        public listService: ListService
    ) { }

    public ngOnInit() {
        this.loadMedia();
    }

    public ngOnChanges() {
        this.loadMedia();
    }

    private loadMedia() {
        this.done = false;
        // console.log('onInit', this.item, this.refreshToken);
        this.listService.downloadRawFile(this.item, this.refreshToken).subscribe((blob) => {
            const reader = new FileReader();
            reader.addEventListener('loadend', (e) => {
                this.text = reader.result;
                // console.log(this.text);
                this.done = true;
              });
            reader.readAsText(blob);
        });
    }
}
