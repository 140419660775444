import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, ProjectSearchResult, Project, } from '../_models';
import { AccountService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { map, take } from 'rxjs/operators';

@Component({
    selector: 'projectAdmin',
    moduleId: module.id,
    templateUrl: 'project-admin.component.html',
    styleUrls: ['project-admin.component.css'],
})
export class ProjectAdminComponent implements AfterViewInit {

    public loggedInAccount: Account;
    public loading = false;
    public viewState = 'search';
    public loadedProjects: ProjectSearchResult[] = [];


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private location: Location,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
            }).subscribe();
        router.events.subscribe((val) => {
            this.handleRoute();
        });
    }

    public ngAfterViewInit() {
        this.handleRoute();
    }

    private handleRoute() {
        this.route.params.pipe(
            take(1),
            map((params) => {
                const action = params['action'];
                if (action === 'search') {
                    this.viewState = 'search';
                } else if (action === 'create') {
                    this.viewState = 'create';
                } else if (action === 'edit') {
                    this.viewState = 'create';
                } else if (action === 'userUploads') {
                    this.viewState = 'userUploads';
                }
                // console.log('action', action);
            })
        );

        this.route.params
            .pipe(
                take(1),
                map((params) => {
                    const projectId = params['projectId'];
                    const component = this;
                    if (projectId) {
                        this.viewState = projectId;
                        this.searchResultSelected({ projectId, organizationName: null, name: 'Loading...' });
                    }
                })
            );
        this.route.params.pipe(
            take(1),
            map((params) => {
                const uploadId = params['uploadId'];
                const component = this;
                if (uploadId) {
                    this.viewState = 'userUploads';
                }
            })
        );
    }

    public projectLoaded(projectInfoUpdate: any) {
        // grab the matching one in the collection:
        const tabAccount = this.loadedProjects.find(a => a.projectId === projectInfoUpdate.projectId);
        if (tabAccount) {
            tabAccount.projectName = projectInfoUpdate.projectName;
            if (projectInfoUpdate.organizationName) {
                tabAccount.organizationName = projectInfoUpdate.organizationName;
            }
        }
    }

    public searchResultSelected(result: any) {
        if (!this.loadedProjects.find(p => p.projectId === result.projectId)) {
            this.loadedProjects.push(result);
        }
        this.viewState = result.projectId;
        this.location.replaceState('/projectAdmin/edit/' + result.projectId);
    }

    public removeProject(searchResult: ProjectSearchResult) {
        const foundIndex = this.loadedProjects.indexOf(searchResult);
        if (foundIndex >= 0) {
            this.loadedProjects.splice(foundIndex, 1);
            if (foundIndex > 0) {
                const toLoad = this.loadedProjects[foundIndex - 1];
                this.searchResultSelected(toLoad);
            } else {
                this.handleTabClick('search');
            }
        }
    }

    private handleTabClick(viewState: string) {
        if (viewState === 'search') {
            this.location.replaceState('projectAdmin/search');
        } else if (viewState === 'create') {
            this.location.replaceState('projectAdmin/create');
        } else if (viewState === 'userUploads') {
            this.location.replaceState('projectAdmin/userUploads');
        } else {
            this.location.replaceState('/projectAdmin/edit/' + viewState);
        }
        this.viewState = viewState;
    }
}

