﻿import { Component, ElementRef, Output, EventEmitter } from '@angular/core';
import { Account, AccountSearchResults, SystemRole, AdminAccount } from '../_models';
import { AccountService } from '../_services';
import { JwtHelper, RolesHelper } from '../_helpers';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

@Component({
    moduleId: module.id,
    selector: 'account-search',
    templateUrl: 'account-search.component.html',
    styleUrls: ['account-search.component.css', 'corner-ribbons.css']
})
export class AccountSearchComponent {
    private static _imageBlobs: any[];
    @Output() public accountSelected = new EventEmitter<{}>();

    // users: User[] = [];
    public loggedInAccount: Account;
    public searchModel: any = {
        query: <string>null,
        filter: <string>null,
        numPerPage: <number>25,
        pageNumber: <number>1,
        orderBy: <string>null
    };
    public loading = false;
    public searchResults: AccountSearchResults;

    public showCreate = false;
    public Math: any;

    public sortOptions: any[] = [
        { name: 'Search Score', field: 'search.score()', dir: 'desc' },
        { name: 'Search Score', field: 'search.score()', dir: 'asc' },
        { name: 'Hours/Week', field: 'WeeklyHours', dir: 'asc' },
        { name: 'Hours/Week', field: 'WeeklyHours', dir: 'desc' },
        { name: 'Minimum Hourly Rate', field: 'MinRate', dir: 'asc' },
        { name: 'Minimum Hourly Rate', field: 'MinRate', dir: 'desc' },
        { name: 'Ideal Hourly Rate', field: 'IdealRate', dir: 'asc' },
        { name: 'Ideal Hourly Rate', field: 'IdealRate', dir: 'desc' },
        { name: 'First Name', field: 'FirstName', dir: 'asc' },
        { name: 'First Name', field: 'FirstName', dir: 'desc' },
        { name: 'Last Name', field: 'LastName', dir: 'asc' },
        { name: 'Last Name', field: 'LastName', dir: 'desc' },
        { name: 'Email Address', field: 'Email', dir: 'asc' },
        { name: 'Email Address', field: 'Email', dir: 'desc' }
    ];

    public selectedSortOptions: any[] = [];

    public selectedSortOption: any = null;

    public filteredFacets: {
        facetName: string,
        facetValue: string
    }[] = [];



    constructor(private accountService: AccountService, private store: Store<Account>,
        private router: Router) {
        this.selectedSortOptions.push(this.sortOptions[0]);
        this.Math = Math;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;

            }).subscribe();
        console.log('search constructor');
    }

    private getOrderByString(): string {
        let ret: string = null;
        if (this.selectedSortOptions && this.selectedSortOptions.length > 0) {
            ret = '';
            for (let i = 0; i < this.selectedSortOptions.length; i++) {
                const opt = this.selectedSortOptions[i];
                if (i > 0) {
                    ret += ', ';
                }
                ret += opt.field + ' ' + opt.dir;
            }
        }
        // console.log('orderby', ret);
        return ret;
    }

    public search(resetPageNumber: boolean, clearFilter: boolean) {
        if (resetPageNumber) {
            this.searchModel.pageNumber = 1;
        }
        if (clearFilter) {
            this.filteredFacets = [];
            this.searchModel.filter = null;
        }
        this.searchModel.orderBy = this.getOrderByString();
        this.loading = true;
        this.accountService.searchAccounts(this.searchModel, this.loggedInAccount.refreshToken)
            .subscribe(results => {
                this.searchResults = results;
                this.loading = false;
                this.searchResults.results.forEach(result => {
                    this.setProfileImageForResult(result);
                });
            });
    }

    public filterBy(facet: any, value: any) {
        const foundFilter = this.filteredFacets.find(f => f.facetName === facet.name && f.facetValue === value);
        if (!foundFilter) {
            this.filteredFacets.push({ facetName: facet.name, facetValue: value });
        }
        this.setFilter();
    }

    public isFiltered(facet: any, value: any) {
        return !!this.filteredFacets.find(f => f.facetName === facet.name && f.facetValue === value);
    }

    public showFacet(facet: any): boolean {
        let ret = facet.results.length > 0;
        if (ret && (facet.name === 'MinRate' || facet.name === 'IdealRate')) {
            // get the totals to determine:
            let total = 0;
            facet.results.forEach(result => {
                total += result.value;
            });
            ret = total > 0;
        }
        return ret;
    }

    private setFilter() {
        this.searchModel.filter = '';
        this.filteredFacets.forEach(facet => {
            if (this.searchModel.filter && this.searchModel.filter.length > 0) {
                this.searchModel.filter += ' and ';
            }
            if (facet.facetName === 'Languages') {
                // different search syntax for collections...Languages/any(l: l eq 'Vietnamese')
                this.searchModel.filter += facet.facetName + '/any(l: l eq \'' + facet.facetValue + '\')';
            } else if (facet.facetName === 'LastIndustry' || facet.facetName === 'CountryCode' ||
                facet.facetName === 'InternalConsultantStatus' || facet.facetName === 'ExternalConsultantStatus') {
                // this is a string, so different syntax
                this.searchModel.filter += facet.facetName + ' eq \'' + facet.facetValue + '\'';
            } else if (facet.facetName === 'MinRate' || facet.facetName === 'IdealRate' || facet.facetName === 'WeeklyHours') {
                // this is a known range... split it up...
                let min: number = null;
                let max: number = null;
                if (facet.facetValue === 'Up to 5') { // Weekly
                    max = 5;
                } else if (facet.facetValue === '30+') { // Weekly
                    min = 30;
                } else if (facet.facetValue === 'Up to 25') { // Rate
                    max = 25;
                } else if (facet.facetValue === '175+') { // Rate
                    min = 175;
                } else {
                    // split on ' - '
                    const splitRate = facet.facetValue.split(' - ');
                    if (splitRate.length === 2) {
                        min = Number(splitRate[0]);
                        max = Number(splitRate[1]);
                    }
                }
                const parts: string[] = [];
                if (min !== null) {
                    parts.push(facet.facetName + ' ge ' + min);
                }
                if (max !== null) {
                    parts.push(facet.facetName + ' lt ' + max);
                }
                if (parts.length > 0) {
                    this.searchModel.filter += parts[0];
                    if (parts.length === 2) {
                        this.searchModel.filter += ' and ';
                        this.searchModel.filter += parts[1];
                    }
                }
            } else {
                this.searchModel.filter += facet.facetName + ' eq ' + facet.facetValue;
            }
        });
        // console.log('filterby', this.searchModel.filter);
        this.search(true, false);
        window.scrollTo(0, 0);
    }

    public clearFilter(facet: any, value: any) {
        const foundFilterIndex = this.filteredFacets.findIndex(f => f.facetName === facet.name && f.facetValue === value);
        if (foundFilterIndex >= 0) {
            this.filteredFacets.splice(foundFilterIndex, 1);
        }
        this.setFilter();
        this.search(true, false);
    }

    public nextPage() {
        this.searchModel.pageNumber += 1;
        this.search(false, false);
    }

    public prevPage() {
        this.searchModel.pageNumber -= 1;
        this.search(false, false);
    }

    public goToPage(page: number) {
        this.searchModel.pageNumber = page;
        this.search(false, false);
    }

    public select(id): void {
        // find in the list:
        const selectedAccount = this.searchResults.results.filter((r) => r.accountId === id)[0];
        // this.router.navigate(['./accountAdmin/manage/' + id]);
        if (this.accountSelected) {
            this.accountSelected.emit({
                accountId: selectedAccount.accountId,
                name: selectedAccount.firstName + ' ' + selectedAccount.lastName
            });
        }
        window.scrollTo(0, 0);
    }

    public toggleShowCreate() {
        this.showCreate = !this.showCreate;
    }

    public getBeforePageRanges(): number[] {
        const ret: number[] = [];
        // there should be 10 total numbers at the bottom (or less), 4 to the left, max
        let numToRight = this.searchResults.totalPages - this.searchResults.page;
        if (numToRight > 5) {
            numToRight = 5;
        }
        for (let i = this.searchResults.page - 1; i > 0; i--) {
            if (ret.length < (9 - numToRight)) {
                ret.push(i);
            }
        }

        ret.sort((a, b) => (a - b));
        return ret;
    }

    public getAfterPageRanges(): number[] {
        const befores = this.getBeforePageRanges();
        const ret: number[] = [];
        // there should be 10 total numbers at the bottom (or less), 5 to the right, max
        for (let i = this.searchResults.page + 1; i <= this.searchResults.totalPages; i++) {
            if (befores.length + 1 + ret.length < 10) {
                ret.push(i);
            }
        }
        return ret;
    }


    private get imageBlobs(): any[] {
        return AccountSearchComponent._imageBlobs;
    }
    private set imageBlobs(blobs: any[]) {
        AccountSearchComponent._imageBlobs = blobs;
    }
    private storeImageBlob(filename: string, blobContent: any): any {
        if (!this.imageBlobs) {
            this.imageBlobs = [];
        }
        const foundBlob = this.getImageBlob(filename);
        if (!foundBlob) {
            const obj = { filename, blobContent };
            this.imageBlobs.push(obj);
            if (this.imageBlobs.length > 50) {
                this.imageBlobs.splice(0, 1); // FIFO
            }
        } else {
            foundBlob.blobContent = blobContent;
        }
    }
    private getImageBlob(filename: string) {
        if (this.imageBlobs) {
            const foundBlob = this.imageBlobs.find(blob => blob.filename === filename);
            if (foundBlob) {
                return foundBlob.blobContent;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private loadAndStoreImageblobForResult(searchResult: any) {
        if (searchResult.profileImageFile) {
            this.accountService.downloadAccountImage(searchResult.profileImageFile, this.loggedInAccount.refreshToken)
                .subscribe(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.storeImageBlob(searchResult.profileImageFile, reader.result);
                        searchResult.profileImageBlob = reader.result;
                    };

                    if (blob) {
                        reader.readAsDataURL(blob);
                    }
                });
        }
    }

    public setProfileImageForResult(searchResult: any) {
        if (searchResult.profileImageFile && !searchResult.profileImageBlob) {
            const foundImage = this.getImageBlob(searchResult.profileImageFile);
            if (foundImage) {
                searchResult.profileImageBlob = foundImage;
            } else {
                this.loadAndStoreImageblobForResult(searchResult);
            }
        }
    }

    public sortOptionSelected(optionName) {
        this.selectedSortOption = this.sortOptions.find(o => o.name === optionName);
    }

    public sortOptionOk(option): boolean {
        const found = this.selectedSortOptions.find(o => o.field === option.field);
        return !found;
    }

    public addSortOption() {
        this.selectedSortOptions.push(this.selectedSortOption);
        this.selectedSortOption = null;
    }

    public removeSortOption(option) {
        this.selectedSortOptions.splice(this.selectedSortOptions.indexOf(option), 1);
    }

    public sortOptionEquals(optionOne, optionTwo): boolean {
        return optionOne === optionTwo;
    }
}
