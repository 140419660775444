export class ProjectScopeDeliverable {
  id: string;
  created: string;
  projectScopeId: string;
  createdByAccountId: string;
  name: string;
  description: string;
  specialistRequired: boolean;
  dueDate: string;
  consultantDueDate: string;
  internalNotes: string;
  tags: string[];
  version: string;
  organizationTermsId: string;
  dueDateDaysFromAcceptance: number;
  onHold: boolean;
}
