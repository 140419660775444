export class AgentConversationSearchResult {

    public created: string;
    public chatChannelId: string;
    public respondedByAgentAccountId: string;
    public responded: string;
    public disconnected: string;
    public clientAccountId: string;
    public clientName: string;
    public agentName: string;
    public chatChannelAccessTokenId: string;
}