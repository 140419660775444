import { Injectable } from '@angular/core';


import { AuthenticationService } from './authentication.service';
import { Organization, TimeZoneInfo } from '../_models';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable()
export class UtilityService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }

    public getTagsByType(tagType: string, refreshToken: string): Observable<string[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/tags/' + tagType, options)
            .pipe(
                take(1),
                map((response: string[]) => response)
            );
    }

    public getAllTimeZones(refreshToken: string): Observable<TimeZoneInfo[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'timeZones', options)
            .pipe(
                take(1),
                map((response: TimeZoneInfo[]) => response)
            );
    }

    public getUploadToken(refreshToken: string): Observable<{ id: string, token: string }> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/uploads/tokens', options)
            .pipe(
                take(1),
                map((response: { id: string, token: string }) => response)
            );
    }

    public uploadFile(fileContent: any, type: string, tokenId: string, uploadToken: string, baseId: string, refreshToken: string): Observable<any> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get account from api
        return this.http.post(CONFIG.apiEndpointBase + `admins/uploads/${type}/${tokenId}/${uploadToken}/${baseId}`, fileContent, options)
            .pipe(
                take(1),
                map((response: any) => response)
            );
    }
}
