export class ChatConversation {
    public id: string;
    public accessToken: string;
    public responseDate: string;
    public disconnectDate: string;
    public createDate: string;
    public clientName: string;
    public clientEmailAddress: string;
    public clientAccountId: string;
    public agentName: string;
    public clientCompanyNames: string[];
    public chatChannelId: string;
    public smsNumberId: string;
    public clientAvatar: any;
    public agentAvatar: any;
    public clientAvatarFilename: string;
    public agentAvatarFilename: string;
    public lastTimestamp: number;
    public hasUnread: boolean;
    public adminLastTypedDate: number = null;
    public adminIdleCheckTime: number = null;
    public adminIsIdle = false;
    public adminIsTyping = false;

    public clientDisconnected = true;
    public clientIdle = false;
    public clientTyping: boolean;

    public constructor(c: ChatConversation) {

        this.id = c.id;
        this.accessToken = c.accessToken;
        this.responseDate = c.responseDate;
        this.disconnectDate = c.disconnectDate;
        this.createDate = c.createDate;
        this.clientName = c.clientName;
        this.clientEmailAddress = c.clientEmailAddress;
        this.clientAccountId = c.clientAccountId;
        this.agentName = c.agentName;
        this.clientCompanyNames = c.clientCompanyNames;
        this.chatChannelId = c.chatChannelId;
        this.smsNumberId = c.smsNumberId;
        this.clientAvatar = c.clientAvatar;
        this.agentAvatar = c.agentAvatar;
        this.clientAvatarFilename = c.clientAvatarFilename;
        this.agentAvatarFilename = c.agentAvatarFilename;
        this.lastTimestamp = c.lastTimestamp;
        this.hasUnread = c.hasUnread;

    }

    public get companyNameString(): string {
        let ret = '';
        if (this.clientCompanyNames) {
            for (const name of this.clientCompanyNames) {
                ret += ret.length > 0 ? ', ' : '';
                ret += name;
            }
        }
        return ret;
    }

    public get localCreateDate() {
        // console.log('start local create date', this.createDate);
        const utcDate = new Date(this.createDate);
        // console.log('utc date', utcDate);
        const localOffset = utcDate.getTimezoneOffset() * 60000;
        // console.log('local offset', localOffset);
        const localTime = utcDate.getTime();
        // console.log('localTime', localTime);
        // console.log('localtime - localOffset', localTime - localOffset);
        // console.log('translates to :', new Date(localTime - localOffset))
        return localTime - localOffset;
    }
}