export class OrganizationTerms {
    public id: string;
    public created: string;
    public createdBy: string;
    public organizationId: string;
    public type: string;
    public name: string;
    public description: string;
    public azureBlobFilename: string;
    public active: boolean;
    public fileUploadFormData: FormData;
}
