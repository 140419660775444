export class ProjectTimeTracking {
    public id: string;
    public created: string;
    public accountId: string;
    public projectScopeDeliverableId: string;
    public billableHours: number;
    public nonBillableHours: number;
    public dateWorked: string;
    public description: string;
    public approvedByAccountId: string;
    public approvedOn: string;
    public apTimeTrackingInvoiceId: string;
    public invoiceAdjustmentAmount: number;

    public uiChecked: boolean;
}