export class AccountMessage {
    id: string;
    accountId: string;
    created: string;
    opened: string;
    archived: boolean;
    flags: number;
    sender: string;
    subject: string;
    message: string;
    messageType: string;
}