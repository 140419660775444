/* tslint:disable:component-class-suffix */
import { Component } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';


@Component({
  moduleId: module.id,
  selector: 'flexteam-overview-report',
  templateUrl: 'flexteam-overview-report.component.html'
})
export class FlexTeamOverviewReportComponent {
  public reportData: any[];
  public loggedInAccount: Account;
  public loading = false;


  constructor(
    private accountService: AccountService,
    private reportsService: ReportsService,
    private store: Store<Account>) {
    this.loading = true;
    this.store.select('loggedInAccount')
      .select((account: Account) => {
        this.loggedInAccount = account;
        this.loading = false;
        this.getReportData();
      }).subscribe();
  }

  private getReportData() {
    this.loading = true;
    this.reportsService.getReport('flexteamOverview', this.loggedInAccount.refreshToken)
      .subscribe(reportData => {
        if (reportData) {
          this.reportData = reportData;
        }
        this.loading = false;
      });
  }
}
