﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import {
    StripeBankAccount, StripeCard, PaymentAccountWallet,
    PaymentTransaction, ExternalPaymentAccount
} from '../_models';
import { CONFIG } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

@Injectable()
export class PaymentService {
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }


    public getAccountWallet(accountId: string, refreshToken: string):
        Observable<PaymentAccountWallet> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get wallet from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/wallets/accounts/' + accountId,
            options)
            .pipe(
                take(1),
                map((response: any) => new PaymentAccountWallet(response))
            );
    }

    public getProjectWallet(projectId: string, refreshToken: string):
        Observable<PaymentAccountWallet> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get wallet from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/wallets/projects/' + projectId,
            options)
            .pipe(
                take(1),
                map((response: any) => new PaymentAccountWallet(response))
            );
    }

    public getPaymentTransactionHistory(accountId: string, refreshToken: string):
        Observable<PaymentTransaction[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get transactions from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/transactions/accounts/' + accountId,
            options)
            .pipe(
                take(1),
                map((response: PaymentTransaction[]) => response)
            );
    }

    public getPaymentTransactionsForProjectInvoice(projectInvoiceId: string, refreshToken: string):
        Observable<PaymentTransaction[]> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get transactions from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/transactions/projects/invoices/' + projectInvoiceId,
            options)
            .pipe(
                take(1),
                map((response: PaymentTransaction[]) => response)
            );
    }

    public chargePaymentAccount(paymentAccountId: string, amount: number,
        description: string, projectInvoiceId: string,
        externalPaymentAccountDescription: string,
        externalPaymentAccountType: string,
        refreshToken: string):
        Observable<PaymentTransaction> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // charge the account
        const body = {
            paymentAccountId,
            amount,
            description,
            currency: 'USD',
            projectInvoiceId,
            externalPaymentAccountDescription,
            externalPaymentAccountType
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/charges', body, options)
            .pipe(
                take(1),
                map((response: PaymentTransaction) => new PaymentTransaction(response))
            );
    }

    public getStripeCardByPaymentAccountId(paymentAccountId: string, refreshToken: string):
        Observable<StripeCard> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get transactions from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/wallets/stripeCards/' + paymentAccountId,
            options)
            .pipe(
                take(1),
                map((response: StripeCard) => response)
            );
    }

    public getExternalPaymentAccountByPaymentAccountId(paymentAccountId: string, refreshToken: string):
        Observable<ExternalPaymentAccount> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // get transactions from api
        return this.http.get(CONFIG.apiEndpointBase + 'admins/wallets/externalPaymentAccounts/' + paymentAccountId,
            options)
            .pipe(
                take(1),
                map((response: ExternalPaymentAccount) => response)
            );
    }

    public updateExternalPaymentTransaction(
        paymentTransactionId: string,
        paid: boolean,
        cleared: boolean,
        externalPaymentAccountName: string,
        refreshToken: string):
        Observable<void> {
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + refreshToken });
        const options = { headers: headers };
        // charge the account
        const body = {
            paymentTransactionId,
            paid,
            cleared,
            externalPaymentAccountName
        };
        return this.http.post(CONFIG.apiEndpointBase + 'admins/charges/externalTransactionUpdates', body, options)
            .pipe(
                take(1),
                map((response: any) => { })
            );
    }

}
