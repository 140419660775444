export class ProjectScope {
    id: string;
    created: string;
    projectId: string;
    createdByAccountId: string;
    name: string;
    description: string;
    version: string;
    agreedByAccountId: string;
    agreedOnDate: string;
    uiIsNew: boolean;
}
