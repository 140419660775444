export class ProjectEstimationAbstract {
    id: string;
    projectType: string;
    projectCategory: string;
    projectTeam: string;
    projectDivision: string;
    inFlexTeam: boolean;
    importanceRank: number;
    visible: boolean;
}
