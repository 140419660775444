import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, RawListItemSearchResult, ProjectSearchResult } from '../_models';
import { AccountService, ProjectService, ListService } from '../_services';
import { RolesHelper, JwtHelper } from '../_helpers';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import { map, take } from 'rxjs/operators';

@Component({
    selector: 'user-uploads',
    moduleId: module.id,
    templateUrl: 'user-uploads.component.html',
    styleUrls: ['user-uploads.component.css'],
})
export class UserUploadsComponent {
    public ctas: string[] = [];
    public loggedInAccount: Account;
    private searchResults: RawListItemSearchResult[];
    public unprocessedUrgent: RawListItemSearchResult[];
    public unprocessedNormal: RawListItemSearchResult[];
    public processedCTAResponses: RawListItemSearchResult[];
    public processedNoCTAResponse: RawListItemSearchResult[];
    public processedSuggested: RawListItemSearchResult[];
    public processedProjectAssigned: RawListItemSearchResult[];
    public processedArchived: RawListItemSearchResult[];
    public processedSaveForLater: RawListItemSearchResult[];

    public selectedItem: RawListItemSearchResult;
    public selectedItemAlert: string = null;
    public allProjects: ProjectSearchResult[] = [];
    public allProjectsOptions: IMultiSelectOption[] = [];
    public sortProp = 'projectName';
    public sortDir = 'asc';

    public selectedProjectIds = [];
    public newProjectShellName: string;
    public handledRoute = false;

    public showProjectPicker = false;
    public showShellForm = false;

    public singleSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        dynamicTitleMaxItems: 1,
        displayAllSelectedText: false,
        selectionLimit: 1,
        autoUnselect: true,
        showUncheckAll: true,
        closeOnSelect: true
    };

    // Text configuration
    public multiSelectTexts: IMultiSelectTexts = {
        checkAll: 'Select all',
        uncheckAll: 'Unselect all',
        checked: 'item selected',
        checkedPlural: 'items selected',
        searchPlaceholder: 'Find',
        defaultTitle: '- - - - - - - - - - - - - - - - - Select - - - - - - - - - - - - - - - - - ',
        allSelected: 'All selected',
    };

    constructor(private listService: ListService,
        private projectService: ProjectService,
        private store: Store<Account>,
        public sanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.setupCTAs();
                this.search();
                this.searchForProjects();
            }).subscribe();
    }

    private loadUploadIfPassed() {
        this.route.params.pipe(
            take(1),
            map((params) => {
                const uploadId = params['uploadId'];
                const component = this;
                if (uploadId && !this.handledRoute) {
                    console.log('uploadId passed:', uploadId);
                    // find it and select it:
                    if (this.searchResults) {
                        const filtered = this.searchResults.filter((result) => {
                            if (result.listItemRawId === uploadId) {
                                return true;
                            }
                        });
                        if (filtered.length === 1) {
                            this.loadItem(filtered[0]);
                        }
                    }
                }
                this.handledRoute = true;
            })
        );
    }

    public search() {
        this.listService.searchForRawListItems(this.loggedInAccount.refreshToken).subscribe((results) => {
            this.searchResults = [];
            this.searchResults = results;
            this.sortIntoBuckets();
            this.loadUploadIfPassed();
        });
    }

    private sortIntoBuckets() {
        this.unprocessedUrgent = [];
        this.unprocessedNormal = [];
        this.processedCTAResponses = [];
        this.processedNoCTAResponse = [];
        this.processedSuggested = [];
        this.processedProjectAssigned = [];
        this.processedArchived = [];
        this.processedSaveForLater = [];

        // sort them into the proper buckets:
        this.searchResults.forEach(result => {
            if (!result.listItemNoteId) {
                if (result.urgent) {
                    this.unprocessedUrgent.push(result);
                } else {
                    this.unprocessedNormal.push(result);
                }
            } else {
                if (result.listItemDeleted) {
                    this.processedArchived.push(result);
                } else if (result.listItemType === 'MovedToProjects') {
                    if (result.projectId) {
                        this.processedProjectAssigned.push(result);
                    } else {
                        this.processedCTAResponses.push(result);
                    }
                } else if (result.listItemType === 'Later') {
                    if (result.projectId) {
                        this.processedProjectAssigned.push(result);
                    } else {
                        this.processedCTAResponses.push(result);
                    }
                } else if (result.listItemType === 'ForReview') {
                    this.processedNoCTAResponse.push(result);
                } else if (result.listItemType === 'Suggested') {
                    this.processedSuggested.push(result);
                }
            }
        });
    }

    public loadItem(item: RawListItemSearchResult) {
        this.selectedItemAlert = null;
        // console.log('loading', item);
        this.showProjectPicker = this.showShellForm = false;
        this.selectedProjectIds = [];
        this.newProjectShellName = null;
        this.selectedItem = item;
        if (item.projectId) {
            this.selectedProjectIds.push(item.projectId);
            this.showProjectPicker = true;
        }
    }

    public get selectedItemType(): string {
        if (this.selectedItem) {
            if (this.selectedItem.azureBlobFilename.endsWith('.jpg')) {
                return 'an image';
            } else if (this.selectedItem.azureBlobFilename.endsWith('m4a')) {
                return 'an audio file';
            } else if (this.selectedItem.azureBlobFilename.endsWith('txt')) {
                return 'a text file';
            }
        }
        return '????';
    }

    public sort() {
        this.allProjects.sort((a, b) => {
            if (!a[this.sortProp] && !b[this.sortProp]) {
                return 0;
            }

            if (!a[this.sortProp] && b[this.sortProp]) {
                return -1;
            }

            if (a[this.sortProp] && !b[this.sortProp]) {
                return 1;
            }

            return a[this.sortProp].localeCompare(b[this.sortProp]);
        });

        if (this.sortDir === 'desc') {
            this.allProjects.reverse();
        }
    }

    private searchForProjects() {
        this.projectService.searchForProjects(null, null, this.loggedInAccount.refreshToken)
            .subscribe((projects: ProjectSearchResult[]) => {
                this.allProjects = projects;
                this.sort();
                this.setProjectOptions();
            });
    }

    private setProjectOptions() {
        if (this.allProjects && this.allProjects.length > 0) {
            this.allProjects.forEach(p => {
                // console.log('pushing project', p.projectName, p.projectId);
                this.allProjectsOptions.push({
                    id: p.projectId,
                    name: p.projectName
                });
            });
        }
    }

    private setupCTAs() {
        this.ctas = [
            'Contact Me',
            'Start Project'
        ];
    }

    public canRespond(): boolean {
        let ret = false;
        if (this.selectedItem.callToAction === 'Start Project') {
            if (this.selectedProjectIds && this.selectedProjectIds.length === 1) {
                // a project is selected. That's good. Now, make sure the project is ready
                // for the client. If not, alert!
                const foundProject = this.allProjects.find(p => p.projectId === this.selectedProjectIds[0]);
                if (foundProject && foundProject.visible && foundProject.clientEmail && foundProject.organizationId
                    && foundProject.projectStatus === 'Scoped - Waiting For Customer Approval') {
                    ret = true;
                }
            }
        } else {
            return true;
        }

        return ret;
    }

    public respond() {
        let projectId = null;
        this.selectedItemAlert = null;
        if (this.selectedProjectIds && this.selectedProjectIds.length === 1) {
            projectId = this.selectedProjectIds[0];
        }
        const i = this.selectedItem;
        console.log('responding to ', this.selectedItem);
        if (this.canRespond()) {
            this.listService.respondToRawListItem(i.listItemId, i.listItemRawId, i.listItemBody,
                i.listItemNote, i.callToAction, projectId, i.listItemRawAccountId, this.newProjectShellName,
                this.loggedInAccount.refreshToken).subscribe((resp) => {
                    // now grab the refreshed  item:
                    this.listService.getRawListItem(i.listItemRawId, this.loggedInAccount.refreshToken).subscribe((respUpdate) => {
                        const index = this.searchResults.indexOf(this.selectedItem);
                        if (index !== -1) {
                            this.searchResults[index] = respUpdate;
                        }
                        if (this.newProjectShellName) {
                            // grab the projects again:
                            this.projectService.searchForProjects(null, null, this.loggedInAccount.refreshToken)
                                .subscribe((projects: ProjectSearchResult[]) => {
                                    this.allProjects = projects;
                                    this.sort();
                                    this.setProjectOptions();

                                    this.loadItem(respUpdate);
                                    this.sortIntoBuckets();
                                    alert('Saved');
                                });
                        } else {
                            this.loadItem(respUpdate);
                            this.sortIntoBuckets();
                            alert('Saved');
                        }
                    });
                });
        } else {
            this.selectedItemAlert = `You cannot respond to this because the project isn't properly configured. It must be visible,
            be in the status "Scoped - Waiting For Customer Approval," 
            and must be assigned to both an Organization and Client account. Please correct this and try again.`;
        }
    }
}
