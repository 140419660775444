/* tslint:disable:component-class-suffix */
import { Component, Input } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';
import { ReportsCommon } from './reports-common';


@Component({
    moduleId: module.id,
    selector: 'consultant-overview-report',
    templateUrl: 'consultant-overview-report.component.html',
    styleUrls: ['reports-common.scss']
})
export class ConsultantOverviewReportComponent {
    @Input() containerHeight = 500;
    public reportData: any[];
    public loggedInAccount: Account;
    public loading = false;


    private _displayed: string;
    public get displayed(): string { return this._displayed; }
    @Input() public set displayed(v: string) { if (v && !this._displayed) { this.getReportData(); } this._displayed = v; }


    public childColumns: {
        displayName: string, propertyName: string,
        sortPrimerFunc?: (valIn) => any, dispType: string,
        fixedDec?: number, styleOverride?: any, clickable?: boolean
    }[] = [
            { displayName: 'Avail?', propertyName: 'isAvailable', dispType: 'boolean', styleOverride: { 'max-width': '50px' } },
            { displayName: 'Last Name', propertyName: 'consultantLastName', dispType: 'string', clickable: true },
            { displayName: 'First Name', propertyName: 'consultantFirstName', dispType: 'string', clickable: true },
            {
                displayName: 'Email', propertyName: 'consultantEmail', clickable: true,
                dispType: 'string', styleOverride: { 'min-width': '300px' }
            },
            { displayName: 'Internal Status', propertyName: 'internalStatus', dispType: 'string' },
            { displayName: 'External Status', propertyName: 'externalStatus', dispType: 'string' },
            { displayName: 'Consultant Profile Created', propertyName: 'accountConsultantProfileCreated', dispType: 'date' },
            { displayName: 'Terms Agreed On', propertyName: 'accountConsultantProfileLegalAgreedAt', dispType: 'date' },
            { displayName: 'Last Industry', propertyName: 'lastIndustry', dispType: 'string' },
            { displayName: 'Projects', propertyName: 'totalProjects', dispType: 'number' },
            { displayName: 'PM Projects', propertyName: 'totalPMProjects', dispType: 'number' },
            { displayName: 'Last Rate Agreed On', propertyName: 'lastRateAgreedOn', dispType: 'date' },
            { displayName: '$ Paid', propertyName: 'totalPaid', dispType: 'money' },
            { displayName: 'Avg $ Paid / Project', propertyName: 'avgPaidPerProject', dispType: 'money' },
            { displayName: 'Total Billable Hours', propertyName: 'totalBillableHours', dispType: 'number' },
            { displayName: 'Avg Hours / Project', propertyName: 'avgHoursPerProject', dispType: 'fixed', fixedDec: 1 },
        ];

    public initialSorts: { propertyName: string, reverse: boolean }[] = [];
    public groupBy = ['internalStatus', 'externalStatus'];
    public sumBy = [];
    public groupedData: any[] = [];
    public selectedGroup: any;

    constructor(
        private accountService: AccountService,
        private reportsService: ReportsService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                // this.getReportData();
            }).subscribe();
    }

    public get childTableHeight(): number {
        return this.containerHeight - 220;
    }

    private getReportData() {
        if (!this.reportData || this.reportData.length === 0) {
            this.loading = true;
            this.reportsService.getReport('consultantOverview', this.loggedInAccount.refreshToken)
                .subscribe(reportData => {
                    if (reportData) {
                        this.initialSorts = [
                            { propertyName: 'consultantLastName', reverse: false },
                            { propertyName: 'consultantFirstName', reverse: false },
                            { propertyName: 'consultantEmail', reverse: false },
                        ];
                        this.reportData = reportData;
                        this.setGroupedData();
                    }
                    this.loading = false;
                });
        }
    }

    public setGroupedData() {
        this.groupedData = ReportsCommon.group(this.reportData, this.groupBy, this.sumBy);
        this.groupedData.sort(ReportsCommon.sort([
            { propertyName: 'projectStatus', primerFunction: null, reverse: false },
        ]));
    }

    public cellClick(d: { rowData: any, propertyName: string }) {
        window.open('/accountAdmin/manage/' + d.rowData.consultantAccountId, '_blank');
    }
}



