﻿export { Account } from './account';
export { AccountMessage } from './accountMessage';
export { AccountSearchResults } from './accountSearchResults';
export { SystemRole } from './systemRole';
export { AdminAccount } from './adminAccount';
export { StripeBankAccount } from './stripeBankAccount';
export { StripeCard } from './stripeCard';
export { PaymentAccountWallet } from './paymentAccountWallet';
export { PaymentTransaction } from './paymentTransaction';
export { ChatConversation } from './chatConversation';
export { Organization, OrganizationPaymentTermsFlags } from './organization';
export { Project, ProjectPermission, ProjectSearchResult } from './project';
export { ProjectFileTemplate } from './projectFileTemplate';
export { ProjectScope } from './projectScope';
export { ProjectScopeDeliverable } from './projectScopeDeliverable';
export { ProjectScopeRateInfo } from './projectScopeRateInfo';
export { ProjectFile } from './projectFile';
export { OrganizationAccount } from './organizationAccount';
export { TimeZoneInfo } from './timeZoneInfo';
export { AgentConversationSearchResult } from './agentConversationSearchResult';
export { AccountNote } from './accountNote';
export { ProjectEstimationAbstract } from './projectEstimationAbstract';
export { RawListItemSearchResult } from './rawListItemSearchResult';
export { ConsultantProfile, ConsultantProfileFlags } from './consultantProfile';
export { ProjectEstimation, ProjectEstimationCaseStudy, ProjectEstimationOption, ProjectEstimationOptionItem, ProjectEstimationQuestion } from './projectEstimation';
export { ProjectEstimationSiteSection, ProjectEstimationSiteSectionGroup, ProjectEstimationSiteSectionGroupAssignment } from './projectEstimationSiteSection';
export { ProjectInvoice, ProjectInvoiceLineItem } from './projectInvoice';
export { ProjectTimeTracking } from './projectTimeTracking';
export { ExternalPaymentAccount } from './externalPaymentAccount';
export { APTimeTrackingInvoice } from './apTimeTrackingInvoice';
export { OrganizationTerms } from './organizationTerms';
export { Answer, Question, Questionnaire, QuestionnaireSection } from './questionnaire';
export { AppConfig } from './app.config.interface'

export enum ProjectFilePermissionFlags {
  /// No Permission
  None = 0,
  /// Read Permission
  Read = 1,
  /// Edit Permission
  Edit = 2,
  /// Delete Permission
  Delete = 4,
  /// Shortcut for full permissions
  FullPermissions = Read | Edit | Delete,
  /// Shortcut for Read/Write permissions
  ReadWrite = Read | Edit,
  /// Shortcut for Read Only permissions
  ReadOnly = Read
}

export enum OrganizationAccountPermissionFlags {
  /// No Permission
  None = 0,
  /// User permission
  User = 1,
  /// Invitor permission
  Invitor = 2,
  /// Admin permission
  Admin = 4,
  /// Billing Admin permission
  BillingAdmin = 8
}