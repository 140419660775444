import { Component } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
export interface AlertModel {
  title:string;
  message:string;
}
@Component({  selector: 'alert',
  template: `<div class="modal-content">
                   <div class="modal-header">
                     <button type="button" class="close" (click)="closeModal()" >&times;</button>
                     <h4 class="modal-title">{{title || 'Confirm'}}</h4>
                   </div>
                   <div class="modal-body">
                     <p>{{message || 'Are you sure?'}}</p>
                   </div>
                   <div class="modal-footer">
                     <button type="button" class="btn btn-primary" (click)="confirm()">OK</button>
                   </div>
                 </div>`
})
export class AlertModalComponent extends DialogComponent<AlertModel, boolean> {
  public title: string;
  public message: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }
  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.result = true;
    this.close();
  }
  closeModal() {
    this.close();
  }
}
