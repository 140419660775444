import { Component, Input, ViewChild, ChangeDetectorRef, ElementRef, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Account, AdminAccount, Project, ProjectFile, ProjectScope, ProjectEstimationAbstract, ProjectFilePermissionFlags,
    ProjectScopeDeliverable, ProjectScopeRateInfo, Organization, ProjectPermission, OrganizationTerms, ProjectFileTemplate
} from '../_models';
import { AccountService, ProjectService, OrganizationService, UtilityService } from '../_services';

import { Store } from '@ngrx/store';
import { CustomInputModel, InputModalComponent } from '../uiHelpers';
import { IMyOptions, IMyDateModel, MyDatePicker, IMyDate } from 'mydatepicker';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import * as FileSaver from 'file-saver';
import { DialogService } from 'ng2-bootstrap-modal';
import * as moment from 'moment';
import { CONFIG } from '../../environments/environment';
import { Observable } from 'rxjs';

@Component({
    selector: 'project-editor',
    moduleId: module.id,
    templateUrl: 'project-editor.component.html',
    styleUrls: ['project-editor.component.css'],
})
export class ProjectEditorComponent implements OnDestroy {
    @ViewChild('requestDate') requestDatePicker: MyDatePicker;
    @ViewChild('clientDueDate') clientDueDatePicker: MyDatePicker;
    @ViewChild('consultantDueDate') consultantDueDatePicker: MyDatePicker;
    @ViewChild('recurringEndDate') recurringEndDatePicker: MyDatePicker;
    @ViewChild('fileDescription') fileDescriptionBox: ElementRef;
    @ViewChild('fileName') fileNameBox: ElementRef;

    private _selectedProjectId: string = null;
    @Output() public projectLoaded = new EventEmitter<{}>();
    @Input() public set selectedProjectId(val: string) {
        this._selectedProjectId = val;
        this.setProject(val);
    }
    public get selectedAccountId(): string {
        return this._selectedProjectId;
    }

    private _editing = 'projectSettings';

    public projectIsDirty = false;
    public scopeItemIsDirty = false;
    public deliverableIsDirty = false;
    public rateIsDirty = false;
    public showConfEmail = false;
    public destroying = false;

    public loggedInAccount: Account;
    public loading = false;
    public project: Project;
    public projectFiles: ProjectFile[] = null;
    public scopes: ProjectScope[] = [];
    public deliverables: ProjectScopeDeliverable[] = [];
    public rates: ProjectScopeRateInfo[] = [];
    public estimationAbstracts: ProjectEstimationAbstract[] = [];

    public projectTypeOptions: IMultiSelectOption[] = [];
    public projectTagOptions: IMultiSelectOption[] = [];
    public organizationOptions: IMultiSelectOption[] = [];
    public organizationAccountOptions: IMultiSelectOption[] = [];
    public projectManagerAccountOptions: IMultiSelectOption[] = [];
    public deliverableTagOptions: IMultiSelectOption[] = [];
    public deliverableOptions: IMultiSelectOption[] = [];
    public consultantAccountOptions: IMultiSelectOption[] = [];
    public estimationAbstractOptions: IMultiSelectOption[] = [];

    public allOrganizations: Organization[] = [];
    public allOrganizationAccounts: AdminAccount[] = [];
    public allProjectManagers: AdminAccount[] = [];
    public allConsultants: AdminAccount[] = [];

    public selectedOrgId: string[];
    public selectedProjectEstimationId: string[];
    public selectedOrgAccountId: string[];
    public selectedProjectManagerAccountId: string[];
    public selectedConsultantAccountId: string[];
    public selectedScope: ProjectScope;
    public selectedDeliverable: ProjectScopeDeliverable;
    public selectedDeliverableOriginallyOnHold = false;
    public selectedRate: ProjectScopeRateInfo;
    public scopeAgreedByName: string;
    public willRemoveScopeAgreement = false;

    public organizationTerms: OrganizationTerms[] = [];

    public permUnderEdit: ProjectPermission = null;
    public consultantRateEmailSent: string = null;

    public rateSanityCheckResult = null;

    public statuses: string[] = [
        'Initial',
        'Scoped - Waiting For Customer Approval',
        'Customer Approved / Active Project',
        'Completed',
        'Dormant'
    ];
    public fileKinds: string[] = [
        'Client Upload',
        'FlexTeam Work',
        'Deliverable'
    ];
    public fileStatuses: string[] = [
        'Draft',
        'Final'
    ];

    public templates: ProjectFileTemplate[] = [];

    public uploadViewModel = {
        fileName: <string>null,
        fileContent: <string>null,
        fileDescription: <string>null,
        kind: <string>null,
        status: <string>null,
        clientAccountPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        consultantPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        orgUserPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        orgViewerPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        projectManagerPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        sendEmail: <boolean>true
    };

    public urlViewModel = {
        url: <string>null,
        fileDescription: <string>null,
        kind: <string>null,
        status: <string>null,
        clientAccountPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        consultantPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        orgUserPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        orgViewerPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        projectManagerPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        sendEmail: <boolean>true
    };

    public gDocViewModel = {
        fileName: <string>null,
        fileDescription: <string>null,
        kind: <string>null,
        status: <string>null,
        template: <ProjectFileTemplate>null,
        clientAccountPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        consultantPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        orgUserPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        orgViewerPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        projectManagerPermissions: <ProjectFilePermissionFlags>ProjectFilePermissionFlags.None,
        sendEmail: <boolean>true
    };

    public fileUnderEdit: ProjectFile = null;
    public fileToDelete: ProjectFile = null;
    public fileEditorToShow = 'Blob';

    // Settings configuration
    public multiSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        dynamicTitleMaxItems: 3,
        displayAllSelectedText: false,
        showUncheckAll: true,
    };

    public singleSelectSettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        dynamicTitleMaxItems: 1,
        displayAllSelectedText: false,
        selectionLimit: 1,
        autoUnselect: true,
        showUncheckAll: true,
        closeOnSelect: true
    };

    // Text configuration
    public multiSelectTexts: IMultiSelectTexts = {
        checkAll: 'Select all',
        uncheckAll: 'Unselect all',
        checked: 'item selected',
        checkedPlural: 'items selected',
        searchPlaceholder: 'Find',
        defaultTitle: '- - - - - - - - - - - - - - - - - Select - - - - - - - - - - - - - - - - - ',
        allSelected: 'All selected',
    };

    public datePickerOptions: IMyOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy'
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dialogService: DialogService,
        private changeDetector: ChangeDetectorRef,
        private accountService: AccountService,
        private projectService: ProjectService,
        private organizationService: OrganizationService,
        private utilityService: UtilityService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.createEmptyProjectIfNecessary();
            }).subscribe();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = `Please save before navigating away!`;
        }
    }

    private canDeactivate(): Observable<boolean> | boolean {
        return !this.projectIsDirty &&
            !this.scopeItemIsDirty &&
            !this.deliverableIsDirty &&
            !this.rateIsDirty;
    }

    public ngOnDestroy() {
        this.destroying = true;
        this.changeDetector.detach();
        this.saveCurrentViewIfNecessary(() => {
        });
    }

    private createEmptyProjectIfNecessary() {
        if (!this.selectedProjectId) {
            const newProject = new Project();
            newProject.name = 'New Project';
            newProject.status = 'Initial';
            this.handleProjectSetup(newProject);
        }
    }

    public setProject(projectId: string) {
        this.projectService.getProjectById(projectId, this.loggedInAccount.refreshToken).subscribe((p) => {
            // console.log('set project', projectId);
            this.handleProjectSetup(p);
            this.setScopes(projectId);
            this.refreshProjectTabInfo();
        });
    }

    public refreshProjectTabInfo() {
        if (this.projectLoaded) {
            const selectedOrg = this.allOrganizations.find(o => o.id === this.project.organizationId);
            this.projectLoaded.emit({
                projectId: this.project.id,
                projectName: this.project.name,
                organizationName: selectedOrg ? selectedOrg.name : null
            });
        }
    }

    private handleProjectSetup(project: Project) {
        this.project = project;
        this.loading = false;
        this.setTypeOptions();
        this.setEstimationAbstracts();
        this.setProjectTagOptions();
        this.setOrganizations();
        this.setProjectManagers();
        this.setRequestDate();
        this.setDeliverableTagOptions(false);
        this.loadProjectFiles();
    }

    public openProjectOnWebsite() {
        if (this.project && this.project.id) {
            window.open(CONFIG.websiteBase + 'project/' + this.project.id, '_blank');
        }
    }

    public statusChange() {
        this.projectIsDirty = true;
        this.showConfEmail = this.project.status === 'Scoped - Waiting For Customer Approval';
    }

    public sendConfEmail() {
        this.projectService.sendProjectScopeEmail(this.project.id, this.loggedInAccount.refreshToken)
            .subscribe(() => {
                // add an internal note that this was sent
                if (this.project.clientAccountId) {
                    const selectedClientAccount = this.allOrganizationAccounts.find(a => this.project.clientAccountId === a.id);
                    if (!this.project.internalNotes) { this.project.internalNotes = '' };
                    this.project.internalNotes += `<br> &quot;Confirmation&quot email sent to 
                ${selectedClientAccount.firstName + ' ' + selectedClientAccount.lastName} on ${moment(new Date()).toLocaleString()}`;
                    this.saveSettings(null);
                }
            });
        this.showConfEmail = false;
    }

    public setRequestDate() {
        setTimeout(() => {
            if (this.project.requestDate && this.requestDatePicker) {
                const jsDate = new Date(this.project.requestDate);
                const myDate: IMyDate = {
                    day: jsDate.getUTCDate(),
                    month: jsDate.getUTCMonth() + 1,
                    year: jsDate.getUTCFullYear()
                };
                this.requestDatePicker.updateDateValue(myDate, false);
            }
        }, 0);
    }

    public setClientDueDate() {
        setTimeout(() => {
            if (this.selectedDeliverable.dueDate && this.clientDueDatePicker) {
                const jsDate = new Date(this.selectedDeliverable.dueDate);
                const myDate: IMyDate = {
                    day: jsDate.getUTCDate(),
                    month: jsDate.getUTCMonth() + 1,
                    year: jsDate.getUTCFullYear()
                };
                this.clientDueDatePicker.updateDateValue(myDate, false);
            } else if (this.clientDueDatePicker) {
                this.clientDueDatePicker.clearDate();
            }
        }, 0);
    }

    public setConsultantDueDate() {
        setTimeout(() => {
            if (this.selectedDeliverable.consultantDueDate && this.consultantDueDatePicker) {
                const jsDate = new Date(this.selectedDeliverable.consultantDueDate);
                const myDate: IMyDate = {
                    day: jsDate.getUTCDate(),
                    month: jsDate.getUTCMonth() + 1,
                    year: jsDate.getUTCFullYear()
                };
                this.consultantDueDatePicker.updateDateValue(myDate, false);
            } else if (this.consultantDueDatePicker) {
                this.consultantDueDatePicker.clearDate();
            }
        }, 0);
    }

    public setRecurringEndDate() {
        setTimeout(() => {
            if (this.selectedRate && this.recurringEndDatePicker) {
                const jsDate = new Date(this.selectedRate.recurringEndDate);
                const myDate: IMyDate = {
                    day: jsDate.getUTCDate(),
                    month: jsDate.getUTCMonth() + 1,
                    year: jsDate.getUTCFullYear()
                };
                this.recurringEndDatePicker.updateDateValue(myDate, false);
            } else if (this.recurringEndDatePicker) {
                this.consultantDueDatePicker.clearDate();
            }
        }, 0);
    }

    public setScopes(projectId: string) {
        this.projectService.getProjectScopesByProjectId(projectId, this.loggedInAccount.refreshToken).subscribe((s) => {
            this.scopes = s;
            // console.log('scopes', s);
            this.scopes.forEach(scope => {
                this.setDeliverables(scope.id);
                this.setRateInfos(scope.id);
            });
        });
    }

    public setDeliverables(scopeId: string) {
        this.projectService.getProjectScopeDeliverablesByProjectScopeId(scopeId, this.loggedInAccount.refreshToken)
            .subscribe((deliverables) => {
                // console.log('deliverables', deliverables);
                this.deliverables.push(...deliverables);
            });
    }

    public setRateInfos(scopeId: string) {
        this.projectService.getProjectScopeRateInfosByProjectScopeId(scopeId, this.loggedInAccount.refreshToken)
            .subscribe((rateInfos) => {
                // console.log('rate infos', rateInfos);
                this.rates.push(...rateInfos);
            });
    }

    public get editing(): string {
        return this._editing;
    }

    public set editing(v: string) {
        if (v === 'projectSettings') {
            this.setRequestDate();
        }
        this._editing = v;
    }

    public get rateTypes(): string[] {
        return ['Flat', 'Hourly', 'Monthly', 'Recurring'];
    }

    public get audiences(): string[] {
        return ['Client', 'Consultant'];
    }

    public get selectedRateType(): string {
        return this.selectedRate.rateType;
    }

    public set selectedRateType(t: string) {
        this.selectedRate.rateType = t;
    }

    private setTypeOptions() {
        this.utilityService.getTagsByType('ProjectType', this.loggedInAccount.refreshToken).subscribe((tags: string[]) => {
            if (tags && tags.length > 0 && this.projectTypeOptions.length === 0) {
                tags.forEach(tag => {
                    this.projectTypeOptions.push({
                        id: tag,
                        name: tag
                    });
                });
            }
            this.forceDropdownChanges(this.project.types);
        });
    }

    private setProjectTagOptions() {
        this.utilityService.getTagsByType('ProjectTag', this.loggedInAccount.refreshToken).subscribe((tags: string[]) => {
            if (tags && tags.length > 0 && this.projectTagOptions.length === 0) {
                tags.forEach(tag => {
                    this.projectTagOptions.push({
                        id: tag,
                        name: tag
                    });
                });
            }
            this.forceDropdownChanges(this.project.tags);
        });
    }

    private setDeliverableTagOptions(justRefreshDeliverable: boolean) {
        if (this.selectedDeliverable) {
            if (this.deliverableTagOptions.length === 0) {
                this.utilityService.getTagsByType('ProjectScopeDeliverableTag',
                    this.loggedInAccount.refreshToken).subscribe((tags: string[]) => {
                        if (tags && tags.length > 0) {
                            tags.forEach(tag => {
                                this.deliverableTagOptions.push({
                                    id: tag,
                                    name: tag
                                });
                            });
                        }
                        this.forceDropdownChanges(this.selectedDeliverable.tags);
                    });
            } else if (justRefreshDeliverable) {
                this.forceDropdownChanges(this.selectedDeliverable.tags);
            }
        }
    }

    private setDeliverableOptions() {
        this.deliverableOptions = [];
        this.projectService.getProjectScopeDeliverablesByProjectScopeId(this.selectedRate.projectScopeId,
            this.loggedInAccount.refreshToken).subscribe((deliverables: ProjectScopeDeliverable[]) => {
                if (deliverables && deliverables.length > 0) {
                    deliverables.forEach(deliverable => {
                        this.deliverableOptions.push({
                            id: deliverable.id,
                            name: deliverable.name ? deliverable.name : '[null]'
                        });
                    });
                }
                this.forceDropdownChanges(this.selectedRate.projectScopeDeliverableIds);
            });
    }

    public saveSettings(callback?: Function) {
        this.project.organizationId = this.selectedOrgId.length === 1 ? this.selectedOrgId[0] : null;
        this.project.clientAccountId = this.selectedOrgAccountId && this.selectedOrgAccountId.length === 1 ? this.selectedOrgAccountId[0]
            : null;
        this.project.projectManagerAccountId = this.selectedProjectManagerAccountId &&
            this.selectedProjectManagerAccountId.length === 1 ? this.selectedProjectManagerAccountId[0] : null;
        this.projectService.upsertProject(this.project, this.loggedInAccount.refreshToken).subscribe((project: Project) => {
            this.project = project;
            this.handleProjectSetup(project);
            this.projectIsDirty = false;
            if (callback) {
                callback();
            }
            this.refreshProjectTabInfo();
        });
    }

    public saveScopeItem(callback?: Function) {
        if (this.willRemoveScopeAgreement) {
            this.selectedScope.agreedByAccountId = null;
            this.selectedScope.agreedOnDate = null;
        }
        this.projectService.upsertProjectScope(this.selectedScope, this.loggedInAccount.refreshToken).subscribe((scope: ProjectScope) => {
            const ix = this.scopes.indexOf(this.selectedScope);
            this.scopes[ix] = scope;
            this.selectedScope = this.scopes[ix];
            this.scopeItemIsDirty = false;
            if (callback) {
                callback();
            }
        });
    }

    public removeScopeAgreement() {
        this.willRemoveScopeAgreement = true;
        this.scopeItemIsDirty = true;
    }

    public undoRemoveScopeAgreement() {
        this.willRemoveScopeAgreement = false;
        this.scopeItemIsDirty = true;
    }

    public saveDeliverable(callback?: Function, fromNavigateAway?: boolean) {
        this.projectService.upsertProjectScopeDeliverable(this.selectedDeliverable, this.loggedInAccount.refreshToken).subscribe((deliverable: ProjectScopeDeliverable) => {
            const ix = this.deliverables.indexOf(this.selectedDeliverable);
            this.deliverables[ix] = deliverable;
            this.selectedDeliverable = this.deliverables[ix];
            // if (!fromNavigateAway) {
            //     this.selectDeliverable(deliverable);
            // }
            this.deliverableIsDirty = false;
            if (callback) {
                callback();
            }
        });
    }

    public saveRate(callback?: Function, fromNavigateAway?: boolean) {
        if (this.selectedRate && !this.rateSanityCheckResult) {
            if (this.selectedRate && this.selectedConsultantAccountId) {
                this.selectedRate.consultantAccountId = (this.selectedConsultantAccountId.length === 1
                    && this.selectedRate.audience === 'Consultant') ? this.selectedConsultantAccountId[0] : null;
            }
            if (this.selectedRate && this.selectedRate.rateType !== 'Recurring') {
                this.selectedRate.recurringDays = null;
            }

            if (this.selectedRate && this.selectedRate.rateType !== 'Recurring' && this.selectedRate.rateType !== 'Monthly') {
                this.selectedRate.recurringEndDate = null;
            }
            if (this.selectedRate) {
                this.projectService.upsertProjectScopeRateInfo(this.selectedRate, this.loggedInAccount.refreshToken)
                    .subscribe((rate: ProjectScopeRateInfo) => {
                        const ix = this.rates.indexOf(this.selectedRate);
                        this.rates[ix] = rate;
                        this.selectedRate = this.rates[ix];
                        // if (!fromNavigateAway) {
                        //     this.selectRate(rate);
                        // }
                        this.rateIsDirty = false;
                        if (callback) {
                            callback();
                        }
                    });
            }
        }
    }

    public onOrgChange(event) {
        this.project.organizationId = this.selectedOrgId.length === 1 ? this.selectedOrgId[0] : null;
        this.project.clientAccountId = null;
        this.selectedOrgAccountId = [];
        this.allOrganizationAccounts = [];
        this.setOrganizationUsers();
        this.organizationTerms = [];
        this.getSelectedOrgTerms();
    }

    public getSelectedOrgTerms() {
        this.organizationService.getOrganizationTerms(this.project.organizationId, this.loggedInAccount.refreshToken)
            .subscribe((terms) => {
                this.organizationTerms = terms;
                // console.log('org terms pulled:', this.organizationTerms);
            });
    }

    public loadTemplates() {
        if (!this.templates || this.templates.length === 0) {
            this.loading = true;
            this.projectService.getProjectFileTemplates(this.loggedInAccount.refreshToken)
                .subscribe(templates => {
                    // console.log('templates', templates);
                    this.templates = templates;
                    this.loading = false;
                });
        }
    }

    public onEstimationAbstractChange(event) {
        this.project.projectEstimationId = this.selectedProjectEstimationId.length === 1 ? this.selectedProjectEstimationId[0] : null;
        this.projectIsDirty = true;
    }


    private setEstimationAbstracts() {
        this.projectService.getProjectEstimationAbstracts(this.loggedInAccount.refreshToken).subscribe((abstracts: ProjectEstimationAbstract[]) => {
            if (abstracts && abstracts.length > 0 && this.estimationAbstractOptions.length === 0) {
                this.estimationAbstracts = abstracts;
                abstracts.forEach(abstract => {
                    this.estimationAbstractOptions.push({
                        id: abstract.id,
                        name: abstract.projectType
                    });
                });
            }
            if (this.project.projectEstimationId) {
                this.selectedProjectEstimationId = [this.project.projectEstimationId];
            }
        });
    }


    private setOrganizations() {
        this.organizationService.getAllOrganizations(this.loggedInAccount.refreshToken).subscribe((orgs: Organization[]) => {
            if (orgs && orgs.length > 0 && this.organizationOptions.length === 0) {
                this.allOrganizations = orgs;
                orgs.forEach(org => {
                    this.organizationOptions.push({
                        id: org.id,
                        name: org.name
                    });
                });
            }
            if (this.project.organizationId) {
                this.selectedOrgId = [this.project.organizationId];
                this.getSelectedOrgTerms();
                this.refreshProjectTabInfo();
            }
            this.setOrganizationUsers();
        });
    }

    private setProjectManagers() {
        this.accountService.getProjectManagerAccounts(this.loggedInAccount.refreshToken).subscribe((accts: AdminAccount[]) => {
            this.projectManagerAccountOptions = [];
            if (accts && accts.length > 0) {
                this.allProjectManagers = accts;
                accts.forEach(acct => {
                    this.projectManagerAccountOptions.push({
                        id: acct.id,
                        name: acct.firstName + ' ' + acct.lastName
                    });
                    if (acct.firstName === 'TamraPM' && acct.lastName === 'Johnson' && !this.project.projectManagerAccountId) {
                        this.selectedProjectManagerAccountId = [acct.id];
                    }
                });
            }
            if (this.project.projectManagerAccountId) {
                this.selectedProjectManagerAccountId = [this.project.projectManagerAccountId];
            }
        });
    }


    private setConsultants() {
        if (!this.allConsultants || this.allConsultants.length === 0) {
            this.accountService.getConsultantAccounts(this.loggedInAccount.refreshToken).subscribe((accts: AdminAccount[]) => {
                this.consultantAccountOptions = [];
                if (accts && accts.length > 0) {
                    this.allConsultants = accts;
                    accts.forEach(acct => {
                        this.consultantAccountOptions.push({
                            id: acct.id,
                            name: acct.firstName + ' ' + acct.lastName
                        });
                    });
                }
                this.selectedConsultantAccountId = [this.selectedRate.consultantAccountId];
            });
        } else {
            this.selectedConsultantAccountId = [this.selectedRate.consultantAccountId];
        }
    }

    private setOrganizationUsers() {
        if (this.project.organizationId) {
            this.accountService.getAccountsForOrganization(this.project.organizationId, this.loggedInAccount.refreshToken)
                .subscribe((accts: AdminAccount[]) => {
                    this.organizationAccountOptions = [];
                    if (accts && accts.length > 0) {
                        this.allOrganizationAccounts = accts;
                        accts.forEach(acct => {
                            this.organizationAccountOptions.push({
                                id: acct.id,
                                name: acct.firstName + ' ' + acct.lastName
                            });
                        });
                    }
                    if (this.project.clientAccountId) {
                        this.selectedOrgAccountId = [this.project.clientAccountId];
                    }
                });
        } else {
            this.organizationAccountOptions = [];
        }
    }

    private forceDropdownChanges(dropdownArray: any[]) {
        if (dropdownArray && !this.destroying) {
            // the dropdown options need to know something changed when loaded after
            // the model is instantiated to display the list properly...
            dropdownArray.push('');
            this.changeDetector.detectChanges();
            dropdownArray.pop();
            this.changeDetector.detectChanges();
        }
    }

    public get projectSaveButtonEnabled(): boolean {
        return this.projectIsDirty &&
            this.selectedOrgAccountId &&
            this.selectedOrgAccountId.length === 1 &&
            this.project.name &&
            this.project.name.length > 0;
    }

    public get scopeSaveButtonEnabled(): boolean {
        return this.scopeItemIsDirty &&
            this.selectedScope.name &&
            this.selectedScope.name.length > 0;
    }

    public get deliverableSaveButtonEnabled(): boolean {
        return this.deliverableIsDirty &&
            this.selectedDeliverable.name &&
            this.selectedDeliverable.name.length > 0;
    }

    public get rateSaveButtonEnabled(): boolean {
        return this.rateIsDirty;
    }

    public onRequestDateChanged(event: IMyDateModel) {
        this.project.requestDate = event.formatted;
        this.projectIsDirty = true;
    }


    public onClientDueDateChanged(event: IMyDateModel) {
        this.selectedDeliverable.dueDate = event.formatted;
        this.deliverableIsDirty = true;
    }

    public onConsultantDueDateChanged(event: IMyDateModel) {
        this.selectedDeliverable.consultantDueDate = event.formatted;
        this.deliverableIsDirty = true;
    }

    public onRecurringEndDateChanged(event: IMyDateModel) {
        this.selectedRate.recurringEndDate = event.formatted;
        this.rateIsDirty = true;
    }


    private saveCurrentViewIfNecessary(callback: Function) {
        if (this.projectIsDirty) {
            console.log('switch - saving project!');
            this.saveSettings(callback);
        } else if (this.scopeItemIsDirty) {
            console.log('switch - saving scope!');
            this.saveScopeItem(callback);
        } else if (this.deliverableIsDirty) {
            console.log('switch - saving deliverable!');
            this.saveDeliverable(callback, true);
        } else if (this.rateIsDirty) {
            console.log('switch - saving project!');
            this.saveRate(callback, true);
        } else {
            if (callback) {
                callback();
            }
        }
    }

    public selectSettings() {
        this.saveCurrentViewIfNecessary(() => {
            this.editing = 'projectSettings';
            this.selectedDeliverable = null;
            this.selectedScope = null;
            this.selectedRate = null;
        });
    }

    public selectBackground() {
        this.saveCurrentViewIfNecessary(() => {
            this.editing = 'projectBackground';
            this.selectedDeliverable = null;
            this.selectedScope = null;
            this.selectedRate = null;
        });
    }

    public selectProjectFiles() {
        this.saveCurrentViewIfNecessary(() => {
            this.loadProjectFiles();
            this.editing = 'projectFiles';
            this.selectedDeliverable = null;
            this.selectedScope = null;
            this.selectedRate = null;
        });
    }

    public selectProjectBilling() {
        this.saveCurrentViewIfNecessary(() => {
            this.editing = 'projectBilling';
            this.selectedDeliverable = null;
            this.deliverableIsDirty = false;
            this.selectedScope = null;
            this.scopeItemIsDirty = null;
            this.selectedRate = null;
            this.rateIsDirty = null;
            window.scrollTo(0, 0);
        });
    }

    public selectProjectConsultant() {
        this.saveCurrentViewIfNecessary(() => {
            this.editing = 'projectConsultant';
            this.selectedDeliverable = null;
            this.deliverableIsDirty = false;
            this.selectedScope = null;
            this.scopeItemIsDirty = null;
            this.selectedRate = null;
            this.rateIsDirty = null;
            window.scrollTo(0, 0);
        });
    }

    private setScopeAgreedByName() {
        this.scopeAgreedByName = null;
        if (this.selectedScope && this.selectedScope.agreedByAccountId) {
            this.accountService.getAccount(this.selectedScope.agreedByAccountId, this.loggedInAccount.refreshToken)
                .subscribe((acct: AdminAccount) => {
                    this.scopeAgreedByName = acct.firstName + ' ' + acct.lastName;
                });
        }
    }

    public selectScope(scope: ProjectScope) {
        this.saveCurrentViewIfNecessary(() => {
            this.selectedDeliverable = null;
            this.selectedScope = scope;
            this.selectedRate = null;
            this.scopeItemIsDirty = false;
            this.editing = 'scope-' + scope.id;
            this.setScopeAgreedByName();
        });
    }

    public selectDeliverable(deliverable: ProjectScopeDeliverable) {
        this.saveCurrentViewIfNecessary(() => {
            this.selectedScope = null;
            this.selectedRate = null;
            this.selectedDeliverable = deliverable;
            this.selectedDeliverableOriginallyOnHold = deliverable.onHold;
            this.deliverableIsDirty = false;
            this.editing = 'deliverable-' + deliverable.id;
            this.setDeliverableTagOptions(true);
            this.setClientDueDate();
            this.setConsultantDueDate();
        });
    }

    public selectRate(rate: ProjectScopeRateInfo) {
        this.saveCurrentViewIfNecessary(() => {
            this.selectedScope = null;
            this.selectedDeliverable = null;
            this.selectedRate = rate;
            this.setDeliverableOptions();
            this.setConsultants();
            this.rateIsDirty = false;
            this.editing = 'rate-' + rate.id;
            this.consultantRateEmailSent = null;
            this.setRecurringEndDate();
        });
    }


    public onSettingsChange(event) {
        console.log('settings changed');
        this.projectIsDirty = true;
    }

    public onScopeItemChange(event) {
        this.scopeItemIsDirty = true;
    }

    public onDeliverableChange(event) {
        this.deliverableIsDirty = true;
    }

    public onRateChange(event) {
        this.rateIsDirty = true;
        if (this.selectedRate.rateType !== 'Hourly') {
            this.selectedRate.hoursLimit = null;
            this.selectedRate.hoursMaxEstimate = null;
            this.selectedRate.hoursMinEstimate = null;
        }
        this.rateSanityCheck();
    }

    public updateScopeItemDescription(event) {
        console.log('update scope item description', event);
    }

    public updateDeliverableDescription(event) {
        console.log('update deliverable description', event);
    }

    public updateDeliverableNotes(event) {
        console.log('update deliverable notes', event);
    }






    public loadProjectFiles() {
        if (this.project && this.project.id) {
            this.loading = true;
            this.projectService.getProjectFiles(this.project.id, this.loggedInAccount.refreshToken)
                .subscribe((result: ProjectFile[]) => {
                    this.projectFiles = result;
                    this.loadTemplates();
                    this.loading = false;
                },
                    (error: any) => {
                        alert('There was an error getting the project files. Check the JavaScript console for details.');
                    });
        }
    }

    public downloadFile(file: ProjectFile) {
        if (file && file.id) {
            if (file.fileType === 'Blob') {
                this.projectService.downloadProjectFile(file, this.loggedInAccount.refreshToken)
                    .subscribe((result) => {
                        const dlFile: Blob = result.body;
                        console.log(dlFile.size + ' bytes file downloaded. File type: ', dlFile.type);
                        FileSaver.saveAs(dlFile, file.name);
                    },
                        (error: any) => {
                            alert('There was an error downloading the project file. Check the JavaScript console for details.');
                        });
            } else if (file.url) {
                if (!file.url.startsWith('http')) {
                    file.url = 'http://' + file.url;
                }
                window.open(file.url);
            }
        }
    }

    public onFileSelect(event: EventTarget) {
        const eventObj: any = event;
        const target: HTMLInputElement = eventObj.target;
        const files: FileList = target.files;
        // this.file = files[0];
        // console.log('file selected: ', files[0]);
        this.getBase64(files[0]);
    }

    public onBase64Complete(base64FileContent: string, fileName: string) {
        this.uploadViewModel.fileContent = base64FileContent;
        this.uploadViewModel.fileName = fileName;
        // console.log('File base64:', this.uploadViewModel.fileContent);
    }

    public getBase64(file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // strip off the URL junk:
            this.onBase64Complete((reader.result as string).substring((reader.result as string).indexOf(',', 0) + 1), file.name);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    }

    public clearUploadViewModel() {
        this.uploadViewModel.fileDescription = null;
        this.uploadViewModel.fileName = null;
        this.uploadViewModel.fileContent = null;
        this.uploadViewModel.kind = null;
        this.uploadViewModel.status = null;
        this.uploadViewModel.sendEmail = true;
        this.fileUnderEdit = null;

        this.uploadViewModel.clientAccountPermissions =
            this.uploadViewModel.consultantPermissions =
            this.uploadViewModel.projectManagerPermissions =
            this.uploadViewModel.orgUserPermissions =
            this.uploadViewModel.orgViewerPermissions = ProjectFilePermissionFlags.None;
    }

    public uploadFile() {
        const pf = new ProjectFile();
        pf.name = this.uploadViewModel.fileName;
        pf.projectId = this.project.id;
        pf.description = this.uploadViewModel.fileDescription;
        pf.kind = this.uploadViewModel.kind;
        pf.status = this.uploadViewModel.status;
        pf.fileType = 'Blob';
        pf.clientAccountPermissions = this.uploadViewModel.clientAccountPermissions;
        pf.consultantPermissions = this.uploadViewModel.consultantPermissions;
        pf.projectManagerPermissions = this.uploadViewModel.projectManagerPermissions;
        pf.orgUserPermissions = this.uploadViewModel.orgUserPermissions;
        pf.orgViewerPermissions = this.uploadViewModel.orgViewerPermissions;
        const thisClass: ProjectEditorComponent = this;
        this.saveBlob(pf).then((value: ProjectFile) => {
            thisClass.clearUploadViewModel();
            thisClass.projectFiles.push(value);
        });
    }

    private saveBlob(pf: ProjectFile): Promise<ProjectFile> {
        return new Promise((resolve, reject) => {
            this.projectService.upsertProjectFile(pf, null, this.uploadViewModel.fileContent,
                this.uploadViewModel.sendEmail, this.loggedInAccount.refreshToken)
                .subscribe((result: ProjectFile) => {
                    resolve(result);
                    return;
                },
                    (error: any) => {
                        alert('There was an error uploading the file. Check the JavaScript console for details.');
                        reject(null);
                    });
        });
    }

    public clearUrlViewModel() {
        this.urlViewModel.fileDescription = null;
        this.urlViewModel.url = null;
        this.uploadViewModel.fileContent = null;
        this.urlViewModel.kind = null;
        this.urlViewModel.status = null;
        this.urlViewModel.sendEmail = true;
        this.fileUnderEdit = null;

        this.urlViewModel.clientAccountPermissions =
            this.urlViewModel.consultantPermissions =
            this.urlViewModel.projectManagerPermissions =
            this.urlViewModel.orgUserPermissions =
            this.urlViewModel.orgViewerPermissions = ProjectFilePermissionFlags.None;
    }

    private saveUrl(pf: ProjectFile): Promise<ProjectFile> {
        return new Promise((resolve, reject) => {
            this.projectService.upsertProjectFile(pf, null, null, this.urlViewModel.sendEmail, this.loggedInAccount.refreshToken)
                .subscribe((result: ProjectFile) => {
                    resolve(result);
                    return;
                },
                    (error: any) => {
                        alert('There was an error linking the URL. Check the JavaScript console for details.');
                        reject(null);
                    });
        });
    }

    public uploadUrl() {
        const pf = new ProjectFile();
        pf.projectId = this.project.id;
        pf.description = this.urlViewModel.fileDescription;
        pf.url = this.urlViewModel.url;
        pf.fileType = 'Link';
        pf.kind = this.urlViewModel.kind;
        pf.status = this.urlViewModel.status;
        pf.clientAccountPermissions = this.urlViewModel.clientAccountPermissions;
        pf.consultantPermissions = this.urlViewModel.consultantPermissions;
        pf.projectManagerPermissions = this.urlViewModel.projectManagerPermissions;
        pf.orgUserPermissions = this.urlViewModel.orgUserPermissions;
        pf.orgViewerPermissions = this.urlViewModel.orgViewerPermissions;

        const thisClass: ProjectEditorComponent = this;
        this.saveUrl(pf).then((value: ProjectFile) => {
            thisClass.clearUrlViewModel();
            thisClass.projectFiles.push(value);
        });
    }

    public clearGDocViewModel() {
        this.gDocViewModel.fileDescription = null;
        this.gDocViewModel.fileName = null;
        this.gDocViewModel.status = null;
        this.gDocViewModel.kind = null;
        this.gDocViewModel.sendEmail = true;
        this.gDocViewModel.template = null;
        this.fileUnderEdit = null;

        this.gDocViewModel.clientAccountPermissions =
            this.gDocViewModel.consultantPermissions =
            this.gDocViewModel.projectManagerPermissions =
            this.gDocViewModel.orgUserPermissions =
            this.gDocViewModel.orgViewerPermissions = ProjectFilePermissionFlags.None;
    }

    private saveGDoc(pf: ProjectFile, fromTemplate: ProjectFileTemplate): Promise<ProjectFile> {
        return new Promise((resolve, reject) => {
            this.projectService.upsertProjectFile(pf, fromTemplate, null, this.gDocViewModel.sendEmail, this.loggedInAccount.refreshToken)
                .subscribe((result: ProjectFile) => {
                    resolve(result);
                    return;
                },
                    (error: any) => {
                        alert('There was an error creating the google doc. Check the JavaScript console for details.');
                        reject(null);
                    });
        });
    }

    public uploadGDoc(type: string) {
        const pf = new ProjectFile();
        pf.projectId = this.project.id;
        pf.description = this.gDocViewModel.fileDescription;
        pf.name = this.gDocViewModel.fileName;
        pf.kind = this.gDocViewModel.kind;
        pf.status = this.gDocViewModel.status;
        pf.fileType = type;
        pf.clientAccountPermissions = this.gDocViewModel.clientAccountPermissions;
        pf.consultantPermissions = this.gDocViewModel.consultantPermissions;
        pf.projectManagerPermissions = this.gDocViewModel.projectManagerPermissions;
        pf.orgUserPermissions = this.gDocViewModel.orgUserPermissions;
        pf.orgViewerPermissions = this.gDocViewModel.orgViewerPermissions;
        const thisClass: ProjectEditorComponent = this;
        this.saveGDoc(pf, this.gDocViewModel.template).then((value: ProjectFile) => {
            thisClass.clearGDocViewModel();
            thisClass.projectFiles.push(value);
        });
    }

    public deleteFile(file: ProjectFile) {
        this.projectService.deleteProjectFile(file.id, this.loggedInAccount.refreshToken)
            .subscribe((result: void) => {
                this.fileToDelete = null;
                this.projectFiles.splice(this.projectFiles.indexOf(file), 1);
            },
                (error: any) => {
                    alert('There was an error uploading the file. Check the JavaScript console for details.');
                });
    }

    public setFileToDelete(file: ProjectFile) {
        this.fileToDelete = file;
    }

    public cancelFileToDelete() {
        this.fileToDelete = null;
    }

    public editFile(file: ProjectFile) {
        this.fileUnderEdit = file;
        if (file.fileType === 'Blob') {
            this.fileEditorToShow = 'Blob';
            this.uploadViewModel.fileDescription = file.description;
            this.uploadViewModel.fileName = file.name;
            this.uploadViewModel.kind = file.kind;
            this.uploadViewModel.status = file.status;
            this.uploadViewModel.clientAccountPermissions = file.clientAccountPermissions;
            this.uploadViewModel.consultantPermissions = file.consultantPermissions;
            this.uploadViewModel.projectManagerPermissions = file.projectManagerPermissions;
            this.uploadViewModel.orgUserPermissions = file.orgUserPermissions;
            this.uploadViewModel.orgViewerPermissions = file.orgViewerPermissions;
        } else if (file.fileType === 'Link') {
            this.fileEditorToShow = 'Url';
            this.urlViewModel.fileDescription = file.description;
            this.urlViewModel.kind = file.kind;
            this.urlViewModel.status = file.status;
            this.urlViewModel.url = file.url;
            this.urlViewModel.clientAccountPermissions = file.clientAccountPermissions;
            this.urlViewModel.consultantPermissions = file.consultantPermissions;
            this.urlViewModel.projectManagerPermissions = file.projectManagerPermissions;
            this.urlViewModel.orgUserPermissions = file.orgUserPermissions;
            this.urlViewModel.orgViewerPermissions = file.orgViewerPermissions;
        } else if (file.fileType === 'G-document' || file.fileType === 'G-spreadsheet' || file.fileType === 'G-presentation') {
            this.fileEditorToShow = 'GDoc';
            this.gDocViewModel.fileDescription = file.description;
            this.gDocViewModel.kind = file.kind;
            this.gDocViewModel.status = file.status;
            this.gDocViewModel.fileName = file.name;
            this.gDocViewModel.clientAccountPermissions = file.clientAccountPermissions;
            this.gDocViewModel.consultantPermissions = file.consultantPermissions;
            this.gDocViewModel.projectManagerPermissions = file.projectManagerPermissions;
            this.gDocViewModel.orgUserPermissions = file.orgUserPermissions;
            this.gDocViewModel.orgViewerPermissions = file.orgViewerPermissions;
        }
    }

    public editFileSubmit() {
        const thisClass: ProjectEditorComponent = this;

        if (this.fileUnderEdit.fileType === 'Blob') {
            this.fileUnderEdit.description = this.uploadViewModel.fileDescription;
            this.fileUnderEdit.kind = this.uploadViewModel.kind;
            this.fileUnderEdit.status = this.uploadViewModel.status;
            this.fileUnderEdit.clientAccountPermissions = this.uploadViewModel.clientAccountPermissions;
            this.fileUnderEdit.consultantPermissions = this.uploadViewModel.consultantPermissions;
            this.fileUnderEdit.projectManagerPermissions = this.uploadViewModel.projectManagerPermissions;
            this.fileUnderEdit.orgUserPermissions = this.uploadViewModel.orgUserPermissions;
            this.fileUnderEdit.orgViewerPermissions = this.uploadViewModel.orgViewerPermissions;
            if (this.uploadViewModel.fileContent) {
                this.fileUnderEdit.name = this.uploadViewModel.fileName;
            }
            this.saveBlob(this.fileUnderEdit).then((value: ProjectFile) => {
                this.cancelEditFile();
            });
        } else if (this.fileUnderEdit.fileType === 'Link') {
            this.fileUnderEdit.description = this.urlViewModel.fileDescription;
            this.fileUnderEdit.kind = this.urlViewModel.kind;
            this.fileUnderEdit.status = this.urlViewModel.status;
            this.fileUnderEdit.url = this.urlViewModel.url;
            this.fileUnderEdit.clientAccountPermissions = this.urlViewModel.clientAccountPermissions;
            this.fileUnderEdit.consultantPermissions = this.urlViewModel.consultantPermissions;
            this.fileUnderEdit.projectManagerPermissions = this.urlViewModel.projectManagerPermissions;
            this.fileUnderEdit.orgUserPermissions = this.urlViewModel.orgUserPermissions;
            this.fileUnderEdit.orgViewerPermissions = this.urlViewModel.orgViewerPermissions;
            this.saveUrl(this.fileUnderEdit).then((value: ProjectFile) => {
                thisClass.cancelEditFile();
            });
        } else if (this.fileUnderEdit.fileType === 'G-document' ||
            this.fileUnderEdit.fileType === 'G-spreadsheet' ||
            this.fileUnderEdit.fileType === 'G-presentation') {
            this.fileUnderEdit.description = this.gDocViewModel.fileDescription;
            this.fileUnderEdit.kind = this.gDocViewModel.kind;
            this.fileUnderEdit.status = this.gDocViewModel.status;
            this.fileUnderEdit.name = this.gDocViewModel.fileName;
            this.fileUnderEdit.clientAccountPermissions = this.gDocViewModel.clientAccountPermissions;
            this.fileUnderEdit.consultantPermissions = this.gDocViewModel.consultantPermissions;
            this.fileUnderEdit.projectManagerPermissions = this.gDocViewModel.projectManagerPermissions;
            this.fileUnderEdit.orgUserPermissions = this.gDocViewModel.orgUserPermissions;
            this.fileUnderEdit.orgViewerPermissions = this.gDocViewModel.orgViewerPermissions;
            this.saveGDoc(this.fileUnderEdit, null).then((value: ProjectFile) => {
                thisClass.cancelEditFile();
            });
        }
    }

    public cancelEditFile() {
        this.clearUploadViewModel();
        this.clearUrlViewModel();
        this.clearGDocViewModel();

    }

    public selectFileEditor(editor: string) {
        this.fileEditorToShow = editor;
        this.cancelEditFile();
    }

    public addScope() {
        const existingNew = this.scopes.filter((s) => { return !s.id; });
        if (existingNew.length === 0) {
            const scope = new ProjectScope();
            scope.name = 'New Scope Item';
            scope.projectId = this.project.id;
            this.scopes.push(scope);
            this.selectScope(scope);
            this.saveScopeItem(null);
        } else {
            this.selectScope(existingNew[0]);
        }
    }

    public addDeliverable(scope: ProjectScope) {
        const existingNew = this.deliverables.filter((d) => { return !d.id; });
        if (existingNew.length === 0) {
            const deliverable = new ProjectScopeDeliverable();
            deliverable.name = 'New Deliverable';
            deliverable.projectScopeId = scope.id;
            this.deliverables.push(deliverable);
            this.deliverableIsDirty = false;
            this.selectDeliverable(deliverable);
            this.saveDeliverable(null);
        } else {
            this.selectDeliverable(existingNew[0]);
        }
    }

    public addRate(scope: ProjectScope) {
        const existingNew = this.rates.filter((r) => { return !r.id; });
        if (existingNew.length === 0) {
            const rate = new ProjectScopeRateInfo();
            rate.rateType = 'Flat';
            rate.projectScopeId = scope.id;
            this.rates.push(rate);
            this.rateIsDirty = false;
            this.selectRate(rate);
            this.saveRate(null);
        } else {
            this.selectRate(existingNew[0]);
        }
    }

    public deleteScope() {
        this.projectService.deleteProjectScope(this.selectedScope.id,
            this.loggedInAccount.refreshToken).subscribe(() => {
                const toRemove = this.selectedScope;
                this.editing = 'projectSettings';
                this.scopes.splice(this.scopes.indexOf(toRemove), 1);
            });
    }

    public deleteDeliverable() {
        this.projectService.deleteProjectScopeDeliverable(this.selectedDeliverable.id,
            this.loggedInAccount.refreshToken).subscribe(() => {
                const toRemove = this.selectedDeliverable;
                const parentScope = this.scopes.filter((s) => (s.id === toRemove.projectScopeId))[0];
                this.selectScope(parentScope);
                this.deliverables.splice(this.deliverables.indexOf(toRemove), 1);
            });
    }

    public deleteRate() {
        this.projectService.deleteProjectScopeRateInfo(this.selectedRate.id,
            this.loggedInAccount.refreshToken).subscribe(() => {
                const toRemove = this.selectedRate;
                const parentScope = this.scopes.filter((s) => (s.id === toRemove.projectScopeId))[0];
                this.selectScope(parentScope);
                this.rates.splice(this.rates.indexOf(toRemove), 1);
            });
    }

    public addNewProjectType() {
        this.popNewTagWindow('Add New Project Type', 'New Project Type: ', this.projectTypeOptions, this.project.types);
    }

    public addNewProjectTag() {
        this.popNewTagWindow('Add New Project Tag', 'New Project Tag: ', this.projectTagOptions, this.project.tags);
    }

    public addNewDeliverableTag() {
        this.popNewTagWindow('Add New Deliverable Tag', 'New Deliverable Tag: ', this.deliverableTagOptions, this.selectedDeliverable.tags);
    }

    private popNewTagWindow(title: string, inputFieldLabel: string,
        tagOptions: IMultiSelectOption[], objectTagArray: string[]) {

        const bodyHtml = ``;
        const inputModel: CustomInputModel = {
            title,
            message: '',
            inputFieldLabel,
            bodyHtml,
            okButtonText: '',
            showCancelButton: true,
            showOkButton: true,
            cancelButtonText: 'Cancel',
            inputValue: ''
        };
        const disposable = this.dialogService.addDialog(InputModalComponent,
            inputModel,
        ).subscribe((inputVal) => {
            tagOptions.push({
                id: inputVal,
                name: inputVal
            });
            objectTagArray.push(inputVal);
            switch (tagOptions) {
                case this.projectTypeOptions:
                case this.projectTagOptions:
                    this.projectIsDirty = true;
                    break;
                case this.deliverableTagOptions:
                    this.deliverableIsDirty = true;
                default:
                    break;
            }
        });
    }

    public consultantVisible(deliverable: ProjectScopeDeliverable): boolean {
        let ret = false;
        this.rates.forEach(rate => {
            if (rate.audience === 'Consultant' && rate.projectScopeDeliverableIds &&
                rate.projectScopeDeliverableIds.indexOf(deliverable.id) >= 0) {
                ret = true;
            }
        });
        return ret;
    }

    public clientVisible(deliverable: ProjectScopeDeliverable): boolean {
        let ret = false;
        this.rates.forEach(rate => {
            if (rate.audience === 'Client' && rate.projectScopeDeliverableIds &&
                rate.projectScopeDeliverableIds.indexOf(deliverable.id) >= 0) {
                ret = true;
            }
        });
        return ret;
    }

    public startPermEdit(perm: ProjectPermission) {
        this.permUnderEdit = perm;
        if (!perm.roleName) {
            perm.roleName = 'None';
        }
    }

    public savePermUnderEdit() {
        if (this.permUnderEdit) {
            if (this.permUnderEdit.roleName === 'None') {
                this.permUnderEdit.roleName = null;
            }
            this.projectService.setProjectPermissions(this.permUnderEdit,
                this.loggedInAccount.refreshToken).subscribe(() => {
                    this.permUnderEdit = null;
                });
        }
    }

    public rateSanityCheck() {
        this.rateSanityCheckResult = null;
        // make sure that the selected consultant has only one rate for this deliverable
        if (this.selectedConsultantAccountId && this.selectedConsultantAccountId.length === 1
            && this.selectedConsultantAccountId[0] !== null
            && this.selectedRate && this.selectedRate.audience === 'Consultant') {
            console.log('checking selected consultant account Id', this.selectedConsultantAccountId);
            let foundRateCount = 0;
            this.selectedRate.projectScopeDeliverableIds.forEach(deliverableId => {
                this.rates.forEach(r => {
                    if (r !== this.selectedRate) {
                        if (r.consultantAccountId === this.selectedConsultantAccountId[0] &&
                            r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0) {
                            foundRateCount++;
                        }
                    }
                });
            });
            if (foundRateCount > 0) {
                this.rateSanityCheckResult = (this.rateSanityCheckResult ? this.rateSanityCheckResult : '') +
                    'This consultant already has a rate for this deliverable.';
            }
        }
        // make sure that there is only one client rate per deliverable:
        if (this.selectedRate && this.selectedRate.audience === 'Client') {
            let foundRateCount = 0;
            this.selectedRate.projectScopeDeliverableIds.forEach(deliverableId => {
                this.rates.forEach(r => {
                    if (r !== this.selectedRate) {
                        if (r.audience === 'Client' && r.projectScopeDeliverableIds.indexOf(deliverableId) >= 0) {
                            foundRateCount++;
                        }
                    }
                });
            });
            if (foundRateCount > 0) {
                this.rateSanityCheckResult = (this.rateSanityCheckResult ? this.rateSanityCheckResult : '') +
                    'There is already a client rate on this deliverable.';
            }
        }
    }

    public duplicateProject() {
        if (this.project) {
            this.loading = true;
            this.projectService.duplicateProject(this.project.id, this.loggedInAccount.refreshToken)
                .subscribe(p => {
                    this.project = null;
                    this.router.navigate([`/projectAdmin/edit/${p.id}`]);
                    window.location.reload();
                });
        }
    }

    private setDefaultPermissions(viewModel: any) {
        switch (viewModel.kind) {
            case 'Client Upload':
                viewModel.projectManagerPermissions = ProjectFilePermissionFlags.ReadOnly;
                viewModel.consultantPermissions = ProjectFilePermissionFlags.ReadOnly;
                viewModel.clientAccountPermissions = ProjectFilePermissionFlags.FullPermissions;
                viewModel.orgUserPermissions = ProjectFilePermissionFlags.FullPermissions;
                viewModel.orgViewerPermissions = ProjectFilePermissionFlags.ReadOnly;
                break;
            case 'FlexTeam Work':
                viewModel.projectManagerPermissions = ProjectFilePermissionFlags.FullPermissions;
                viewModel.consultantPermissions = ProjectFilePermissionFlags.ReadWrite;
                viewModel.clientAccountPermissions = ProjectFilePermissionFlags.None;
                viewModel.orgUserPermissions = ProjectFilePermissionFlags.None;
                viewModel.orgViewerPermissions = ProjectFilePermissionFlags.None;
                break;
            case 'Deliverable':
                viewModel.projectManagerPermissions = ProjectFilePermissionFlags.FullPermissions;
                viewModel.consultantPermissions = ProjectFilePermissionFlags.ReadOnly;
                viewModel.clientAccountPermissions = ProjectFilePermissionFlags.ReadOnly;
                viewModel.orgUserPermissions = ProjectFilePermissionFlags.ReadOnly;
                viewModel.orgViewerPermissions = ProjectFilePermissionFlags.ReadOnly;
                break;
            default:
                break;
        }
    }

    public fileKindChange(viewModel: any) {
        // console.log('fileKindChange', this.fileUnderEdit);
        if (!this.fileUnderEdit) {
            this.setDefaultPermissions(viewModel);
        } else if (this.fileUnderEdit && this.fileUnderEdit.fileType.startsWith('G-') && viewModel === this.gDocViewModel) {
            if (this.fileUnderEdit.kind !== 'Deliverable' && this.gDocViewModel.kind === 'Deliverable') {
                // they are switching to deliverable...
                this.setDefaultPermissions(viewModel);
            } else {
                // return it to how it was set prior to changing:
                this.gDocViewModel.clientAccountPermissions = this.fileUnderEdit.projectManagerPermissions;
                this.gDocViewModel.consultantPermissions = this.fileUnderEdit.consultantPermissions;
                this.gDocViewModel.orgUserPermissions = this.fileUnderEdit.orgUserPermissions;
                this.gDocViewModel.orgViewerPermissions = this.fileUnderEdit.orgViewerPermissions;
                this.gDocViewModel.projectManagerPermissions = this.fileUnderEdit.projectManagerPermissions;
            }
        }
    }

    public fileTemplateChange(viewModel: any) {
        if (this.gDocViewModel.template) {
            this.gDocViewModel.fileDescription = this.gDocViewModel.template.name;
            this.gDocViewModel.kind = 'FlexTeam Work';
            this.setDefaultPermissions(this.gDocViewModel);
            this.gDocViewModel.fileName = this.gDocViewModel.template.name;
            this.gDocViewModel.status = 'Draft';
            this.gDocViewModel.sendEmail = false;
        }
    }

    public permissionsUpdated(viewModel: any, property: string, permissions: ProjectFilePermissionFlags) {
        viewModel[property] = permissions;
    }

    public sendConsultantEmailForRateInfo(rateInfoId: string) {
        this.projectService.sendConsultantEmailsForRateInfo(rateInfoId, this.loggedInAccount.refreshToken)
            .subscribe(() => {
                this.consultantRateEmailSent = rateInfoId;
                // add an internal note to the rate:
                const selectedConsultant = this.allConsultants.find(c => c.id === this.selectedRate.consultantAccountId);
                if (!this.selectedRate.internalNotes) { this.selectedRate.internalNotes = '' };
                this.selectedRate.internalNotes += `<br> &quot;Ready&quot email sent to 
            ${selectedConsultant.firstName + ' ' + selectedConsultant.lastName} on ${moment(new Date()).toLocaleString()}`;
                this.saveRate(null, null);
            });
    }

    public canSendConsultantReadyEmail(selectedRate: ProjectScopeRateInfo): boolean {
        let ret = false;
        // get the associated scope:
        const scope = this.scopes.find(s => s.id === selectedRate.projectScopeId);
        if (scope) {
            ret = !!scope.agreedOnDate && !!scope.agreedByAccountId;
        }
        return ret;
    }
}


