﻿import { Component, ViewChild, ElementRef, AfterContentChecked } from '@angular/core';
import { Account } from '../_models';

@Component({
    moduleId: module.id,
    templateUrl: 'dashboard.component.html',
    styleUrls: ['reports-common.scss']
})

export class DashboardComponent implements AfterContentChecked {
    public containerHeight = 0;
    public loggedInAccount: Account;
    @ViewChild('outer') outerDiv: ElementRef;
    @ViewChild('overview') overviewDiv: ElementRef;
    @ViewChild('orgs') orgsDiv: ElementRef;
    @ViewChild('projects') projectsDiv: ElementRef;
    @ViewChild('consultants') consultantsDiv: ElementRef;
    @ViewChild('accounts') accountsDiv: ElementRef;
    @ViewChild('deliverables') deliverablesDiv: ElementRef;
    @ViewChild('invoices') invoicesDiv: ElementRef;
    public viewState = 'flexteam-overview';

    public dispOrgs = false;
    public dispConsultants = false;
    public dispProjects = false;
    public dispAccounts = false;
    public dispDeliverables = false;
    public dispInvoices = false;

    public constructor() {
        console.log('LOADING DASHBOARD');
    }

    public handleTabClick(view: string) {
        this.viewState = view;
        this.dispOrgs = this.dispConsultants = this.dispProjects =
            this.dispAccounts = this.dispDeliverables = false;
        switch (view) {
            case 'org-overview':
                this.dispOrgs = true;
                break;
            case 'consultant-overview':
                this.dispConsultants = true;
                break;
            case 'projects-overview':
                this.dispProjects = true;
                break;
            case 'accounts-overview':
                this.dispAccounts = true;
                break;
            case 'deliverables-overview':
                this.dispDeliverables = true;
                break;
            case 'invoice-overview':
                this.dispInvoices = true;
                break;
            default:
                break;
        }
    }

    ngAfterContentChecked(): void {
        // console.log('height', window.innerHeight);
        this.containerHeight = window.innerHeight - 340; //this.outerDiv.nativeElement.clientHeight;
    }
}
