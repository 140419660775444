/* tslint:disable:component-class-suffix */
import { Component, Input } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';
import { Router } from '@angular/router';


@Component({
    moduleId: module.id,
    selector: 'accounts-overview-report',
    templateUrl: 'accounts-overview-report.component.html',
    styleUrls: ['reports-common.scss']
})
export class AccountsOverviewReportComponent {
    @Input() containerHeight = 500;
    public reportData: any[];
    public loggedInAccount: Account;
    public loading = false;

    private _displayed: string;
    public get displayed(): string { return this._displayed; }
    @Input() public set displayed(v: string) { if (v && !this._displayed) { this.getReportData(); } this._displayed = v; }




    public childColumns: {
        displayName: string, propertyName: string,
        sortPrimerFunc?: (valIn) => any, dispType: string,
        fixedDec?: number, styleOverride?: any, clickable?: boolean
    }[] = [
            { displayName: 'Last Name', propertyName: 'lastName', dispType: 'string', clickable: true },
            { displayName: 'First Name', propertyName: 'firstName', dispType: 'string', clickable: true },
            {
                displayName: 'Email', propertyName: 'email', clickable: true,
                dispType: 'string', styleOverride: { 'min-width': '300px' }
            },
            { displayName: 'Started Projects', propertyName: 'clientStartedProjectCount', dispType: 'number' },
            { displayName: 'First Project Start Date', propertyName: 'clientFirstProjectStartDate', dispType: 'date' },
            { displayName: 'Last Project Start Date', propertyName: 'clientLastProjectStartDate', dispType: 'date' },
            { displayName: 'Avg Projects / Month', propertyName: 'avgClientProjectsPerMonth', dispType: 'fixed', fixedDec: 1 },
            { displayName: 'App Device Count', propertyName: 'installedAppDeviceCount', dispType: 'number' },
            { displayName: 'Last API Access', propertyName: 'lastAPIAccess', dispType: 'datetime' },
            { displayName: 'Last Login', propertyName: 'lastLogin', dispType: 'datetime' },
            { displayName: 'Last Failed Login', propertyName: 'lastFailedLogin', dispType: 'datetime' },
            { displayName: 'List Items', propertyName: 'listItemCount', dispType: 'number' },
            {
                displayName: 'List Items Converted To Started Projects',
                propertyName: 'listItemsConvertedToStartedProjects', dispType: 'number'
            },
            {
                displayName: 'List Items Converted To Started Projects %',
                propertyName: 'listItemsConvertedToStartedProjectsPct', dispType: 'pct'
            },
        ];

    public initialSorts: { propertyName: string, reverse: boolean }[] = [];

    constructor(
        private router: Router,
        private accountService: AccountService,
        private reportsService: ReportsService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                // this.getReportData();
            }).subscribe();
    }

    public get childTableHeight(): number {
        return this.containerHeight;
    }

    private getReportData() {
        if (!this.reportData || this.reportData.length === 0) {
            this.loading = true;
            this.reportsService.getReport('accountoverview', this.loggedInAccount.refreshToken)
                .subscribe(reportData => {
                    if (reportData) {
                        this.initialSorts = [
                            { propertyName: 'lastName', reverse: false },
                            { propertyName: 'firstName', reverse: false },
                            { propertyName: 'email', reverse: false },
                        ];
                        this.reportData = reportData;
                    }
                    this.loading = false;
                });
        }
    }

    public cellClick(d: { rowData: any, propertyName: string }) {
        window.open('/accountAdmin/manage/' + d.rowData.accountId, '_blank');
    }

}


