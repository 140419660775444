import { RolesHelper, JwtHelper } from '../_helpers';
export class Account {
    refreshToken: string;
    accessToken: string;
    roles: any;
    loggedInAccount: any;
    profile: {
        email: string;
        isLegalAgreed: boolean;
        isLegalAgreedAt: string;
        lastSetupStepCompleted: number;
        profileImageFile: string;
        gravatarHash: string;
        firstName: string;
        lastName: string;
        phone: string;
        city: string;
        state: string;
        country: string;
        linkedIn: string;
        version: string;
    };

    constructor(account: any) {
        this.setAccount(account);
    }

    private setProfile(profile: any) {
        this.profile = {
            email: profile.email,
            isLegalAgreed: profile.isLegalAgreed,
            isLegalAgreedAt: profile.isLegalAgreedAt,
            lastSetupStepCompleted: profile.lastSetupStepCompleted,
            profileImageFile: profile.profileImageFile,
            gravatarHash: profile.gravatarHash,
            firstName: profile.firstName,
            lastName: profile.lastName,
            phone: profile.phone,
            city: profile.city,
            state: profile.state,
            country: profile.country,
            linkedIn: profile.linkedIn,
            version: profile.version
        };
    }

    private setAccount(account: any) {
        this.refreshToken = account.refreshToken;
        this.accessToken = account.accessToken;
        this.roles = RolesHelper.setRoles(
            JSON.parse(JwtHelper.decodeToken(account.refreshToken).sroles)
        );
        this.setProfile(account.profile);
    }
}
