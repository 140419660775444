/* tslint:disable:component-class-suffix */
import { Component, Input } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';


@Component({
    moduleId: module.id,
    selector: 'deliverables-overview-report',
    templateUrl: 'deliverables-overview-report.component.html',
    styleUrls: ['reports-common.scss']
})
export class DeliverablesOverviewReportComponent {
    @Input() containerHeight = 500;
    public reportData: any[];
    public loggedInAccount: Account;
    public loading = false;


    private _displayed: string;
    public get displayed(): string { return this._displayed; }
    @Input() public set displayed(v: string) { if (v && !this._displayed) { this.getReportData(); } this._displayed = v; }


    public childColumns: {
        displayName: string, propertyName: string,
        sortPrimerFunc?: (valIn) => any, dispType: string,
        fixedDec?: number, styleOverride?: any, clickable?: boolean
    }[] = [
            { displayName: 'Organization Name', propertyName: 'organizationName', dispType: 'string' },
            {
                displayName: 'Project Name', propertyName: 'projectName', dispType: 'string',
                clickable: true, styleOverride: { 'min-width': '300px' }
            },
            { displayName: 'Scope Name', propertyName: 'scopeName', dispType: 'string' },
            { displayName: 'Deliverable Name', propertyName: 'deliverableName', dispType: 'string' },
            { displayName: 'Deliverable Due', propertyName: 'deliverableDueDate', dispType: 'date' },
            { displayName: 'Project Status', propertyName: 'projectStatus', dispType: 'string' },
            { displayName: 'Rate Audience', propertyName: 'rateAudience', dispType: 'string' },
            { displayName: 'Rate Type', propertyName: 'rateType', dispType: 'string' },
            { displayName: 'Rate', propertyName: 'rate', dispType: 'money' },
            {
                displayName: 'Total Billable Hours', propertyName: 'totalBillableHours', dispType: 'fixed', fixedDec: 4,
                styleOverride: { 'min-width': '105px' }
            },
            { displayName: '$ Billed', propertyName: 'totalBilledForDeliverable', dispType: 'money' },
            { displayName: '$ Paid To Consultant', propertyName: 'amountPaidToConsultant', dispType: 'money' },
            { displayName: 'Collected?', propertyName: 'successfullyCollected', dispType: 'boolean' },
            { displayName: 'Consultant First Name', propertyName: 'consultantFirstName', dispType: 'string' },
            { displayName: 'Consultant Last Name', propertyName: 'consultantLastName', dispType: 'string' },
        ];

    public initialSorts: { propertyName: string, reverse: boolean }[] = [];

    constructor(
        private accountService: AccountService,
        private reportsService: ReportsService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                // this.getReportData();
            }).subscribe();
    }

    public get childTableHeight(): number {
        return this.containerHeight;
    }

    private getReportData() {
        if (!this.reportData || this.reportData.length === 0) {
            this.loading = true;
            this.reportsService.getReport('deliverableoverview', this.loggedInAccount.refreshToken)
                .subscribe(reportData => {
                    if (reportData) {
                        this.initialSorts = [
                            { propertyName: 'organizationName', reverse: false },
                            { propertyName: 'projectName', reverse: false },
                            { propertyName: 'scopeName', reverse: false },
                            { propertyName: 'deliverableName', reverse: false },
                            { propertyName: 'rateAudience', reverse: false },
                        ];
                        this.reportData = reportData;
                        //   console.log('report data', this.reportData);
                    }
                    this.loading = false;
                });
        }
    }

    public cellClick(d: { rowData: any, propertyName: string }) {
        window.open('/projectAdmin/edit/' + d.rowData.projectId, '_blank');
    }
}


