import {
    Component, ElementRef, AfterContentInit,
    ViewChild, AfterViewChecked, OnDestroy, HostListener
} from '@angular/core';
import { Account, ChatConversation, AgentConversationSearchResult, AccountNote } from '../_models';
import { AccountService, ChatService } from '../_services';
import { Store } from '@ngrx/store';
import { PubNubAngular } from 'pubnub-angular2';
import { JwtHelper } from '../_helpers';
import { CONFIG } from '../../environments/environment';
import { PendingChangesGuard, ComponentCanDeactivate } from '../_guards';
import { finalize, map } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    templateUrl: 'chatAdmin.component.html',
    styleUrls: ['chatAdmin.component.scss'],
    providers: [PubNubAngular]
})
export class ChatAdminComponent implements AfterContentInit, AfterViewChecked, OnDestroy, ComponentCanDeactivate {
    public PN_INSTANCE_MESSAGEBUS = 'MessageBus';
    public loading = false;
    public loggedInAccount: Account;
    public unansweredConversations: ChatConversation[];
    public answeredConversations: ChatConversation[];
    public activeChats: any = {};
    public selectedChat: any;
    public agentAccountId: string;

    private pubnub: PubNubAngular;
    private prevChatHeight = 0;
    public whosTyping: string;
    public whatToShow = 'chatWindow';

    public selectedNote: AccountNote = null;
    public selectedNoteDirty = false;

    @ViewChild('chatScroll') private chatScrollContainer: ElementRef;
    @ViewChild('msgInput') private messageInputBox: ElementRef;

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            this.updateNote(false, null);
            $event.returnValue = `You have an unsaved note`;
        }
    }

    constructor(public pn: PubNubAngular, private chatService: ChatService,
        private accountService: AccountService,
        private store: Store<Account>) {
        console.log('CHAT ADMIN CTOR()')
        this.answeredConversations = [];
        this.pubnub = pn;

        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.agentAccountId = JwtHelper.decodeToken(this.loggedInAccount.refreshToken).acct;
            }).subscribe();

        // set up the message bus instance:
        this.pubnub
            .init({
                subscribeKey: CONFIG.pubNub.subscribeKey,
                ssl: true,
                uuid: 'WebChatAdmin',
                authKey: 'abc123',
                origin: 'pubsub.pubnub.com'
            });

        this.pubnub
            .subscribe({
                channels: [CONFIG.pubNub.agentMessageBus],
                channelGroups: [],
                withPresence: false,
                timeToken: null
            });

        this.pubnub
            .addListener({
                message: (m: any) => {
                    this.processIncomingBusRequest(m);
                }
            });

    }

    canDeactivate(): boolean {
        return !this.selectedNoteDirty;
    }

    public ngOnDestroy() {

    }

    public get sortedAnsweredConversations() {
        return this.answeredConversations.sort((a: ChatConversation, b: ChatConversation) => {
            return b.lastTimestamp - a.lastTimestamp;
        });
    }

    public keyDown() {
        if (this.selectedChat) {
            this.selectedChat.conversation.hasUnread = false;
        }
    }

    public keyUp(messageString: string) {
        if (this.selectedChat) {
            const convo: ChatConversation = this.selectedChat.conversation;
            convo.adminLastTypedDate = Date.now();
            let idleChanged = false;
            if (convo.adminIsIdle) {
                convo.adminIsIdle = false;
                idleChanged = true;
            }

            const typingChanged = (messageString && messageString.length > 0 && !convo.adminIsTyping) ||
                ((!messageString || messageString.length === 0) && convo.adminIsTyping);

            if (typingChanged) {
                convo.adminIsTyping = ((messageString !== null) && messageString.length > 0);
            }

            if (typingChanged || idleChanged) {
                this.sendState(convo, idleChanged);
            }
        }
    }

    private sendState(convo: ChatConversation, idleChanged: boolean) {
        const state = { isTyping: <boolean>convo.adminIsTyping, isIdle: <boolean>convo.adminIsIdle, idleChanged, pre: false };
        // console.log('state change', state);
        this.setState(state, convo.chatChannelId, this.agentAccountId);
    }

    public setState(stateObject: any, chatChannelId: string, uuid: string) {
        this.pubnub.getInstance(this.selectedChat.conversation.id).setState({
            state: stateObject,
            uuid,
            channels: [chatChannelId]
        },
            (status, response) => { }
        );
    }

    public ngAfterViewChecked(): void {
        /* need _canScrollDown because it triggers even if you enter text in the textarea */

        if (this.canScrollDown()) {
            this.scrollToBottom();
        }
    }

    public ngAfterContentInit() {
        this.loadAnswered();
        this.loadUnanswered();
    }

    public processIncomingBusRequest(m: any): void {
        const channelName = m.channel;
        const channelGroup = m.subscription;
        const publishTimeToken = m.timeToken;
        const msg = m.message;

        console.log('incoming bus request:', m);

        if (msg.notificationType === 'CustomerWaiting') {

            // add conversation to wait list
            const toPush: ChatConversation = new ChatConversation(msg.conversation);
            this.handleAvatars(toPush);
            this.unansweredConversations.push(toPush);

        } else if (msg.notificationType === 'CustomerHungUp' ||
            msg.notificationType === 'AgentHungUp') {
            const conversation = msg.conversation;

            // unsubscribe:
            if (this.answeredConversations.filter((convo) => { return convo.chatChannelId === conversation.chatChannelId; })
                .length > 0) {
                this.pubnub.getInstance(conversation.id).unsubscribe({ channels: [conversation.chatChannelId] });
            }
            // remove the active chat:
            delete this.activeChats[conversation.chatChannelId];
            // remove the answered conversation:
            this.answeredConversations = this.answeredConversations.filter((convo) => {
                return convo.chatChannelId !== conversation.chatChannelId;
            });
            // if it is the currently selected conversation, remove the selection:
            if (this.selectedChat && this.selectedChat.conversation.id === conversation.id) {
                this.selectedChat = null;
                this.selectFirstChat();
            }
        } else if (msg.notificationType === 'AgentPickedUp') {
            // remove it from the list:
            const conversation: ChatConversation = msg.conversation;
            const matchedConvos = this.unansweredConversations.filter((c) => {
                return c.id === conversation.id;
            });
            if (matchedConvos.length === 1) {
                this.unansweredConversations.splice(matchedConvos.indexOf(matchedConvos[0]), 1);
            }
        }
    }

    public processIncomingMessage(m: any): void {
        const channelName = m.channel;
        const channelGroup = m.subscription;
        const publishTimeToken = m.timetoken;
        const msg = m.message;
        msg.text = msg.text.replace(new RegExp('\n', 'g'), '<br>');
        if (this.activeChats[channelName]) {
            const convo: ChatConversation = this.activeChats[channelName].conversation;
            convo.lastTimestamp = publishTimeToken / 10000;
            convo.hasUnread = !(this.agentAccountId === msg.user.id);
            this.activeChats[channelName].messages.push(m);
        }
    }

    private processIncomingPresenceMessage(event: any) {
        const channelName = event.channel;
        console.log('handlePresence(' + event.uuid + ')', event);
        if (event.action === 'join') {
            console.log('join', event.uuid);
            if (event.uuid !== this.agentAccountId) {
                // this.hangUp(this.activeChats[channelName].conversation.id);
                this.activeChats[channelName].conversation.clientDisconnected = false;
            }
        } else if (event.action === 'leave') {
            if (event.uuid !== this.agentAccountId) {
                // this.hangUp(this.activeChats[channelName].conversation.id);
                console.log('disconnected!');
                this.activeChats[channelName].conversation.clientDisconnected = true;
            }
        } else if (event.action === 'state-change') {
            console.log('state-change', event.uuid);
            if (event.uuid !== this.agentAccountId) {
                this.activeChats[channelName].conversation.clientTyping = event.state.isTyping;
                this.activeChats[channelName].conversation.clientIdle = event.state.isIdle;
            }
        }
    }

    public getDateFromTimestamp(timestamp: number): string {
        return new Date(timestamp / 10000).toISOString();
    }

    public pickupChat(id: string): void {
        this.loading = true;
        this.chatService.pickupAgentConversation(id, this.loggedInAccount.refreshToken)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }), map((result: ChatConversation) => {
                    if (result) {
                        // refresh the unanswered list:
                        this.loadUnanswered();
                        // add to active chats (wait a second:)
                        setTimeout(() => {
                            this.addToActiveChats(result);
                            if (this.activeChats.length === 1) {
                                this.selectFirstChat();
                            }
                        }, 1000);
                    }
                })
            );
    }

    public selectChat(channelName: string) {
        if (this.selectedNote && this.selectedNoteDirty) {
            // save off the prior note...
            console.log('saving off notes first before changing channel');
            this.updateNote(true, channelName);
        } else {
            this.selectedNote = null;
            this.selectedNoteDirty = false;
            console.log('selecting', channelName);
            this.selectedChat = this.activeChats[channelName];

            const convo: ChatConversation = this.selectedChat.conversation;
            convo.hasUnread = false;

            if (!this.selectedChat.notes) {
                this.accountService.getAccountNotes(convo.clientAccountId, this.loggedInAccount.refreshToken)
                    .subscribe(notes => {
                        this.selectedChat.notes = notes;
                    });
            }
        }
    }

    public sendMessage(messageText: string) {

        const message = {
            text: messageText.trim(),
            user: {
                id: this.agentAccountId,
                smsNumberId: this.selectedChat.conversation.smsNumberId
            }
        };

        this.pubnub.getInstance(this.selectedChat.conversation.id).publish({
            channel: this.selectedChat.conversation.chatChannelId,
            message
        }, (status, response) => {
            if (status.error) {
                console.log('Error sending message:' + status.error);
            } else {
                if (this.messageInputBox && this.messageInputBox.nativeElement) {
                    this.messageInputBox.nativeElement.value = '';
                    this.keyUp(this.messageInputBox.nativeElement.value);
                }
            }
        });

    }

    public hangUp(conversationId: string) {
        this.loading = true;
        this.chatService.hangUpConversation(conversationId, this.loggedInAccount.refreshToken)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
                map((result) => {
                    if (result) {
                        const chatChannelId = result.chatChannelId;
                    }
                })
            );
    }

    private canScrollDown(): boolean {
        /* compares prev and current scrollHeight */

        const can = (this.chatScrollContainer && this.chatScrollContainer.nativeElement &&
            this.prevChatHeight !== this.chatScrollContainer.nativeElement.scrollHeight);

        if (this.chatScrollContainer && this.chatScrollContainer.nativeElement) {
            this.prevChatHeight = this.chatScrollContainer.nativeElement.scrollHeight;
        }

        return can;
    }

    private scrollToBottom() {
        try {
            this.chatScrollContainer.nativeElement.scrollTop =
                this.chatScrollContainer.nativeElement.scrollHeight;
        } catch (err) { console.log('Error scrolling:' + err); }
    }

    private loadAnswered(): void {
        this.loading = true;
        this.chatService.getAnsweredChatListForAgent(
            this.agentAccountId,
            this.loggedInAccount.refreshToken)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
                map((result: ChatConversation[]) => {
                    if (result) {
                        for (const convo of result) {
                            this.addToActiveChats(convo);
                            this.handleAvatars(convo);
                        }
                        this.selectFirstChat();
                    }
                })
            ).subscribe();
    }

    private loadUnanswered(): void {
        // grab the list of agent chats:
        this.loading = true;
        this.chatService.getUnansweredChatList(this.loggedInAccount.refreshToken)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
                map((result: any) => {
                    if (result) {
                        this.unansweredConversations = result;
                        // grab the avatar images for each conversation:
                        this.unansweredConversations.forEach(c => {
                            this.handleAvatars(c);
                        });
                    }
                })
            ).subscribe();
    }

    private handleAvatars(c: ChatConversation) {
        if (c.clientAvatarFilename) {
            this.accountService.downloadAccountImage(c.clientAvatarFilename, this.loggedInAccount.refreshToken)
                .subscribe(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        c.clientAvatar = reader.result;
                    };
                    if (blob) {
                        reader.readAsDataURL(blob);
                    }
                });
        }
        if (c.agentAvatarFilename) {
            this.accountService.downloadAccountImage(c.agentAvatarFilename, this.loggedInAccount.refreshToken)
                .subscribe(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        c.agentAvatar = reader.result;
                    };
                    if (blob) {
                        reader.readAsDataURL(blob);
                    }
                });
        }
    }

    private addToActiveChats(convo: ChatConversation) {

        if (!this.activeChats[convo.chatChannelId]) {

            this.activeChats[convo.chatChannelId] = {};
            this.activeChats[convo.chatChannelId].messages = [];
            this.activeChats[convo.chatChannelId].conversation = convo;
            this.answeredConversations.push(convo);
            this.handleAvatars(convo);

            // set up the pubnub for this conversation
            this.pubnub.getInstance(convo.id)
                .init({
                    subscribeKey: CONFIG.pubNub.subscribeKey,
                    publishKey: CONFIG.pubNub.publishKey,
                    ssl: true,
                    uuid: this.agentAccountId,
                    authKey: convo.accessToken,
                    origin: 'pubsub.pubnub.com'
                });

            this.pubnub.getInstance(convo.id).subscribe({
                channels: [convo.chatChannelId],
                channelGroups: [],
                withPresence: true,
                timeToken: null
            });

            this.pubnub.getInstance(convo.id).addListener({
                message: (m: any) => {
                    this.processIncomingMessage(m);
                }
            });

            this.pubnub.getInstance(convo.id).addListener({
                presence: (m: any) => {
                    this.processIncomingPresenceMessage(m);
                }
            });

            // get the history for the channel:
            this.pubnub.getInstance(convo.id).history({
                channel: convo.chatChannelId,
                reverse: false,
                count: 100,
                stringifiedTimeToken: false,
                start: null,
                end: null
            }, (status, response) => {
                for (const historyItem of response.messages) {
                    this.processIncomingMessage({
                        timetoken: historyItem.timetoken,
                        message: historyItem.entry,
                        channel: convo.chatChannelId
                    });
                }
            });

            // get who is here now:
            this.pubnub.getInstance(convo.id).hereNow({
                channels: [convo.chatChannelId],
                includeUUIDs: true,
                includeState: true
            },
                (status, response) => {
                    // console.log('Here Now status:', status);
                    // console.log('Here Now response:', response);
                    if (response && response.channels && response.channels[convo.chatChannelId]) {
                        const resp = response.channels[convo.chatChannelId];
                        resp.occupants.forEach(occupant => {
                            // console.log('eval', occupant.uuid, convo.clientAccountId, this.agentAccountId);
                            if (occupant.uuid === convo.clientAccountId) {
                                convo.clientDisconnected = false;
                                convo.clientIdle = (occupant.state && occupant.state.isIdle);
                                convo.clientTyping = (occupant.state && occupant.state.isTyping);
                            }
                        });
                    }
                });

        } else {
            console.log('Chat is already in active chats. Not resubscribing.');
        }
    }

    private selectFirstChat() {
        if (this.answeredConversations && this.answeredConversations.length > 0) {
            this.selectChat(this.answeredConversations[0].chatChannelId);
        }
    }

    public setSelectedNote(note) {
        this.selectedNote = note;
    }

    public selectedNoteChanged() {
        this.selectedNoteDirty = true;
    }

    public updateNote(clearSelectedAfterUpdate: boolean, afterClearChannelName: string) {
        const idx = this.selectedChat.notes.indexOf(this.selectedNote);
        this.accountService.upsertAccountNote(this.selectedNote, this.loggedInAccount.refreshToken)
            .subscribe(note => {
                if (clearSelectedAfterUpdate) {
                    this.setSelectedNote(null);
                    if (afterClearChannelName) {
                        this.selectChat(afterClearChannelName);
                    }
                } else {
                    this.selectedChat.notes.splice(idx, 1);
                    this.selectedChat.notes.unshift(note);
                    this.setSelectedNote(note);
                }
                this.selectedNoteDirty = false;
            });
    }

    public createNote() {
        console.log('create note', this.selectedChat);
        const newNote = new AccountNote();
        newNote.accountId = this.selectedChat.conversation.clientAccountId;
        newNote.note = '';
        this.accountService.upsertAccountNote(newNote, this.loggedInAccount.refreshToken)
            .subscribe(createdNote => {
                this.selectedChat.notes.unshift(createdNote);
                this.setSelectedNote(createdNote);
            });
    }
}
