import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Account } from '../_models';
import { Store } from '@ngrx/store';
import { JwtHelper, RolesHelper } from '../_helpers';

@Component({
    selector: 'header',
    moduleId: module.id,
    templateUrl: 'header.component.html',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})

export class HeaderComponent {
    public loggedInAccount: Account;
    public showDashboard: boolean;
    public showBilling: boolean;
    public showChat: boolean;
    public showAccountAdmin: boolean;
    public showProjectAdmin: boolean;
    public showMlDemo: boolean;
    public showReports: boolean;
    public showHours: boolean;
    public showSiteAdmin: boolean;

    private location;

    public menuExpanded = false;   // store state

    constructor(private store: Store<Account>, location: Location) {
        this.location = location;

        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                if (this.loggedInAccount) {
                    this.showDashboard = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['User'] });
                    this.showBilling = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['BillingAdmin'] });
                    this.showChat = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['ChatAgent'] });
                    this.showAccountAdmin = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['Admin'] });
                    this.showProjectAdmin = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['Admin'] });
                    this.showSiteAdmin = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['Admin'] });
                    this.showMlDemo = RolesHelper
                        .belongsToRole(this.loggedInAccount.roles, { System: ['Admin'] });
                }
            }).subscribe();
    }

    toggleState() { // click handler
        this.menuExpanded = !this.menuExpanded;
    }

    public isActive(location: string) {
        if (location.length === 0 && this.location.path().length === 0) {
            return true;
        }

        return this.location.path().indexOf(location) >= 0 && location.length > 0;
    }
}
