/* tslint:disable:component-class-suffix */
import { Component, Input } from '@angular/core';
import { AccountService, ReportsService } from '../_services';
import { Store } from '@ngrx/store';
import { Account } from '../_models';
import { ReportsCommon } from './reports-common';
import { CONFIG } from '../../environments/environment';

@Component({
    moduleId: module.id,
    selector: 'invoice-overview-report',
    templateUrl: 'invoice-overview-report.component.html',
    styleUrls: ['reports-common.scss']
})
export class InvoiceOverviewReportComponent {
    @Input() containerHeight = 500;
    public reportData: any[];
    public groupedData: any[] = [];
    public selectedGroup: any;

    public loggedInAccount: Account;
    public loading = false;
    public groupBy = ['paid'];
    public sumBy = ['invoicedAmount'];

    public childColumns: {
        displayName: string, propertyName: string,
        sortPrimerFunc?: (valIn) => any, dispType: string,
        fixedDec?: number, styleOverride?: any, clickable?: boolean
    }[] = [
            { displayName: 'Inv #', propertyName: 'invoiceNumber', dispType: 'string', clickable: true },
            // {displayName:   'Paid?', propertyName: 'paid', dispType: 'boolean'},
            { displayName: 'Organization', propertyName: 'organizationName', dispType: 'string' },
            {
                displayName: 'Project', propertyName: 'projectName', clickable: true,
                dispType: 'string', styleOverride: { 'min-width': '300px' }
            },
            { displayName: 'Invoice Date', propertyName: 'invoiceDate', dispType: 'date' },
            { displayName: 'Due Date', propertyName: 'invoiceDueDate', dispType: 'date' },
            { displayName: '$ Invoiced', propertyName: 'invoicedAmount', dispType: 'money' },
            { displayName: '$ Approved', propertyName: 'totalApprovedTransactionAmount', dispType: 'money' },
            { displayName: '# Approved Transactions', propertyName: 'totalApprovedTransactions', dispType: 'number' },
            { displayName: 'Created By First Name', propertyName: 'createdByFirstName', dispType: 'string' },
            { displayName: 'Created By Last Name', propertyName: 'createdByLastName', dispType: 'string' }
        ];

    public initialSorts: { propertyName: string, reverse: boolean }[] = [];

    public get childTableHeight(): number {
        return this.containerHeight - 170;
    }



    private _displayed: string;
    public get displayed(): string { return this._displayed; }
    @Input() public set displayed(v: string) { if (v && !this._displayed) { this.getReportData(); } this._displayed = v; }


    constructor(
        private accountService: AccountService,
        private reportsService: ReportsService,
        private store: Store<Account>) {
        this.loading = true;
        this.store.select('loggedInAccount')
            .select((account: Account) => {
                this.loggedInAccount = account;
                this.loading = false;
                // this.getReportData();
            }).subscribe();
    }

    private getReportData() {
        if (!this.reportData || this.reportData.length === 0) {
            this.loading = true;
            this.reportsService.getReport('invoiceOverview', this.loggedInAccount.refreshToken)
                .subscribe(reportData => {
                    if (reportData) {
                        this.initialSorts = [
                            { propertyName: 'invoiceNumber', reverse: true },
                        ];
                        this.reportData = reportData;
                        this.setGroupedData();
                    }
                    this.loading = false;
                });
        }
    }

    public setGroupedData() {
        this.groupedData = ReportsCommon.group(this.reportData, this.groupBy, this.sumBy);
        this.groupedData.sort(ReportsCommon.sort([
            { propertyName: 'paid', primerFunction: null, reverse: false },
        ]));
    }

    public cellClick(d: { rowData: any, propertyName: string }) {
        if (d.propertyName === 'invoiceNumber') {
            window.open(CONFIG.websiteBase + 'project/invoice/' + d.rowData.projectInvoiceId, '_blank');
        } else if (d.propertyName === 'projectName') {
            window.open('/projectAdmin/edit/' + d.rowData.projectId, '_blank');
        }

    }
}


